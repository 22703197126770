import FIELD_TYPES from '../components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/exception_log_config',
  access: 'exceptionLogConfig',
  resource: {
    definition: '#ExceptionLogConfig',
    properties: {
      notificationEmails: {
        type: FIELD_TYPES.SIMPLE_ARRAY,
        description: 'Emaile do powiadomienia',
        addInputLabel: 'Email',
        addButtonLabel: 'Dodaj email',
        inputValidate: ['email'],
        orderable: false,
      },
    },
  },
}

export default schema
