import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import schema from '_schema/shopOrderStatus'
import routes from './routes'
import { StatusCell } from '../../components/table/cells/StatusCell'
import { TitleCell } from './table/cells/TitleCell'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'title',
        header: translate('Nazwa'),
        accessor: `title`,
        filterable: true,
        sortable: true,
        width: '20%',
        Cell: TitleCell,
      },
      {
        columnId: 'paid',
        header: translate('Zamówienie opłacone'),
        accessor: `paid`,
        filterable: true,
        sortable: true,
        Cell: StatusCell,
        width: '10%',
      },
      {
        columnId: 'returnable',
        header: translate('Można zwrócić'),
        accessor: `returnable`,
        Cell: StatusCell,
        width: '10%',
      },
      {
        columnId: 'forOrder',
        header: translate('Dla zamówienia'),
        accessor: `forOrder`,
        Cell: StatusCell,
        width: '10%',
      },
      {
        columnId: 'forSubscription',
        header: translate('Dla prenumeraty'),
        accessor: `forSubscription`,
        Cell: StatusCell,
        width: '10%',
      },
      {
        columnId: 'sendEmail',
        header: translate('Wysłać powiadomienie email'),
        accessor: `sendEmail`,
        Cell: StatusCell,
        width: '10%',
      },
      {
        columnId: 'operations',
        ...operations(`title`, true, true, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj nowy status')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
      >
        {translate('Statusy zamówień')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        orderable={true}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        paginable={false}
        parameters={[
          {
            name: 'title',
            in: 'query',
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
