import React, { useState } from 'react'
import { Form } from 'pages'
import schema from '_schema/shopOrder'
import routes from './routes'
import { translate } from '../../_helpers/translate'
import { EmbeddedCollection } from './components/product'
import { AddModal } from '../../components/embedded/AddModal'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [reloadParent, setReloadParent] = useState(false)
  const [modalResponse, setModalResponse] = useState(null)

  const handleSuccessAndStay = resource => {
    window.location.reload()
  }

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarStatAccessor={''}
      width={600}
      reloadParent={reloadParent}
      setReloadParent={setReloadParent}
      handleSuccessAndStay={handleSuccessAndStay}
    >
      <EmbeddedCollection
        endpoint={`${schema.subresources.variants.endpoint}?iri[parent]=${iri}`}
        pid={match.params.id}
        parentIri={iri}
        properties={schema.subresources.variants.properties}
        definitionSchema={definitions[schema.subresources.variants.definition]}
        headerTitle={translate('T_SHOP_MODULE_ORDER_VARIANT_TITLE_HEADER')}
        panelTitle={translate('T_SHOP_MODULE_ORDER_VARIANT_TITLE')}
        titleAccessor={`title`}
        statable={false}
        hideAddButton={true}
        setReloadParent={setReloadParent}
        modalResponse={modalResponse}
        setModalResponse={setModalResponse}
      />
      <AddModal
        title={translate('Dodaj produkt')}
        definitionSchema={definitions[schema.subresources.variants.definition]}
        itemProperties={schema.subresources.addVariantModal.properties}
        defaultData={{ parent: iri, price: 0.1, populateData: true }}
        endpoint={schema.subresources.variants.endpoint}
        itemSchema={schema.subresources.variants}
        setModalResponse={setModalResponse}
      />
    </Form>
  )
}
