import usersShema from './accommodationUser'

const schema = {
  endpoint: '/api/accommodation_messages',
  access: 'accommodationMessage',
  resource: {
    definition: '#AccommodationMessage',
    properties: {
      mailTitle: {
        description: 'Tytuł wątku/wiadomości',
        validate: ['required'],
      },
      mailText: {
        description: 'Treść wiadomości',
        validate: ['required'],
        type: 'textarea',
      },
      newMailText: {
        description: 'Wiadomość',
        type: 'textarea',
        hint: 'wpisz wiadomość i zapisz, aby odpowiedzieć nadawcy',
      },

      recipientsChoosen: {
        type: 'multipleResource',
        endpoint: `${usersShema.endpoint}?pagination=false`,
        titleAccessor: 'selectName',
        description: 'Odbiorcy',
        hint: 'zostaw puste jeśli chcesz wysłać do wszystkich',
      },

      restart: {
        description: 'Restart',
        hint:
          'jesli włączysz, zresetujesz ustawienia, będziesz mógł od nowa wysłać tą wiadomość',
      },

      children: {
        type: 'returnMessage',
        description: 'Wątek',
      },
    },
  },
  resourceNewAction: {
    definition: '#AccommodationMessage',
    properties: {
      mailTitle: {
        description: 'Tytuł wątku/wiadomości',
        validate: ['required'],
      },
      mailText: {
        description: 'Treść wiadomości',
        validate: ['required'],
        type: 'textarea',
      },
      recipientsChoosen: {
        type: 'multipleResource',
        endpoint: `${usersShema.endpoint}?pagination=false`,
        titleAccessor: 'selectName',
        description: 'Odbiorcy',
        hint: 'zostaw puste jeśli chcesz wysłać do wszystkich',
      },
    },
  },
}

export default schema
