export const modifyOptionPropertiesByPage = (properties, resource, schema) => {
  // let modifiedProperties = Object.assign({}, properties)
  // modifiedProperties.translations.properties = Object.assign(
  //   {},
  //   properties.translations.properties
  // )
  // modifiedProperties.options.properties = Object.assign(
  //   {},
  //   properties.options.properties
  // )
  // modifiedProperties.options.properties.translations = Object.assign(
  //   {},
  //   properties.options.properties.translations
  // )
  // modifiedProperties.options.properties.translations.properties = Object.assign(
  //   {},
  //   properties.options.properties.translations.properties
  // )

  function deepClone(obj) {
    if (obj === null || typeof obj !== 'object') {
      return obj
    }

    const clone = Array.isArray(obj) ? [] : {}

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        clone[key] = deepClone(obj[key])
      }
    }

    return clone
  }

  let modifiedProperties = deepClone(properties)

  let idname = resource.type

  if (idname !== 'checkbox') {
    delete modifiedProperties?.expandItems
  }

  switch (idname) {
    case 'textarea':
      return modifiedProperties

    case 'select':
    case 'multiple':
    case 'checkbox':
    case 'radio':
      delete modifiedProperties.translations.properties.shortLead
      delete modifiedProperties.required
      delete modifiedProperties.translations.properties.title.hint
      return modifiedProperties

    case 'file':
      return modifiedProperties

    case 'number':
      return modifiedProperties

    case 'header':
      return modifiedProperties

    case 'consent':
      modifiedProperties.translations.properties.title.hint =
        'nazwa widoczna tylko w panelu'
      modifiedProperties.translations.properties.shortLead.label = 'Treść zgody'
      return modifiedProperties

    default:
      return modifiedProperties
  }
}
