import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { prop } from '_helpers/prop'
import BlockOutlined from '@material-ui/icons/BlockOutlined'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  container: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: '#ccc',
    marginLeft: '5px',
  },
})

export const TitleCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()
  const classes = useStyles()

  const onClick = () => {
    if (isRowLinkable && !resource?.defaultVariant) {
      editPath && history.push(editPath.replace(':id', resource.uuid))
    }
  }

  const value = prop(resource, accessor)

  return (
    <div onClick={onClick} className={classes.container}>
      {value}
      {resource?.canDelete === false && (
        <BlockOutlined className={classes.icon} />
      )}
    </div>
  )
}

TitleCell.propTypes = {
  resource: PropTypes.shape({
    uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isRowLinkable: PropTypes.bool,
  editPath: PropTypes.string,
}
