import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
} from '@material-ui/core'
import schema from '_schema/reservationEventDate'
import routes from '../routes'
import { Form } from '../../Form'
import { makeStyles } from '@material-ui/core/styles'
import { translate } from '_helpers/translate'
import { AddModal } from '../../../components/embedded/AddModal'
import { ReservationCollection } from './ReservationCollection'

const useStyles = makeStyles(theme => ({
  // Custom dialog styles for different breakpoints
  dialogRoot: {
    // Default minimum width for xs and sm breakpoints
    [theme.breakpoints.down('sm')]: {
      '& .MuiDialog-paper': {
        minWidth: 250, // Smaller width for mobile devices
      },
    },
    // Wider minimum width for md and larger breakpoints
    [theme.breakpoints.up('lg')]: {
      '& .MuiDialog-paper': {
        minWidth: 1024, // Wider width for desktop
      },
    },
  },
  dialogContent: {
    overflowY: 'auto',
  },
  reservationsContainer: {
    marginTop: 30,
  },
}))

const DateEditDialog = ({
  open,
  onClose,
  fullScreen,
  selectedDate = null,
  selectedItem = null,
  definitions = [],
  setCollection = null,
}) => {
  const classes = useStyles()
  const [reservationModalResponse, setReservationModalResponse] = useState(null)
  const defaultData = { parent: selectedItem?.['@id'] }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="date-edit-dialog-title"
      className={classes.dialogRoot}
    >
      <DialogTitle id="date-edit-dialog-title">
        {selectedDate && selectedItem
          ? `Edycja daty ${selectedDate} ${selectedItem.calendarHour}`
          : 'Wybierz datę do edycji.'}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText></DialogContentText>
        {/* Add your form fields here */}
        <Paper>
          {selectedItem && (
            <Form
              definitionSchema={definitions[schema.resource.definition]}
              definitions={definitions}
              customResourceSchema={schema.resource}
              iri={selectedItem?.['@id']}
              method="PUT"
              url={selectedItem?.['@id']}
              collectionPath={routes().index.path}
              storeCollectionId={schema.endpoint}
              sidebar={false}
              width={'auto'}
              buttonsFixed={false}
              showSubmitButton={false}
              showCancelButton={false}
              customSubmitButtonTitle={translate('T_GENERAL_SAVE')}
              handleSuccessAndStay={resource => {
                if (setCollection) {
                  setCollection(null)
                }
              }}
            />
          )}
        </Paper>
        {selectedItem && (
          <div className={classes.reservationsContainer}>
            <ReservationCollection
              endpoint={`${schema.subresources.reservations.endpoint}?iri[parent]=${selectedItem?.['@id']}&order[createdAt]=desc&pagination=false`}
              pid={selectedItem?.uuid}
              parentIri={selectedItem?.['@id']}
              properties={schema.subresources.reservations.properties}
              definitionSchema={
                definitions[schema.subresources.reservations.definition]
              }
              headerTitle={translate('Rezerwacje')}
              panelTitle={translate('Rezerwacja')}
              titleAccessor={`title`}
              statable={false}
              modalResponse={reservationModalResponse}
              setModalResponse={setReservationModalResponse}
              hideAddButton={true}
              orderable={false}
            />
            <AddModal
              title={translate('Dodaj rezerwację')}
              definitionSchema={
                definitions[schema.subresources.reservations.definition]
              }
              itemProperties={schema.subresources.reservations.properties}
              defaultData={defaultData}
              endpoint={schema.subresources.reservations.endpoint}
              itemSchema={schema}
              setModalResponse={setReservationModalResponse}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          Zamknij
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DateEditDialog
