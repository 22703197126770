import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  IconButton,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import ReactJsonView from '@microlink/react-json-view'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { translate } from '_helpers/translate'
import { SaveOutlined } from '@material-ui/icons'
import { getApiUrl } from '../../../_services/apiUrlProvider'
import { authHeader } from '../../../_helpers/authHeader'
import { notification } from '../../../_helpers/notification'
import { fetchDataHandleAuthError } from '../../../_helpers/fetchDataHandleAuthError'
import { useHistory } from 'react-router-dom'
import { Alert } from '@material-ui/lab'

const chartTypes = [
  { value: 'line', label: 'Liniowy' },
  { value: 'bar', label: 'Słupkowy' },
  { value: 'scatter', label: 'Punktowy' },
  { value: 'mixed', label: 'Mieszany' },
  { value: 'pie', label: 'Kołowy' },
  { value: 'boxPlot', label: 'Pudełko i wąsy' },
]

const chartMixedTypes = [
  { value: 'line', label: 'Liniowy' },
  { value: 'bar', label: 'Słupkowy' },
]

const boxPlotLabels = ['Minimum', 'Q1', 'Mediana', 'Q3', 'Maksimum']

const useStyles = makeStyles(theme => ({
  container: {
    padding: 15,
  },
  containerChart: {
    marginTop: 15,
    padding: 10,
  },
  rightBorder: {
    borderRight: 'solid 1px #bbb7b7',
  },
  rightContainer: {
    maxHeight: 400,
    overflowY: 'scroll',
  },
  seriesContainerBorder: {
    borderTop: 'solid 1px #bbb7b7',
    paddingTop: 10,
  },
  deleteIcon: {
    color: '#c12a2a',
  },
  deleteSeriesIconContainer: {
    marginBottom: -12,
  },
  labelText: {
    color: 'rgba(0, 0, 0, .54)',
  },
  buttonsFixed: {
    position: 'fixed',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);',
    background: theme.elements.content,
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gridColumnGap: '24px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 'calc(100% - 56px)',
    bottom: 0,
    right: 0,
    padding: '20px',
    paddingRight: '24px',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 20,
  },
  buttonsFixedOpen: {
    width: 'calc(100% - 240px)',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 56px)',
    },
  },
  cancelButton: {
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.main,
    },
    '&:active': {
      background: theme.palette.error.main,
    },
  },
  colorSelectContainer: {
    display: 'inline-flex',
    marginLeft: 5,
    '& .MuiSelect-select': {
      minHeight: 20,
      minWidth: 180,
      padding: 10,
      paddingRight: 30,
      paddingLeft: 5,
    },
  },
}))

const defaultBoxPlotOptions = {
  boxPlot: {
    colors: {
      upper: '#00E396',
      lower: '#008FFB',
    },
  },
}

export default function ChartDataGenerator({ iri, schema, collectionPath }) {
  const [chartVersion, setChartVersion] = useState(1)
  const [chartType, setChartType] = useState('line')
  const [labels, setLabels] = useState([])
  const [newLabel, setNewLabel] = useState('')
  const [chartSeries, setChartSeries] = useState([])
  const [series, setSeries] = useState([])
  const [showLabelError, setShowLabelError] = useState(false)
  const [chartData, setChartData] = useState({ labels, series })
  const isSidebarOpen = useSelector(state => state.common.isSidebarOpen)
  const [chartTitle, setChartTitle] = useState('')
  const [yAxisTitle, setYAxisTitle] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [resource, setResource] = useState(null)
  const [pieLabelsOutside, setPieLabelsOutside] = useState(false)
  const [boxPlotOptions, setBoxPlotOptions] = useState(defaultBoxPlotOptions)

  const defaultBoxPlotSeries = [
    {
      name: '',
      type: 'boxPlot',
      data: [],
      colors: {
        upper: '#48A676',
        lower: '#366F52',
      },
    },
  ]

  const [boxPlotSeries, setBoxPlotSeries] = useState(defaultBoxPlotSeries)

  const colors = [
    { name: 'Zielony Mirt', code: '#5B9450' },
    { name: 'Błękitny Ocean', code: '#4799E2' },
    { name: 'Miętowa Zieleń', code: '#46E492' },
    { name: 'Limonkowy Blask', code: '#CFE623' },
    { name: 'Jasna Zieleń', code: '#9CE641' },
    { name: 'Koralowy Cień', code: '#F37E6E' },
    { name: 'Pomarańczowy Zmierzch', code: '#F5B042' },
    { name: 'Różowy Pastel', code: '#FFDCFF' },
  ]
  const [selectedColors, setSelectedColors] = useState([])

  const classes = useStyles()
  const history = useHistory()

  const handlePieColorChange = (index, colorCode) => {
    let updatedColors = [...selectedColors]

    if (selectedColors.length !== labels.length) {
      updatedColors = Array(labels.length).fill(colors[0].code)
    }

    updatedColors[index] = colorCode
    setSelectedColors(updatedColors)
  }

  const updateChartVersion = () => {
    setChartVersion(chartVersion + 1)
  }

  const addLabel = () => {
    if (newLabel.trim() !== '') {
      const updated = [...labels, newLabel.trim()]
      setLabels(updated)
      setChartData({ labels: updated, series })
      setNewLabel('')
      if (chartType === 'boxPlot') {
        const updatedBoxPlotSeries = [...boxPlotSeries]
        updatedBoxPlotSeries.forEach(s => {
          s.data.push({
            x: newLabel.trim(),
            y: [0, 0, 0, 0, 0],
          })
        })
        setBoxPlotSeries(updatedBoxPlotSeries)
      }
    } else {
      setShowLabelError(true)
    }
  }

  const removeLabel = index => {
    const updated = labels.filter((_, i) => i !== index)
    setLabels(updated)
    setChartData({ labels: updated, series })
    if (chartType === 'boxPlot') {
      const updatedBoxPlotSeries = [...boxPlotSeries]
      updatedBoxPlotSeries.forEach(s => {
        s.data = s.data.filter((_, i) => i !== index)
      })
      setBoxPlotSeries(updatedBoxPlotSeries)
    }
    updateChartVersion()
  }

  const updatePieColors = () => {
    const updatedColors = Array(labels.length).fill(colors[0].code)
    setSelectedColors(updatedColors)
  }

  const updateChartSeries = (
    newSeries,
    newChartType = chartType,
    newLabels = null
  ) => {
    if (newChartType !== 'mixed') {
      // delete types from series
      newSeries = newSeries.map(s => {
        delete s.type
        return s
      })
    }

    setSeries(newSeries)
    if (newChartType === 'pie') {
      if (newSeries[0]?.data) {
        setChartSeries(newSeries[0].data)
      } else {
        setChartSeries([])
      }
    } else {
      setChartSeries(newSeries)
    }

    // todo setChartData
    setChartData({ labels: newLabels ? newLabels : labels, series: newSeries })
  }

  const addBoxPlotSeries = () => {
    const emptyData = []
    labels.forEach(value => {
      emptyData.push({
        x: value,
        y: [0, 0, 0, 0, 0],
      })
    })

    const updated = [
      ...boxPlotSeries,
      {
        name: '',
        type: 'boxPlot',
        data: [...emptyData],
        colors: {
          upper: colors[0].code,
          lower: colors[1].code,
        },
      },
    ]
    setBoxPlotSeries(updated)
    updateChartVersion()
  }

  const handleBoxPlotSeriesChange = (index, key, value) => {
    const updatedSeries = [...boxPlotSeries]
    updatedSeries[index][key] = value
    setBoxPlotSeries(updatedSeries)
    updateChartVersion()
  }

  const handleBoxPlotSeriesValueChange = (
    seriesIndex,
    dataIndex,
    valueIndex,
    value
  ) => {
    const updatedSeries = [...boxPlotSeries]
    if (dataIndex > labels.length) {
      delete updatedSeries[seriesIndex].data[dataIndex]
    }

    if (dataIndex < labels.length) {
      if (!updatedSeries[seriesIndex].data[dataIndex]) {
        updatedSeries[seriesIndex].data[dataIndex] = {
          x: labels[dataIndex],
          y: [0, 0, 0, 0, 0],
        }
      }

      updatedSeries[seriesIndex].data[dataIndex]['y'][valueIndex] = value
    }
    setBoxPlotSeries(updatedSeries)
    updateChartVersion()
  }

  /*
  const handleBoxPlotSeriesColorChange = (seriesIndex, valueIndex, value) => {
    const updatedSeries = [...boxPlotSeries]
    updatedSeries[seriesIndex].colors[valueIndex] = value

    setBoxPlotSeries(updatedSeries)
    updateChartVersion()
  }
   */

  const removeBoxPlotSeries = index => {
    const updated = boxPlotSeries.filter((_, i) => i !== index)
    setBoxPlotSeries(updated)
    updateChartVersion()
  }

  const handleSeriesChange = (index, key, value) => {
    const updatedSeries = [...series]
    if (key === 'data') {
      updatedSeries[index][key][value.index] = parseFloat(value.data)
    } else {
      updatedSeries[index][key] = value
    }
    updateChartSeries(updatedSeries)
    updateChartVersion()
  }

  const addSeries = () => {
    const updated = [
      ...series,
      { name: '', data: Array(labels.length).fill(0) },
    ]
    updateChartSeries(updated)
    updateChartVersion()
  }

  const removeSeries = index => {
    const updated = series.filter((_, i) => i !== index)
    updateChartSeries(updated)
    updateChartVersion()
  }

  const updateFunctionRef = React.useRef(updateChartSeries)
  const updateChartVersionRef = React.useRef(updateChartVersion)

  useEffect(() => {
    if (!iri) {
      setIsFetching(false)
      return
    }

    setIsFetching(true)

    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      iri,
      'GET',
      { signal },
      response => {
        setResource(response)
        if (response?.data) {
          setChartData(response.data)

          if (response.data?.type) {
            setChartType(response.data.type)
          }

          if (response.data?.labels) {
            setLabels(response.data.labels)
          }

          if (response.data?.series) {
            updateFunctionRef.current(
              response.data.series,
              response.data?.type ? response.data.type : 'line',
              response.data.labels
            )
            //updateChartSeries(response.data.series)
          }

          if (response.data?.pieColors) {
            setSelectedColors(response.data?.pieColors)
          }

          if (response.data?.boxPlotSeries) {
            setBoxPlotSeries(response.data.boxPlotSeries)
          }

          setPieLabelsOutside(response.data?.pieLabelsOutside || false)
          setYAxisTitle(response.data?.yaxis?.title?.text || '')
          setBoxPlotOptions(
            response?.data?.plotOptions
              ? response?.data?.plotOptions
              : defaultBoxPlotOptions
          )
        }
        setChartTitle(response?.workingTitle)
        setIsFetching(false)
        updateChartVersionRef.current()
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }
        setIsFetching(false)

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [iri])

  const handleSubmit = (stay = true) => {
    setIsFetching(true)

    fetch(`${getApiUrl()}${resource['@id']}`, {
      method: 'PUT',
      headers: {
        ...authHeader(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          title: { text: chartTitle },
          ...chartData,
          type: chartType,
          pieColors: selectedColors,
          boxPlotSeries,
          plotOptions: boxPlotOptions,
          yaxis: {
            title: {
              text: yAxisTitle,
            },
          },
          pieLabelsOutside,
        },
        workingTitle: chartTitle,
      }),
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Error')
        }

        return response.json()
      })
      .then(data => {
        setResource(data)
        if (!stay && collectionPath) {
          history.push(collectionPath)
        }

        notification('success', 'Zapisano', 'Zapisano')
        setIsFetching(false)
      })
      .catch(e => {
        notification('error', 'Wystąpił błąd zapisu', e)
        setIsFetching(false)
      })
  }

  return (
    <Box p={4} style={{ marginBottom: 60 }}>
      <TextField
        label="Tytuł"
        value={chartTitle}
        onChange={e => {
          setChartTitle(e.target.value)
        }}
        style={{ marginBottom: 20, width: '100%' }}
      />
      <Paper elevation={0} className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={4} className={classes.rightBorder}>
            <Box mb={2}>
              <FormControl fullWidth>
                <InputLabel id="chartTypeSelect">Typ wykresu</InputLabel>
                <Select
                  id={'chartTypeSelect'}
                  label={'Typ wykresu'}
                  value={chartType}
                  onChange={e => {
                    setChartType(e.target.value)
                    updateChartSeries(series, e.target.value)
                    updatePieColors()
                    updateChartVersion()
                  }}
                  fullWidth
                >
                  {chartTypes.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {chartType === 'boxPlot' && (
              <Box display="flex" style={{ marginBottom: 10 }}>
                <FormControl
                  variant="outlined"
                  className={classes.colorSelectContainer}
                  style={{ marginLeft: 0 }}
                >
                  <InputLabel id="color-select-label">Wybierz kolor</InputLabel>
                  <Select
                    labelId="color-select-label"
                    value={
                      colors.find(
                        color =>
                          color.code === boxPlotOptions.boxPlot.colors.upper
                      )
                        ? boxPlotOptions.boxPlot.colors.upper
                        : colors[0].code
                    }
                    onChange={e => {
                      const newOptions = { ...boxPlotOptions }
                      newOptions.boxPlot.colors.upper = e.target.value
                      setBoxPlotOptions(newOptions)
                      updateChartVersion()
                    }}
                    label="Wybierz kolor"
                  >
                    {colors.map(color => (
                      <MenuItem key={color.code} value={color.code}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              width: 20,
                              height: 20,
                              backgroundColor: color.code,
                              borderRadius: '50%',
                              marginRight: 5,
                              border: '1px solid #ccc',
                            }}
                          />
                          {color.name}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={classes.colorSelectContainer}
                >
                  <InputLabel id="color-select-label">Wybierz kolor</InputLabel>
                  <Select
                    labelId="color-select-label"
                    value={
                      colors.find(
                        color =>
                          color.code === boxPlotOptions.boxPlot.colors.lower
                      )
                        ? boxPlotOptions.boxPlot.colors.lower
                        : colors[0].code
                    }
                    onChange={e => {
                      const newOptions = { ...boxPlotOptions }
                      newOptions.boxPlot.colors.lower = e.target.value
                      setBoxPlotOptions(newOptions)
                      updateChartVersion()
                    }}
                    label="Wybierz kolor"
                  >
                    {colors.map(color => (
                      <MenuItem key={color.code} value={color.code}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              width: 20,
                              height: 20,
                              backgroundColor: color.code,
                              borderRadius: '50%',
                              marginRight: 5,
                              border: '1px solid #ccc',
                            }}
                          />
                          {color.name}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
            <Typography variant="h5">Oś Y</Typography>
            <Box>
              <TextField
                label="Tytuł"
                value={yAxisTitle}
                onChange={e => {
                  setYAxisTitle(e.target.value)
                }}
                style={{ marginBottom: 20, width: '100%' }}
              />
            </Box>
            <Typography variant="h5">Oś X</Typography>
            <Box
              display="flex"
              alignItems="center"
              mb={2}
              style={{ alignItems: 'end' }}
            >
              <TextField
                label="Nowa pozycja"
                value={newLabel}
                onChange={e => {
                  setNewLabel(e.target.value)
                  setShowLabelError(false)
                }}
                style={{ marginRight: 8, width: '100%' }}
                error={showLabelError}
              />
              <Button
                size={'small'}
                variant="contained"
                color={'primary'}
                startIcon={<AddIcon />}
                onClick={addLabel}
                style={{ minWidth: 150 }}
                disabled={isFetching}
              >
                Dodaj pozycję
              </Button>
            </Box>
            <Box display="flex" flexWrap="wrap" style={{ margin: -10 }}>
              {labels.map((label, index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  m={1}
                  p={1}
                  border={1}
                  borderRadius={2}
                >
                  <Typography className={classes.labelText}>{label}</Typography>
                  <IconButton
                    size="small"
                    onClick={() => removeLabel(index)}
                    color="secondary"
                    disabled={isFetching}
                  >
                    <DeleteIcon
                      fontSize="small"
                      className={classes.deleteIcon}
                    />
                  </IconButton>
                </Box>
              ))}
            </Box>
            {chartType === 'pie' && (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={pieLabelsOutside}
                      checked={pieLabelsOutside}
                      onChange={e => setPieLabelsOutside(e.target.checked)}
                    />
                  }
                  label="Wartości na zewnątrz"
                />
                <Box display="flex" flexDirection="column" gap={2}>
                  {labels.map((item, index) => (
                    <FormControl key={index} fullWidth>
                      <InputLabel>Kolor dla {item}</InputLabel>
                      <Select
                        value={selectedColors[index] ?? ''}
                        onChange={e =>
                          handlePieColorChange(index, e.target.value)
                        }
                        label={`Kolor dla ${item}`}
                      >
                        {colors.map(color => (
                          <MenuItem key={color.code} value={color.code}>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Box
                                sx={{
                                  width: 20,
                                  height: 20,
                                  backgroundColor: color.code,
                                  borderRadius: '50%',
                                  marginRight: 5,
                                  border: '1px solid #ccc',
                                }}
                              />
                              {color.name}
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ))}
                </Box>
              </div>
            )}
          </Grid>
          <Grid item xs={8} className={classes.rightContainer}>
            <Typography variant="h5" style={{ marginBottom: 20 }}>
              Serie danych
            </Typography>
            {chartType === 'pie' && series.length > 1 && (
              <Alert severity="warning">
                Dla wykresu kołowego używana jest tylko pierwsza seria
              </Alert>
            )}
            {chartType !== 'boxPlot' &&
              series.map((s, seriesIndex) => (
                <Box
                  key={seriesIndex}
                  mb={2}
                  className={
                    seriesIndex > 0 ? classes.seriesContainerBorder : ''
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                      marginBottom: 15,
                    }}
                  >
                    <TextField
                      label="Nazwa serii"
                      value={s.name}
                      onChange={e =>
                        handleSeriesChange(seriesIndex, 'name', e.target.value)
                      }
                    />
                    <IconButton
                      onClick={() => removeSeries(seriesIndex)}
                      disabled={isFetching}
                      className={classes.deleteSeriesIconContainer}
                    >
                      <Box display="flex" alignItems="center" gap={1}>
                        <DeleteIcon className={classes.deleteIcon} />
                      </Box>
                    </IconButton>
                    {chartType !== 'pie' && (
                      <FormControl
                        variant="outlined"
                        className={classes.colorSelectContainer}
                      >
                        <InputLabel id="color-select-label">
                          Wybierz kolor
                        </InputLabel>
                        <Select
                          labelId="color-select-label"
                          value={s.color ? s.color : colors[0].code}
                          onChange={e => {
                            handleSeriesChange(
                              seriesIndex,
                              'color',
                              e.target.value
                            )
                          }}
                          label="Wybierz kolor"
                        >
                          {colors.map(color => (
                            <MenuItem key={color.code} value={color.code}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Box
                                  sx={{
                                    width: 20,
                                    height: 20,
                                    backgroundColor: color.code,
                                    borderRadius: '50%',
                                    marginRight: 5,
                                    border: '1px solid #ccc',
                                  }}
                                />
                                {color.name}
                              </Box>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {chartType === 'mixed' && (
                      <FormControl
                        variant="outlined"
                        className={classes.colorSelectContainer}
                      >
                        <InputLabel id="series-type-select-label">
                          Wybierz typ
                        </InputLabel>
                        <Select
                          labelId="series-type-select-label"
                          value={s.type ? s.type : chartType}
                          onChange={e => {
                            handleSeriesChange(
                              seriesIndex,
                              'type',
                              e.target.value
                            )
                          }}
                          label="Wybierz typ"
                        >
                          {chartMixedTypes.map(({ value, label }) => (
                            <MenuItem key={value} value={value}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </div>
                  <Box display="flex" flexWrap="wrap">
                    {labels.map((label, dataIndex) => (
                      <TextField
                        key={dataIndex}
                        label={label}
                        type="number"
                        value={s?.data?.[dataIndex] || 0}
                        onChange={e =>
                          handleSeriesChange(seriesIndex, 'data', {
                            index: dataIndex,
                            data: e.target.value,
                          })
                        }
                        style={{ marginRight: 8, marginBottom: 8 }}
                      />
                    ))}
                  </Box>
                </Box>
              ))}

            {chartType === 'boxPlot' &&
              boxPlotSeries.map((s, seriesIndex) => (
                <Box
                  key={seriesIndex}
                  mb={2}
                  className={
                    seriesIndex > 0 ? classes.seriesContainerBorder : ''
                  }
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'end',
                      marginBottom: 15,
                    }}
                  >
                    <TextField
                      label="Nazwa serii"
                      value={s.name}
                      onChange={e =>
                        handleBoxPlotSeriesChange(
                          seriesIndex,
                          'name',
                          e.target.value
                        )
                      }
                    />
                    <IconButton
                      onClick={() => removeBoxPlotSeries(seriesIndex)}
                      disabled={isFetching}
                      className={classes.deleteSeriesIconContainer}
                    >
                      <Box display="flex" alignItems="center" gap={1}>
                        <DeleteIcon className={classes.deleteIcon} />
                      </Box>
                    </IconButton>
                  </div>
                  <Box display="flex" flexWrap="wrap">
                    {labels.map((label, dataIndex) => (
                      <div
                        key={`container-${dataIndex}`}
                        style={{
                          marginBottom: 16,
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {[0, 1, 2, 3, 4].map(i => (
                          <TextField
                            key={`${dataIndex}-${i}`}
                            label={`[${label}] ${boxPlotLabels[i]}`}
                            type="number"
                            value={s.data[dataIndex]?.['y']?.[i] || 0}
                            onChange={e =>
                              handleBoxPlotSeriesValueChange(
                                seriesIndex,
                                dataIndex,
                                i,
                                e.target.value
                              )
                            }
                            style={{ marginRight: 8, marginBottom: 8 }}
                          />
                        ))}
                      </div>
                    ))}
                  </Box>
                </Box>
              ))}

            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={chartType === 'boxPlot' ? addBoxPlotSeries : addSeries}
              disabled={isFetching}
              fullWidth
            >
              Dodaj serię
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Typography variant="h4" gutterBottom style={{ marginTop: 20 }}>
        Wykres poglądowy
      </Typography>
      <div id="chart">
        {/*
        <Chart
          options={state.options}
          series={state.series}
          type="boxPlot"
          height={350}
        />
        */}
      </div>
      <Paper className={classes.containerChart} elevation={1}>
        {chartSeries.length > 0 && (
          <Chart
            key={chartVersion}
            options={{
              labels: labels,
              colors: chartType === 'pie' ? selectedColors : [],
              plotOptions: boxPlotOptions,
            }}
            series={chartType === 'boxPlot' ? boxPlotSeries : chartSeries}
            type={chartType === 'mixed' ? 'line' : chartType}
            height={350}
          />
        )}
      </Paper>
      <Typography variant="h6">Generated JSON Data</Typography>
      <ReactJsonView
        src={chartData}
        collapsed={true}
        enableClipboard={true}
        displayDataTypes={true}
      />
      <div
        className={clsx(
          classes.buttonsFixed,
          isSidebarOpen && classes.buttonsFixedOpen
        )}
      >
        <Button
          variant={'contained'}
          color={'primary'}
          size={'small'}
          startIcon={<SaveOutlined />}
          disabled={isFetching}
          onClick={() => handleSubmit(false)}
        >
          {translate('T_FORM_SAVE')}
        </Button>
        <Button
          variant={'contained'}
          color={'primary'}
          size={'small'}
          startIcon={<SaveOutlined />}
          onClick={() => handleSubmit(true)}
          disabled={isFetching}
        >
          {translate('T_FORM_SAVE_AND_STAY')}
        </Button>
        <Button
          variant={'contained'}
          color={'secondary'}
          size={'small'}
          startIcon={<SaveOutlined />}
          className={classes.cancelButton}
          disabled={isFetching}
          onClick={() => {
            if (collectionPath) {
              history.push(collectionPath)
            }
          }}
        >
          {translate('T_FORM_CANCEL')}
        </Button>
      </div>
    </Box>
  )
}
