import React from 'react'
import { Tooltip } from '@material-ui/core'
import { translate } from '../../../_helpers/translate'
import { PrintOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { getApiUrl } from '../../../_services/apiUrlProvider'
import { authHeader } from '../../../_helpers/authHeader'
import { notification } from '../../../_helpers/notification'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  printIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))

const PrintTicket = ({ iri }) => {
  const classes = useStyles()
  const [isFetching, setIsFetching] = React.useState(false)

  function getPdf() {
    if (isFetching) {
      return
    }

    setIsFetching(true)
    let xtitle = null
    fetch(`${getApiUrl()}/api/reservation_event_reservations/ticket`, {
      method: 'POST',
      headers: {
        ...authHeader(),
      },
      body: JSON.stringify({ iri }),
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Error')
        }
        xtitle = response.headers.get('x-title')
        return response.blob()
      })
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', xtitle ?? 'plik')

        document.body.appendChild(link)

        link.click()
        link.parentNode.removeChild(link)
        setIsFetching(false)
      })
      .catch(e => {
        setIsFetching(false)
        notification('error', 'Error, nie można pobrać', e)
      })
  }

  return isFetching ? (
    <CircularProgress size={20} />
  ) : (
    <Tooltip title={translate('Drukuj bilet')}>
      <PrintOutlined
        className={classes.printIcon}
        onClick={() => {
          getPdf()
        }}
      ></PrintOutlined>
    </Tooltip>
  )
}

export default PrintTicket
