import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Select,
  MenuItem,
  DialogContent,
  DialogContentText,
  Typography,
  Button,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { translate } from '_helpers/translate'
import { constants } from './_state'
import { ElektronicznyNadawcaLabelModal } from '../../pages/ShopOrder/Modal/ElektronicznyNadawcaLabelModal'
import { getApiUrl } from '../../_services/apiUrlProvider'
import schemaElektronicznyNadawca from '../../_schema/shopElektronicznyNadawcaAddShipment'
import schemaInpost from '../../_schema/shopInpostAddShipment'
import { authHeader } from '../../_helpers/authHeader'
import { notification } from '../../_helpers/notification'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { fetchDataHandleAuthError } from '../../_helpers/fetchDataHandleAuthError'
import { statusselected } from '../../pages/ShopOrder/table/_mass/statusselected'
import { InpostLabelModal } from '../../pages/ShopOrder/Modal/InpostLabelModal'
import { Print } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  container: {
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
    fontFamily: theme.typography.fontFamily,
    margin: 8,
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textAlign: 'left',
  },
  title: {
    marginRight: 8,
  },
  select: {
    fontSize: 14,
  },
  generateLabelsButton: {
    marginLeft: 20,
    height: 30,
  },
  generateLabelsWarning: {
    marginLeft: 5,
    color: '#e54a4a',
  },
  generateInpostLabelsWarning: {
    marginLeft: 'auto',
    color: '#e54a4a',
  },
  generateInpostLabesContainer: {
    display: 'flex',
    justifyContent: 'right',
    flexDirection: 'column',
    marginTop: 15,
  },
  generateInpostLabelsButton: {
    maxWidth: 250,
    marginLeft: 'auto',
  },
}))

export const MassSelectChoiceShopOrder = ({
  selectable = [],
  tableState = null,
  tableStateDispatch = null,
  classes = {},
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const [choices, setChoices] = useState([])
  const [statuses, setStatuses] = useState([])
  const [changeSubscriptionStatus, setChangeSubscriptionStatus] = useState(
    false
  )
  const [withSubscription, setWithSubscription] = useState(0)
  const [currentOperation, setCurrentOperation] = useState(null)

  const handleChangeSubscriptionStatus = event => {
    setChangeSubscriptionStatus(event.target.checked)
  }

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    //const endpoint2 = `/api/shop_order_statuses_enum?pagination=false`
    const endpoint2 = `/api/shop_order_statuses?pagination=false`

    fetchDataHandleAuthError(
      endpoint2,
      'GET',
      { signal },
      response => {
        const data = response['hydra:member']
        if (data.length) {
          setStatuses(data)
          const tempChoices = []
          data.map(i =>
            //tempChoices.push(statusselected.status(i['@id'], i.trans))
            tempChoices.push(statusselected.status(i['@id'], i.title))
          )
          setChoices(tempChoices)
        }
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [setChoices])

  const handleMassSelectAction = useCallback(
    e => {
      const operation = selectable.find(item => item.action === e.target.value)

      if (!operation) {
        return
      }

      tableStateDispatch({
        type: constants.MASS_SELECT_ACTION_START,
        payload: {
          action: operation.action,
        },
      })

      operation.execute(tableState, tableStateDispatch)
    },
    [selectable, tableState, tableStateDispatch]
  )

  const handleMassSelectAction2 = useCallback(
    e => {
      const operation = choices.find(item => item.action === e.target.value)

      if (!operation) {
        return
      }

      setCurrentOperation(operation)

      setIsDialogOpen(true)
    },
    [choices]
  )

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
  }

  const handleDialogAction = () => {
    if (!currentOperation) {
      setIsDialogOpen(false)
      return
    }

    currentOperation.execute(
      tableState,
      tableStateDispatch,
      withSubscription > 0 ? changeSubscriptionStatus : false
    )

    setIsDialogOpen(false)
  }

  const isInpost = resource => {
    return ['INPOST_PACZKOMATY', 'INPOST_KURIER'].includes(
      resource?.shippingOperator?.name
    )
  }

  const isPocztaPolska = resource => {
    return ['POCZTA_POLSKA', 'POCZTA_POLSKA_PICKUP_POINT'].includes(
      resource?.shippingOperator?.name
    )
  }

  const canGenerateForOperator = resource => {
    const labelGuid = resource?.elektronicznyNadawcaLabelGuid
    const trackingNumber = resource?.trackingNumber

    const isInpostResult = isInpost(resource)
    const isPocztaPolskaResult = isPocztaPolska(resource)

    if (!isInpostResult && !isPocztaPolskaResult) {
      return false
    }

    // check if inpost has been processed
    if (isInpostResult) {
      return !trackingNumber
    }

    // check if poczta polska has been processed
    return !labelGuid
  }

  const canGenerate = useRef(canGenerateForOperator)

  const [generateLabelsWarning, setGenerateLabelsWarning] = useState([])
  const [inpostLabelPrintWarning, setInpostLabelPrintWarning] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [inpostLabelsFetching, setInpostLabelsFetching] = useState(false)

  useEffect(() => {
    //let invalid = false
    let errors = []
    let inpostErrors = []
    let lastOperator = null
    let lastOperatorInpost = null
    let lastCountry = null
    let lastCashOnDelivery = null
    let withSubscriptionLocal = 0

    const items = tableState.data.selected.map((isSelected, index) => {
      const visSelected =
        typeof isSelected === 'object' ? isSelected.value : isSelected

      if (!visSelected) {
        return null
      }

      if (tableState.data.items[index]?.subscriptionsCount) {
        withSubscriptionLocal++
      }

      if (lastOperator === null) {
        lastOperator = tableState.data.items[index]?.shippingOperator?.value
      }

      if (lastCashOnDelivery === null) {
        lastCashOnDelivery = tableState.data.items[index]?.cashOnDelivery
      }

      if (lastCountry === null) {
        lastCountry = tableState.data.items[index]?.countryTitle
      }

      if (!canGenerate.current(tableState.data.items[index])) {
        //invalid = true
        errors.push('Jedno z zamówień posiada już etykietę lub zawiera błąd')
      }

      if (
        lastOperator !==
          tableState.data.items[index]?.shippingOperator?.value ||
        lastCashOnDelivery !== tableState.data.items[index]?.cashOnDelivery
      ) {
        //invalid = true
        errors.push('Zamówienia muszą być z tego samego operatora przesyłki')
      }

      if (lastCountry !== tableState.data.items[index]?.countryTitle) {
        //invalid = true
        errors.push('Zamówienia muszą być z tego samego kraju')
      }

      if (isInpost(tableState.data.items[index])) {
        if (lastOperatorInpost === null) {
          lastOperatorInpost =
            tableState.data.items[index]?.shippingOperator?.value
        }

        if (
          lastOperatorInpost !==
          tableState.data.items[index]?.shippingOperator?.value
        ) {
          /*
          inpostErrors.push(
            'Zamówienia muszą być z tego samego operatora Inpost'
          )*/
        }
      }

      if (!isInpost(tableState.data.items[index])) {
        inpostErrors.push('Zamówienia muszą być z operatora Inpost')
      } else if (!tableState.data.items[index]?.trackingNumber) {
        inpostErrors.push(
          'Jedno z zamówień nie posiada etykiety: ' +
            tableState.data.items[index]?.orderId
        )
      }

      return tableState.data.items[index]
    })

    const tempChoices = []
    statuses.forEach(status => {
      /*
      if (withSubscriptionLocal > 0 && !status?.subscriptionVariant) {
        tempChoices.push(
          statusselected.status(status['@id'], status.trans, true)
        )
      } else {
        tempChoices.push(
          statusselected.status(status['@id'], status.trans, false)
        )
      }
       */
      tempChoices.push(
        statusselected.status(status['@id'], status.title, false)
      )
    })
    setChoices(tempChoices)
    setWithSubscription(withSubscriptionLocal)

    setSelectedItems(items)
    setGenerateLabelsWarning([...new Set(errors)])
    setInpostLabelPrintWarning([...new Set(inpostErrors)])
  }, [statuses, tableState])

  const defaultClasses = useStyles()

  function getfiledownload(endpoint, defaultData = null, statusSetter = null) {
    let xtitle = null
    if (statusSetter) {
      statusSetter(true)
    }
    fetch(`${getApiUrl()}${endpoint}`, {
      method: 'POST',
      headers: {
        ...authHeader(),
      },
      body: JSON.stringify(
        defaultData ? defaultData : { orders: selectedItems }
      ),
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Error')
        }
        xtitle = response.headers.get('x-title')
        return response.blob()
      })
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', xtitle ?? 'plik')

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()
        if (statusSetter) {
          statusSetter(false)
        }

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
      .catch(e => {
        if (statusSetter) {
          statusSetter(false)
        }
        notification('error', 'Error, nie można pobrać', e)
      })
  }

  return !tableState.data.selected.reduce(
    (acc, current) =>
      acc || (typeof current === 'boolean' ? current : current.value),
    false
  ) ? null : (
    <div className={clsx(defaultClasses.container, classes.container)}>
      <span className={clsx(defaultClasses.title, classes.title)}>
        {translate('T_GENERAL_MASS_SELECT_ACTION')}
      </span>
      <Select
        id="mass-select"
        value="choose"
        classes={{
          select: defaultClasses.select,
        }}
        onChange={handleMassSelectAction}
        className={defaultClasses.separator}
      >
        <MenuItem value="choose" key="choose">
          {translate('T_GENERAL_MASS_SELECT_CHOOSE')}
        </MenuItem>
        {selectable.map(item => (
          <MenuItem value={item.action} key={item.action}>
            {item.title}
          </MenuItem>
        ))}
      </Select>

      {choices.length > 0 && (
        <>
          <span
            style={{ marginLeft: 15 }}
            className={clsx(defaultClasses.title, classes.title)}
          >
            lub zmień status
          </span>
          <Select
            id="mass-select2"
            value="choose"
            classes={{
              select: defaultClasses.select,
            }}
            onChange={handleMassSelectAction2}
            className={defaultClasses.separator}
          >
            <MenuItem value="choose" key="choose">
              {translate('T_GENERAL_MASS_SELECT_CHOOSE')}
            </MenuItem>
            {choices.map(item => (
              <MenuItem
                value={item.action}
                key={item.action}
                disabled={item.disabled}
              >
                {item.title}
              </MenuItem>
            ))}
          </Select>
          <Dialog
            onClose={handleCloseDialog}
            aria-labelledby="simple-dialog-title"
            open={isDialogOpen}
          >
            <DialogTitle id="simple-dialog-title" disableTypography>
              <Typography variant="h4">
                Czy na pewno chcesz zmienić status wybranych rekordów?
              </Typography>
            </DialogTitle>
            <DialogContent>
              {withSubscription > 0 && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={changeSubscriptionStatus}
                      onChange={handleChangeSubscriptionStatus}
                      color="primary"
                    />
                  }
                  label={'Zmień statusy subskrypcji'}
                  labelPlacement="end"
                />
              )}
              <DialogContentText id="alert-dialog-description"></DialogContentText>
            </DialogContent>
            <DialogActions
              style={{ padding: '16px 24px', justifyContent: 'flex-start' }}
            >
              <Button
                autoFocus
                variant="outlined"
                color="primary"
                style={{ borderWidth: '2px' }}
                onClick={handleCloseDialog}
              >
                Anuluj
              </Button>
              <Button
                color="primary"
                variant={'contained'}
                onClick={handleDialogAction}
              >
                Zmień statusy
              </Button>
            </DialogActions>
          </Dialog>
          {generateLabelsWarning.length === 0 &&
            isPocztaPolska(
              selectedItems?.filter(item => item !== null)?.[0]
            ) && (
              <ElektronicznyNadawcaLabelModal
                onClick={() => {
                  getfiledownload(schemaElektronicznyNadawca.endpointPdf)
                }}
                defaultData={{
                  orders: selectedItems
                    .filter(item => item !== null)
                    .map(item => item.uuid),
                }}
                tableState={tableState}
                tableStateDispatch={tableStateDispatch}
                resource={selectedItems?.filter(item => item !== null)}
                disabledButton={generateLabelsWarning.length > 0}
              ></ElektronicznyNadawcaLabelModal>
            )}
          {generateLabelsWarning.length === 0 &&
            isInpost(selectedItems?.filter(item => item !== null)?.[0]) && (
              <div>
                <InpostLabelModal
                  onClick={() => {
                    getfiledownload(schemaInpost.endpointPdf)
                  }}
                  defaultData={{
                    orders: selectedItems
                      .filter(item => item !== null)
                      .map(item => item.uuid),
                  }}
                  tableState={tableState}
                  tableStateDispatch={tableStateDispatch}
                  resource={selectedItems?.filter(item => item !== null)}
                  disabledButton={generateLabelsWarning.length > 0}
                ></InpostLabelModal>
              </div>
            )}
          <span
            hidden={!generateLabelsWarning.length > 0}
            className={defaultClasses.generateLabelsWarning}
          >
            {generateLabelsWarning.join(', ')}
          </span>
        </>
      )}
      {selectedItems.length > 0 && (
        <div className={defaultClasses.generateInpostLabesContainer}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Print />}
            disabled={
              inpostLabelPrintWarning.length > 0 || inpostLabelsFetching
            }
            className={defaultClasses.generateInpostLabelsButton}
            onClick={() => {
              getfiledownload(
                schemaInpost.endpointMultiPdf,
                {
                  orders: selectedItems
                    .filter(item => item !== null)
                    .map(item => item.uuid),
                },
                setInpostLabelsFetching
              )
            }}
          >
            Drukuj etykiety (Inpost)
          </Button>
          <Button
            style={{ marginTop: 5, fontSize: 14 }}
            variant="contained"
            color="primary"
            startIcon={<Print />}
            disabled={
              inpostLabelPrintWarning.length > 0 || inpostLabelsFetching
            }
            className={defaultClasses.generateInpostLabelsButton}
            onClick={() => {
              getfiledownload(
                schemaInpost.endpointMultiPdf + '?type=a6',
                {
                  orders: selectedItems
                    .filter(item => item !== null)
                    .map(item => item.uuid),
                },
                setInpostLabelsFetching
              )
            }}
          >
            Drukuj etykiety a6 (Inpost)
          </Button>
          <div
            hidden={!inpostLabelPrintWarning.length > 0}
            className={defaultClasses.generateInpostLabelsWarning}
          >
            {inpostLabelPrintWarning.join(', ')}
          </div>
        </div>
      )}
    </div>
  )
}

MassSelectChoiceShopOrder.propTypes = {
  selectable: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      execute: PropTypes.func.isRequired,
    })
  ),
  tableState: PropTypes.shape({
    data: PropTypes.shape({
      selected: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.shape({
            value: PropTypes.bool,
          }),
        ])
      ),
      selectAll: PropTypes.bool,
    }).isRequired,
    isInit: PropTypes.bool,
    isFetching: PropTypes.bool,
  }),
  tableStateDispatch: PropTypes.func,
  classes: PropTypes.shape({
    container: PropTypes.string,
    title: PropTypes.string,
  }),
}
