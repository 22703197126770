import FIELD_TYPES from '../components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/shop_order_statuses',
  resource: {
    definition: '#ShopOrderStatus',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {
            type: FIELD_TYPES.STRING,
            description: 'Nazwa',
          },
        },
      },
      paid: {
        type: FIELD_TYPES.BOOLEAN,
        description: 'Zamówienie opłacone',
      },
      returnable: {
        type: FIELD_TYPES.BOOLEAN,
        description: 'Można zwrócić',
      },
      forOrder: {
        type: FIELD_TYPES.BOOLEAN,
        description: 'Dla zamówienia',
      },
      forSubscription: {
        type: FIELD_TYPES.BOOLEAN,
        description: 'Dla prenumeraty',
      },
      sendEmail: {
        type: FIELD_TYPES.BOOLEAN,
        description: 'Wysłać powiadomienie email',
      },
      icon: {
        label: 'Ikona',
        description: 'Ikona',
        type: FIELD_TYPES.RESOURCE,
        endpoint: '/api/shop_order_status_icons?pagination=false',
        titleAccessor: 'trans',
        transTitle: true,
      },
    },
  },
}

export default schema
