import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import randomHash from 'random-hash'
import { FormControl, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from '_helpers/validate'
import { translate } from '_helpers/translate'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
  },
  formControllFullWidth: {
    minWidth: '100%',
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
  radioGroup: {
    flexDirection: 'row',
  },
}))

export const RadioType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  choices,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
  isNumber = false,
}) => {
  const [id] = useState(randomHash())

  const handleChange = e => {
    const value = isNumber ? Number(e.target.value) : e.target.value
    setValue(name, !value || value === 'null' ? null : value)
    validateField(!value || value === 'null' ? null : value)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(
    () => {
      if (!initialValue) {
      } else {
        validateField(initialValue)
      }

      if (initialValue && !value) {
        setValue(name, initialValue)
      }
    },
    // eslint-disable-next-line
    [validateField, initialValue, setValue, name]
  )

  const classes = useStyles()

  return (
    <FormControl
      className={clsx(
        classes.formControl,
        fullWidth && classes.formControllFullWidth
      )}
      error={renderError && !!error}
      disabled={disabled || !Object.keys(choices).length}
    >
      <RadioGroup
        id={id}
        name={name}
        value={
          Object.keys(choices).length
            ? value ||
              (!validators || !validators.includes('required') ? 'null' : '')
            : ''
        }
        onChange={handleChange}
        className={classes.radioGroup}
      >
        {Object.keys(choices).map(key => (
          <FormControlLabel
            key={key}
            value={key}
            control={<Radio />}
            label={translate(choices[key])}
            labelPlacement={'end'}
          />
        ))}
      </RadioGroup>
      <FormHelperText>
        {translate(renderError && error ? error : hint)}
      </FormHelperText>
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {Object.keys(choices).length &&
            compareValue &&
            translate(choices[compareValue])}
        </div>
      )}
    </FormControl>
  )
}

RadioType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  compareValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  compare: PropTypes.bool,
  choices: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  isNumber: PropTypes.bool,
}
