import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { authHeader } from '_helpers/authHeader'
import { notification } from '_helpers/notification'
import { getApiUrl } from '_services/apiUrlProvider'
import { Loader } from 'components/Loader'
import { MatterTypeFilter } from './MatterTypeFilter'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  input: {
    textAlign: 'left',
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    '& input': {
      paddingTop: 13,
    },
  },
})

export const ControlList = () => {
  const [isFetching, setIsFetching] = React.useState(false)
  const [type, setType] = React.useState(null)
  const [error, setError] = React.useState(false)
  const exportAll = () => {
    if (!type) {
      setError(true)
      return
    }

    setIsFetching(true)
    setError(false)
    let url = `${getApiUrl()}/api/matter_form_permission/csv`
    let xtitle = null
    fetch(url, {
      method: 'POST',
      headers: {
        ...authHeader(),
      },
      body: JSON.stringify({
        items: [],
        type: type,
      }),
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Error')
        }
        xtitle = response.headers.get('x-title')
        return response.blob()
      })
      .then(blob => {
        setIsFetching(false)
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', xtitle ?? 'lista.csv')

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
      .catch(e => {
        setIsFetching(false)
        notification('error', 'Error, nie można pobrać', e)
      })
  }

  const setTypeCallback = (name, value) => {
    setType(value)
    setError(false)
  }

  const defaultClasses = useStyles()

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Card style={{ marginTop: '15px' }}>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Eksport listy
            </Typography>
            <Typography color="textSecondary" style={{ fontSize: '0.4em' }}>
              ( csv, windows-1250, rozdzielony średnikiem )
            </Typography>

            {isFetching ? (
              <Loader style={{ marginTop: 22 }} />
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginTop: 15 }}
                onClick={exportAll}
              >
                Pobierz
              </Button>
            )}
            <MatterTypeFilter
              title="Typ sprawy"
              error={error}
              value={type}
              name="filter-to-exel-csv-export"
              classes={defaultClasses}
              setFilter={setTypeCallback}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
