export const modifyPropertiesByPage = (properties, resource, schema) => {
  // let modifiedProperties = Object.assign({}, properties)
  // modifiedProperties.translations.properties = Object.assign(
  //   {},
  //   properties.translations.properties
  // )
  // modifiedProperties.options.properties = Object.assign(
  //   {},
  //   properties.options.properties
  // )
  // modifiedProperties.options.properties.translations = Object.assign(
  //   {},
  //   properties.options.properties.translations
  // )
  // modifiedProperties.options.properties.translations.properties = Object.assign(
  //   {},
  //   properties.options.properties.translations.properties
  // )

  let modifiedProperties = JSON.parse(JSON.stringify(properties))

  let idname = resource.type

  switch (idname) {
    case 'textarea':
      delete modifiedProperties.fileSize
      delete modifiedProperties.fileExtension
      delete modifiedProperties.maxCount
      delete modifiedProperties.items
      delete modifiedProperties.options
      return modifiedProperties

    case 'select':
    case 'multiple':
    case 'checkbox':
    case 'radio':
      delete modifiedProperties.fileSize
      delete modifiedProperties.fileExtension
      delete modifiedProperties.textareaSize
      delete modifiedProperties.maxCount
      //delete modifiedProperties.options.properties.translations.properties
      //  .shortLead
      //delete modifiedProperties.options.properties.required
      //delete modifiedProperties.options.properties.translations.properties.title
      //  .hint
      return modifiedProperties

    case 'file':
      delete modifiedProperties.textareaSize
      delete modifiedProperties.maxCount
      delete modifiedProperties.options
      return modifiedProperties

    case 'number':
      delete modifiedProperties.fileSize
      delete modifiedProperties.fileExtension
      delete modifiedProperties.textareaSize
      delete modifiedProperties.options
      return modifiedProperties

    case 'header':
      delete modifiedProperties.fileSize
      delete modifiedProperties.fileExtension
      delete modifiedProperties.textareaSize
      delete modifiedProperties.options
      delete modifiedProperties.maxCount
      delete modifiedProperties.required
      delete modifiedProperties.translations.properties.shortLead
      delete modifiedProperties.translations.properties.placeholder
      delete modifiedProperties.translations.properties.errorMessage
      return modifiedProperties

    case 'consent':
      delete modifiedProperties.fileSize
      delete modifiedProperties.fileExtension
      delete modifiedProperties.textareaSize
      delete modifiedProperties.maxCount
      //modifiedProperties.options.properties.translations.properties.title.hint =
      //  'nazwa widoczna tylko w panelu'
      //modifiedProperties.options.properties.translations.properties.shortLead.label =
      //  'Treść zgody'
      return modifiedProperties
    // case 'xxx':
    //   modifiedProperties.audiodescription = {
    //     description: 'Audiodeskrypcja',
    //     hint: 'link',
    //     maxLength: 511,
    //     type: 'string',
    //   }
    //   delete modifiedProperties.translations.properties.subtitle
    //   return modifiedProperties

    default:
      delete modifiedProperties.fileSize
      delete modifiedProperties.fileExtension
      delete modifiedProperties.textareaSize
      delete modifiedProperties.options
      delete modifiedProperties.maxCount
      return modifiedProperties
  }
}
