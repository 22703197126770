import React from 'react'
import { Route } from 'react-router-dom'
import { Collection } from './Collection'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import parentSchema from '_schema/bipPage'
import Archive from '../../_icons/archive'

const basePath = '/bip_page_article_archive'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: Archive,
      },
    },
    {
      title: 'Archiwum postępowań',
      resourceAccess: parentSchema.access,
      indexPath: basePath,
    }
  )

export default routes
