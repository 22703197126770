import React from 'react'
import { SvgIcon } from '@material-ui/core'

function ArchiveCategory(props) {
  return (
    <SvgIcon {...props} width="15" height="16" viewBox="0 0 15 16" fill="none">
      <path
        d="M5.832 0h-5a.837.837 0 0 0-.59.242.837.837 0 0 0-.242.59v5a.83.83 0 0 0 .832.836h5c.223 0 .434-.09.59-.246a.834.834 0 0 0 .246-.59v-5A.83.83 0 0 0 5.832 0ZM5 5H1.668V1.668H5Zm9.168-5h-5a.83.83 0 0 0-.836.832v5c0 .223.09.434.246.59a.834.834 0 0 0 .59.246h5A.83.83 0 0 0 15 5.832v-5a.837.837 0 0 0-.242-.59.837.837 0 0 0-.59-.242Zm-.836 5H10V1.668h3.332Zm-7.5 3.332h-5A.83.83 0 0 0 0 9.168v5c0 .219.086.434.242.59a.837.837 0 0 0 .59.242h5a.83.83 0 0 0 .836-.832v-5a.834.834 0 0 0-.246-.59.834.834 0 0 0-.59-.246Zm-.832 5H1.668V10H5Zm6.668-5a3.34 3.34 0 0 0-3.336 3.336A3.338 3.338 0 0 0 11.668 15 3.338 3.338 0 0 0 15 11.668a3.338 3.338 0 0 0-3.332-3.336Zm0 5a1.666 1.666 0 1 1-.003-3.333 1.666 1.666 0 0 1 .003 3.333Zm0 0"
        fill="#aaa"
        style={{ color: 'transparent' }}
      />
    </SvgIcon>
  )
}

export default ArchiveCategory
