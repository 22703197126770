import React, { useState } from 'react'
import { Form } from 'pages'
import schema from '_schema/matterFormPermission'
import routes from './routes'
import { LANGS_IRI } from '../../_lib/langs'
import { authHeader } from '../../_helpers/authHeader'
import { notification } from '../../_helpers/notification'
import { Button } from '@material-ui/core'
import { Print } from '@material-ui/icons'
import { getApiUrl } from '../../_services/apiUrlProvider'
import { makeStyles } from '@material-ui/styles'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const useStyles = makeStyles(theme => ({
    container: {
      padding: theme.spacing(4),
      backgroundColor: 'transparent',
    },
    button: {
      margin: theme.spacing(1),
    },
  }))

  const classes = useStyles()

  const iri = `${schema.endpoint}/${match.params.id}`
  const [parentResource, setParentResource] = useState(null)

  function getfiledownload() {
    let xtitle = null
    fetch(`${getApiUrl()}${schema.endpointPdf}`, {
      method: 'POST',
      headers: {
        ...authHeader(),
      },
      body: JSON.stringify({ uuid: parentResource?.uuid }),
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Error')
        }
        xtitle = response.headers.get('x-title')
        return response.blob()
      })
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', xtitle ?? 'plik')

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
      .catch(e => {
        notification('error', 'Error, nie można pobrać', e)
      })
  }

  return (
    <div>
      <div className={classes.container}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Print />}
          onClick={() => {
            getfiledownload()
          }}
        >
          Drukuj
        </Button>
      </div>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        definitions={definitions}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        collectionPath={routes().index.path}
        storeCollectionId={schema.endpoint}
        sidebar={true}
        width={600}
        setParentResource={setParentResource}
        modifyProperties={(properties, resource, schema) => {
          if (resource && resource.parent && resource.parent.type) {
            if (
              resource.parent.type.value !== 'car' &&
              resource.parent.type.value !== 'car_dmo' &&
              resource.parent.type.value !== 'car_dw'
            ) {
              delete properties.fee2Item
            }
            if (resource.parent.type.value === 'move') {
              delete properties.fee1Item
            }
          }
          if (resource?.groupConfig && resource?.groupConfig.length > 0) {
            for (let i = 0; i < resource.groupConfig.length; i++) {
              properties['group_config_' + resource.groupConfig[i].uuid] = {
                type: 'multipleResource',
                description:
                  resource.groupConfig[i]['translations'][LANGS_IRI.PL].title,
                endpoint: `/api/matter_form_config_group_items?pagination=false&iri[parent]=${resource.groupConfig[i]['@id']}`,
                titleAccessor: `translations.${LANGS_IRI.PL}.title`,
              }
            }
          }
          return properties
        }}
      ></Form>
    </div>
  )
}
