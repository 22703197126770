import home from 'pages/Home/routes'
import admins from 'pages/Admins/routes'
import adminGroups from 'pages/AdminGroups/routes'
import loginEntries from 'pages/LoginEntries/routes'
import registryEntries from 'pages/RegistryEntries/routes'
import settings from 'pages/Settings/routes'
import profile from 'pages/Profile/routes'
import pages from 'pages/Pages/routes'
import translatorEntries from 'pages/TranslatorEntries/routes'
import packageGalleries from 'pages/PackageGalleries/routes'
import packageGalleryItems from 'pages/PackageGalleryItems/routes'
import packageFiles from 'pages/PackageFiles/routes'
import packageFaqs from 'pages/PackageFaqs/routes'
import packageLogotypes from 'pages/PackageLogotypes/routes'
import packageLogotypeItems from 'pages/PackageLogotypeItems/routes'
import news from 'pages/News/routes'
import newsCategory from 'pages/NewsCategory/routes'
import contest from 'pages/Contest/routes'
import config from 'pages/Config/routes'
import socialMediaItem from 'pages/SocialMediaItem/routes'
import socialMediaItemListen from 'pages/SocialMediaItemListen/routes'
import homeBanner from 'pages/HomeBanner/routes'
import customer from 'pages/Customer/routes'
import customerDeleted from 'pages/CustomerDeleted/routes'
import footer from 'pages/Footer/routes'
import event from 'pages/Event/routes'
import eventCategory from 'pages/EventCategory/routes'
import eventLocation from 'pages/EventLocation/routes'
import trail from 'pages/Trail/routes'
import trailCategory from 'pages/TrailCategory/routes'
import trailSubcategory from 'pages/TrailSubcategory/routes'
import trailReview from 'pages/TrailReview/routes'
import park from 'pages/Park/routes'
import volunteering from 'pages/Volunteering/routes'
import volunteeringCategory from 'pages/VolunteeringCategory/routes'
import volunteeringAccommodation from 'pages/VolunteeringAccommodation/routes'
import volunteeringLocation from 'pages/VolunteeringLocation/routes'
import volunteeringConfig from 'pages/VolunteeringConfig/routes'
import volunteerTimesheet from 'pages/VolunteerTimesheet/routes'
import volunteerSubmission from 'pages/VolunteeringSubmission/routes'
import volunteeringEvent from 'pages/VolunteeringEvent/routes'
import volunteerUser from 'pages/VolunteerUser/routes'
import shopProduct from 'pages/ShopProduct/routes'
import shopProductReview from 'pages/ShopProductReview/routes'
import shopVariant from 'pages/ShopVariant/routes'
import shopCategory from 'pages/ShopCategory/routes'
import shopDiscountCode from 'pages/ShopDiscountCode/routes'
import shopPaymentMethod from 'pages/ShopPaymentMethod/routes'
import shopPromotion from 'pages/ShopPromotion/routes'
import shopShippingArea from 'pages/ShopShippingArea/routes'
import shopShippingCountry from 'pages/ShopShippingCountry/routes'
import shopShippingType from 'pages/ShopShippingType/routes'
import shopOrder from 'pages/ShopOrder/routes'
import shopOrderStatus from 'pages/ShopOrderStatus/routes'
import shopFooter from 'pages/ShopFooter/routes'
import shopSubscription from 'pages/ShopSubscription/routes'
import shopElektronicznyNadawca from 'pages/ShopElektronicznyNadawca/routes'
import shopConfig from 'pages/ShopConfig/routes'
import shopReport from 'pages/ShopReport/routes'
import shopReturn from 'pages/ShopReturn/routes'
import crewItem from 'pages/CrewItem/routes'
import bipPage from 'pages/BipPage/routes'
import bipEntity from 'pages/BipEntity/routes'
import bipMenuCategory from 'pages/BipMenuCategory/routes'
import bipPageArticleCategory from 'pages/BipPageArticleCategory/routes'
import bipPageArticleArchive from 'pages/BipPageArticleArchive/routes'
import formset from 'pages/Formset/routes'
import tourismNotice from 'pages/TourismNotice/routes'
import tourismNoticeArticle from 'pages/TourismNoticeArticle/routes'
import mainMenu from 'pages/MainMenu/routes'
import homeBlocks from 'pages/HomeBlocks/routes'
import accomm from 'pages/Accommodation/routes'
import accommCat from 'pages/AccommodationCategory/routes'
import accommUser from 'pages/AccommodationUser/routes'
import accommConf from 'pages/AccommodationConfig/routes'
import accomMessage from 'pages/AccommodationMessage/routes'
import accommPdf from 'pages/AccommodationPdf/routes'
import accommNot from 'pages/AccommodationNotice/routes'
import tourney from 'pages/Tourney/routes'
import tourneyUser from 'pages/TourneyUser/routes'
import tourneyConfig from 'pages/TourneyConfig/routes'
import matter from 'pages/Matter/routes'
import matterFormConfigGroup from 'pages/MatterFormConfigGroup/routes'
import matterFormRequest from 'pages/MatterFormRequest/routes'
import matterFormPermission from 'pages/MatterFormPermission/routes'
import guides from 'pages/Guides/routes'
import guideFees from 'pages/GuideFees/routes'
import guidePermissions from 'pages/GuidePermissions/routes'
import guideLicences from 'pages/GuideLicences/routes'
import guideLanguages from 'pages/GuideLanguages/routes'
import guideFilters from 'pages/GuideFilters/routes'
import seaEyeFiacres from 'pages/SeaEyeFiacres/routes'
import seaEyeHorses from 'pages/SeaEyeHorses/routes'
import seaEyeInspections from 'pages/SeaEyeInspections/routes'
import seaEyeHistoryEntries from 'pages/SeaEyeHistoryEntries/routes'
import churchiscoFiacres from 'pages/ChurchiscoFiacres/routes'
import churchiscoInspections from 'pages/ChurchiscoInspections/routes'
import churchiscoHistoryEntries from 'pages/ChurchiscoHistoryEntries/routes'
import lesson from 'pages/Lesson/routes'
import lessonSchedule from 'pages/LessonSchedule/routes'
import lessonSubmission from 'pages/LessonSubmission/routes'
import lessonMySubmission from 'pages/LessonMySubmission/routes'
import lessonCommune from 'pages/LessonCommune/routes'
import lessonDistrict from 'pages/LessonDistrict/routes'
import lessonHost from 'pages/LessonHost/routes'
import lessonConfig from 'pages/LessonConfig/routes'
import weatherLocation from 'pages/WeatherLocation/routes'
import serachedWord from 'pages/SearchedWord/routes'
import seoRedirect from 'pages/SeoRedirect/routes'
import matterCalendar from 'pages/MatterCalendar/routes'
import changelog from 'pages/Changelog/routes'
import managerMedia from 'pages/ManagerMedia/routes'
import deletedUser from 'pages/DeletedUser/routes'
import chart from 'pages/Chart/routes'
import reservationEvent from 'pages/ReservationEvent/routes'
import reservationEventReservation from 'pages/ReservationEvent/routesReservation'
import exceptionLog from 'pages/ExceptionLog/routes'
import exceptionLogConfig from 'pages/ExceptionLogConfig/routes'

const routes = () => [
  ...getArray(home()),
  ...getArray(pages()),
  ...getArray(packageGalleries()),
  ...getArray(packageGalleryItems()),
  ...getArray(packageFiles()),
  ...getArray(packageFaqs()),
  ...getArray(packageLogotypes()),
  ...getArray(packageLogotypeItems()),
  ...getArray(formset()),
  ...getArray(admins()),
  ...getArray(adminGroups()),
  ...getArray(loginEntries()),
  ...getArray(registryEntries()),
  ...getArray(translatorEntries()),
  ...getArray(settings()),
  ...getArray(profile()),
  ...getArray(news()),
  ...getArray(newsCategory()),
  ...getArray(contest()),
  ...getArray(matter()),
  ...getArray(matterFormConfigGroup()),
  ...getArray(matterFormRequest()),
  ...getArray(matterFormPermission()),
  ...getArray(matterCalendar()),
  ...getArray(mainMenu()),
  ...getArray(homeBlocks()),
  ...getArray(config()),
  ...getArray(socialMediaItem()),
  ...getArray(socialMediaItemListen()),
  ...getArray(homeBanner()),
  ...getArray(customer()),
  ...getArray(customerDeleted()),
  ...getArray(footer()),
  ...getArray(event()),
  ...getArray(eventCategory()),
  ...getArray(eventLocation()),
  ...getArray(trail()),
  ...getArray(trailCategory()),
  ...getArray(trailSubcategory()),
  ...getArray(trailReview()),
  ...getArray(park()),
  ...getArray(accomm()),
  ...getArray(accommCat()),
  ...getArray(accommUser()),
  ...getArray(accommConf()),
  ...getArray(accomMessage()),
  ...getArray(accommPdf()),
  ...getArray(accommNot()),
  ...getArray(tourney()),
  ...getArray(tourneyUser()),
  ...getArray(tourneyConfig()),
  ...getArray(volunteering()),
  ...getArray(volunteeringCategory()),
  ...getArray(volunteeringAccommodation()),
  ...getArray(volunteeringLocation()),
  ...getArray(volunteeringConfig()),
  ...getArray(volunteerTimesheet()),
  ...getArray(volunteerSubmission()),
  ...getArray(volunteeringEvent()),
  ...getArray(volunteerUser()),
  ...getArray(shopProduct()),
  ...getArray(shopProductReview()),
  ...getArray(shopVariant()),
  ...getArray(shopCategory()),
  ...getArray(shopDiscountCode()),
  ...getArray(shopPaymentMethod()),
  ...getArray(shopPromotion()),
  ...getArray(shopShippingArea()),
  ...getArray(shopShippingCountry()),
  ...getArray(shopShippingType()),
  ...getArray(shopOrder()),
  ...getArray(shopOrderStatus()),
  ...getArray(shopFooter()),
  ...getArray(shopSubscription()),
  ...getArray(shopElektronicznyNadawca()),
  ...getArray(shopConfig()),
  ...getArray(shopReport()),
  ...getArray(shopReturn()),
  ...getArray(crewItem()),
  ...getArray(bipPage()),
  ...getArray(bipEntity()),
  ...getArray(bipMenuCategory()),
  ...getArray(bipPageArticleCategory()),
  ...getArray(bipPageArticleArchive()),
  ...getArray(tourismNotice()),
  ...getArray(tourismNoticeArticle()),
  ...getArray(guides()),
  ...getArray(guideFees()),
  ...getArray(guidePermissions()),
  ...getArray(guideLicences()),
  ...getArray(guideLanguages()),
  ...getArray(guideFilters()),
  ...getArray(seaEyeFiacres()),
  ...getArray(seaEyeHorses()),
  ...getArray(seaEyeInspections()),
  ...getArray(seaEyeHistoryEntries()),
  ...getArray(churchiscoFiacres()),
  ...getArray(churchiscoInspections()),
  ...getArray(churchiscoHistoryEntries()),
  ...getArray(lesson()),
  ...getArray(lessonSchedule()),
  ...getArray(lessonSubmission()),
  ...getArray(lessonMySubmission()),
  ...getArray(lessonCommune()),
  ...getArray(lessonDistrict()),
  ...getArray(lessonHost()),
  ...getArray(lessonConfig()),
  ...getArray(weatherLocation()),
  ...getArray(serachedWord()),
  ...getArray(seoRedirect()),
  ...getArray(changelog()),
  ...getArray(managerMedia()),
  ...getArray(deletedUser()),
  ...getArray(chart()),
  ...getArray(reservationEvent()),
  ...getArray(reservationEventReservation()),
  ...getArray(exceptionLog()),
  ...getArray(exceptionLogConfig()),
]

const getArray = routes => Object.values(routes)

export default routes
