import React from 'react'
import ReactJsonView from '@microlink/react-json-view'
import { Typography } from '@material-ui/core'
import { translate } from '_helpers/translate'

export const JsonType = ({
  endpoint = null,
  name,
  label,
  hint,
  initialValue,
  compareValue,
  compare,
  disabled,
  form,
  value,
  setValue,
}) => {
  return (
    <>
      <Typography variant="h6">{translate(label)}</Typography>
      <ReactJsonView
        src={value || {}}
        collapsed={false}
        enableClipboard={true}
        displayDataTypes={true}
      />
    </>
  )
}
