import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import { notification } from '../../../_helpers/notification'
import clsx from 'clsx'
import { Title } from '../../Title'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { getApiUrl } from '../../../_services/apiUrlProvider'
import { makeStyles } from '@material-ui/styles'
import { authHeader } from '../../../_helpers/authHeader'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4),
    backgroundColor: 'transparent',
  },
  grid: {
    marginBottom: 70,
  },
  header: {
    marginBottom: 20,
  },
}))

export const ReservationEventFormDataType = ({
  label = 'Dodatkowe pola',
  form,
  value,
}) => {
  const defaultClasses = useStyles()
  const classes = useStyles()

  function renderHTML(xxs) {
    return { __html: xxs }
  }

  const Wrapper = ({ children, wrapWithPaper }) =>
    wrapWithPaper ? (
      <Paper withPadding={false}>{children}</Paper>
    ) : (
      <div>{children}</div>
    )

  function getFileDownload(url) {
    let xtitle = null
    fetch(url, {
      method: 'GET',
      headers: {
        ...authHeader(),
      },
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Error')
        }
        xtitle = response.headers.get('x-title')
        return response.blob()
      })
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', xtitle ?? 'plik')

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
      .catch(e => {
        notification('error', 'Error, nie można pobrać', e)
      })
  }

  return (
    <Grid container spacing={3}>
      <Wrapper wrapWithPaper={false}>
        <div
          className={clsx(defaultClasses.root, classes.root)}
          style={{
            width: '100%',
          }}
        >
          <div className={clsx(defaultClasses.field, classes.field)}>
            <br />
            <Title className={classes.header}>{translate(label)}:</Title>
            {value &&
              value.map((section, index) => (
                <div key={index}>
                  {section.items.map((item, itemIndex) => (
                    <div key={itemIndex} style={{ padding: '5px' }}>
                      <span style={{ fontWeight: 800 }}>
                        {item.title.replace(/<[^>]+>/g, '')}
                      </span>
                      <span>
                        {item.uuid ? (
                          <div
                            style={{
                              marginLeft: '20px',
                              listStyleType: 'circle',
                            }}
                          >
                            {item.uuid.map((filename, filenameIndex) => (
                              <div key={filenameIndex}>
                                {' '}
                                - plik ({filename[1] ?? ''}) -
                                <Button
                                  startIcon={<CloudUploadIcon />}
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    getFileDownload(
                                      `${getApiUrl()}/api/formset/download/${
                                        Array.isArray(filename)
                                          ? filename[0]
                                          : filename
                                      }`
                                    )
                                  }}
                                >
                                  pobierz
                                </Button>
                              </div>
                            ))}
                          </div>
                        ) : Array.isArray(item.value) ? (
                          <div
                            style={{
                              marginLeft: '20px',
                              listStyleType: 'circle',
                            }}
                          >
                            {item.value.map((option, optionIndex) => (
                              <div key={optionIndex}>
                                -{' '}
                                {option?.title ? (
                                  option?.type === 'consent' ? (
                                    <span
                                      dangerouslySetInnerHTML={renderHTML(
                                        option.title
                                      )}
                                    />
                                  ) : (
                                    option.title
                                  )
                                ) : (
                                  '--'
                                )}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div style={{ marginLeft: 20 }}>
                            {' '}
                            {item.value ?? '--'}{' '}
                          </div>
                        )}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      </Wrapper>
    </Grid>
  )
}
