import { authHeader } from '_helpers/authHeader'
import { notification } from '_helpers/notification'
import { getApiUrl } from '_services/apiUrlProvider'
import { translate } from '_helpers/translate'
import { constants } from 'components/table/_state'

const performexportselected = (action, title, disabled) => ({
  action,
  title: translate(title),
  disabled: disabled,
  execute: (tableState, tableStateDispatch, updateSubscriptions = false) => {
    let itemsUuidToSend = []

    tableStateDispatch({
      type: constants.MASS_SELECT_ACTION_START,
      payload: {
        action: action,
      },
    })

    const items = tableState.data.selected.map((isSelected, index) => {
      const visSelected =
        typeof isSelected === 'object' ? isSelected.value : isSelected

      if (!visSelected) {
        return tableState.data.items[index]
      }

      itemsUuidToSend.push(tableState.data.items[index].uuid)

      return tableState.data.items[index]
    })

    let url = `${getApiUrl()}/api/shoporder/status/change`

    setTimeout(() => {
      fetch(url, {
        method: 'POST',
        headers: {
          ...authHeader(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          items: itemsUuidToSend,
          action: action,
          updateSubscriptions: updateSubscriptions,
        }),
      })
        .then(response => {
          if (!response.ok) {
            // Check for non-successful status
            return response.json().then(json => {
              throw { status: response.status, message: 'Error', json } // Include JSON in the error
            })
          }
          return response.json()
        })
        .then(json => {
          tableStateDispatch({
            type: constants.RELOAD,
            payload: { action, items },
          })
          notification('success', json.message, 'Zmieniono status')
        })
        .catch(e => {
          if (e.json) {
            notification('error', e.json.error || e.json.message)
          } else {
            notification('error', 'Error, nie można zmienić', e.message)
          }
          tableStateDispatch({
            type: constants.MASS_SELECT_ACTION_FAILURE,
            payload: { action },
          })
        })
    }, 500)
  },
})

export const statusselected = {
  status: (action = 'new', title = 'Nowa', disabled = false) =>
    performexportselected(action, title, disabled),
}
