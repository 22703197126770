import React, { useEffect, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { prop } from '../../../_helpers/prop'
import { constants } from '../../../components/table/_state'
import { notification } from '../../../_helpers/notification'
import { authHeader } from '../../../_helpers/authHeader'
import { getApiUrl } from '../../../_services/apiUrlProvider'
import { TodayOutlined } from '@material-ui/icons'
import moment from 'moment'
import { IconButton, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

const FORMAT = 'YYYY-MM-DD HH:mm:ss'

const ChangeReservationDateButton = ({
  resource,
  accessor,
  reservationId,
  tableState,
  tableStateDispatch,
}) => {
  const [initialized, setInitialized] = useState(false)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingChoices, setLoadingChoices] = useState(false)
  const [choices, setChoices] = useState(null)
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'success',
  })
  const [selectedDate, setSelectedDate] = useState(null)

  const eventIri = resource?.parent?.parent?.['@id']

  const formatDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSuccess = value => {
    tableStateDispatch({
      type: constants.UPDATE_ITEM_FIELD,
      payload: {
        '@id': resource['@id'],
        accessor: 'parent',
        value,
      },
    })
  }

  const value = prop(resource, accessor)
  const warsawTime = value
    ? moment
        .utc(value)
        .tz('Europe/Warsaw')
        .format(FORMAT)
    : null

  const handleParentChange = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${getApiUrl()}${resource?.['@id']}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(),
        },
        body: JSON.stringify({ parent: selectedDate }),
      })

      if (!response.ok) {
        throw new Error('Failed to cancel reservation')
      }

      const json = await response.json()
      onSuccess(json.parent)

      notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')
    } catch (error) {
      notification('error', 'T_FORM_ERROR', 'T_FORM_ERROR')
    } finally {
      setLoading(false)
      setOpen(false)
    }
  }

  const fetchChoices = async () => {
    setLoadingChoices(true)
    try {
      const response = await fetch(
        `${getApiUrl()}/api/reservation_event_dates?iri[parent]=${eventIri}&pagination=false&order[startDate]=asc&startDate[after]=${formatDate(
          new Date()
        )}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/ld+json',
            ...authHeader(),
          },
        }
      )

      if (!response.ok) {
        throw new Error('Failed to cancel reservation')
      }

      const json = await response.json()
      setChoices(json['hydra:member'] ?? [])
    } catch (error) {
      console.error(error)
      notification('error', 'T_FORM_ERROR', 'T_FORM_ERROR')
    } finally {
      setLoadingChoices(false)
    }
    setInitialized(true)
  }

  const handleAlertClose = () => {
    setAlert({ ...alert, open: false })
  }

  const fetchChoicesRef = useRef(fetchChoices)

  useEffect(() => {
    if (open && choices === null && eventIri && !initialized) {
      fetchChoicesRef.current()
    }
  }, [resource, choices, eventIri, open, initialized])

  const handleChange = (e, option) => {
    setSelectedDate(option?.['@id'])
  }

  return (
    <React.Fragment>
      <div style={{ display: 'flex' }}>
        {warsawTime && <span>{warsawTime}</span>}
        <IconButton onClick={() => handleClickOpen()}>
          <TodayOutlined></TodayOutlined>
        </IconButton>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Zmiana daty rezerwacji
        </DialogTitle>
        <DialogContent id="alert-dialog-description">
          <div>
            Liczba uczestników: <b>{resource.numberOfParticipants}</b>
          </div>
          <div>
            Obecna data:
            <b>
              {resource?.parent?.startDate &&
                moment
                  .utc(resource?.parent?.startDate)
                  .tz('Europe/Warsaw')
                  .format(FORMAT)}
            </b>
          </div>
          <Autocomplete
            id="combo-box-demo"
            options={choices ?? []}
            getOptionLabel={option =>
              moment
                .utc(option?.startDate)
                .tz('Europe/Warsaw')
                .format(FORMAT)
            }
            style={{ width: 350, marginTop: 15 }}
            renderInput={params => (
              <TextField
                {...params}
                label="Data rezerwacji"
                variant="outlined"
              />
            )}
            value={
              choices?.find(option => option?.['@id'] === selectedDate) || null
            }
            onChange={handleChange}
            renderOption={option => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {/* Main date information with custom styling */}
                <div style={{ fontWeight: 'bold', color: '#1976d2' }}>
                  {moment
                    .utc(option.startDate)
                    .tz('Europe/Warsaw')
                    .format(FORMAT)}
                </div>

                {/* You can add additional fields from your option object */}
                {option.calendarLeftParticipants <
                  resource.numberOfParticipants && (
                  <div
                    style={{
                      color: '#ffffff',
                      fontSize: '0.85rem',
                      backgroundColor: '#b44646',
                      padding: '0 8px',
                      borderRadius: '4px',
                    }}
                  >
                    Zbyt mało miejsc: {option.calendarLeftParticipants}
                  </div>
                )}
                {option.calendarLeftParticipants >
                  resource.numberOfParticipants && (
                  <div
                    style={{
                      color: '#ffffff',
                      fontSize: '0.85rem',
                      backgroundColor: '#81a15a',
                      padding: '0 8px',
                      borderRadius: '4px',
                    }}
                  >
                    Pozostało miejsc: {option.calendarLeftParticipants}
                  </div>
                )}

                {/* You can conditionally style based on some property */}
                {option.status && (
                  <div
                    style={{
                      color:
                        option.status === 'active'
                          ? '#4caf50'
                          : option.status === 'pending'
                          ? '#ff9800'
                          : '#f44336',
                      fontWeight: 'bold',
                      marginLeft: '8px',
                    }}
                  >
                    {option.status}
                  </div>
                )}
              </div>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Anuluj
          </Button>
          <Button
            onClick={handleParentChange}
            color="secondary"
            autoFocus
            disabled={loading}
          >
            {loading ? 'Zapisywanie zmian...' : 'Zmień'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  )
}

export default ChangeReservationDateButton
