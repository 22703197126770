import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { authHeader } from '../../../_helpers/authHeader'
import { getApiUrl } from '../../../_services/apiUrlProvider'
import { notification } from '../../../_helpers/notification'

const DateGenerationDialog = ({
  buttonText = 'Perform Action',
  dialogTitle = 'Confirm Action',
  dialogContent = 'Are you sure you want to perform this action?',
  requestData = {},
  apiEndpoint = '',
  alert = null,
  buttonStyle = {},
  setCollection = null,
}) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = async () => {
    setLoading(true)

    try {
      const response = await fetch(`${getApiUrl()}${apiEndpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(),
        },
        body: JSON.stringify(requestData),
      })

      const data = await response.json()

      if (data?.error) {
        notification('error', data?.error, 'Wystąpił błąd')
      }

      if (data?.message) {
        notification('success', data?.message, 'Sukces')
      }

      if (data?.success) {
        notification('success', data?.success, 'Sukces')
      }

      if (!response.ok) {
        throw new Error(data.message || 'Something went wrong')
      }

      if (setCollection) {
        setCollection(null)
      }
    } catch (error) {
      console.error(error)
      notification('error', 'Wystąpił błąd', 'Wystąpił błąd')
    } finally {
      setLoading(false)
      setOpen(false)
    }
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        disabled={loading}
        style={buttonStyle}
      >
        {loading ? 'Przetwarzanie...' : buttonText}
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          {alert}
          <DialogContentText id="alert-dialog-description">
            {dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            Zamknij
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            autoFocus
            disabled={loading}
          >
            {loading ? 'Przetwarzanie...' : 'Potwierdź'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DateGenerationDialog
