import _blockBip from './_blockBip'
import mediaSchema from './media'
import menuCategorySchema from './bipMenuCategory'
import { LANGS_IRI } from '../_lib/langs'

const schema = {
  endpoint: '/api/bip_pages',
  clone_endpoint: '/api/bip_pages/clone',
  version_endpoint: '/api/bip_pages/version',
  is_modified_endpoint: '/api/bip_pages/is_modified',
  access: 'bipPage',
  resource: {
    definition: '#BipPage',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {},
          description: {
            description: 'Opis',
            label: 'Description',
            type: 'tinymce',
          },
          metaTitle: {},
          metaDescription: {
            type: 'textarea',
          },
          metaKeywords: {
            type: 'textarea',
          },
          slug: {},
          previewUuid: {
            type: 'articlePreviewUuid',
            label: 'T_GENERAL_PREVIEW_UUID',
            routes: {
              [LANGS_IRI.PL]: '/bip/podglad-strony/:uuid',
              [LANGS_IRI.EN]: '/en/bip/page-preview/:uuid',
            },
          },
        },
      },
      category: {
        type: 'resource',
        endpoint: `${menuCategorySchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_BIP_PAGE_MENU_CATEGORY',
      },
      homepage: {
        type: 'boolean',
        description: 'Strona główna',
      },
      creatorName: {
        description: 'Informację wytworzył',
      },
      personResponsible: {
        description: 'Informację opublikował',
      },
      archivedAt: {
        type: 'datetime',
        description: 'Data przeniesienia do archiwum',
      },
      createdDate: {
        type: 'date',
        description: 'Data wytworzenia informacji',
      },
      showArticlesInArchive: {
        type: 'boolean',
        description: 'Pokaż artykuły w globalnym archiwum',
        hint: 'Wyświetla archiwalne artykuły w globalnym archiwum artykułów',
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/bip_page_blocks',
      definition: '#BipPageBlock',
      types: _blockBip(
        mediaSchema.endpoint,
        '/api/bip_page_block_thumbs',
        '/api/bip_page_block_files',
        '/api/bip_page_block_images'
      ).types,
    },
  },

  /*
  branches: {
      //type: 'embeddedCollection',
      type: 'collection',
      label: 'T_GENERAL_BLOCK_TYPE_MULTI_BRANCHES_BRANCHES',
      titleAccessor: `translations.${LANGS_IRI.PL}.description`,
      definitionRef: '#NewsBlockBranch',
      endpoint: blockBranchesEndpoint,
      properties: {
        translations: {
          type: 'translation',
          properties: {
            description: {},
          },
        },
        branch: {
          type: 'resource',
          endpoint: `/api/branches?pagination=false`,
          titleAccessor: `translations.${LANGS_IRI.PL}.title`,
          label: 'T_MODULE_EVENT_BRANCH_SELECT',
        },
      },
    },
   */
}

export default schema
