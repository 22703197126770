import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu, MenuItem, IconButton, Tooltip } from '@material-ui/core'
import { MoreVertOutlined, Event, EqualizerOutlined } from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/styles'
import { useSelector, useDispatch } from 'react-redux'
import { EditLink } from 'components/resource/EditLink'
import { DeleteCell } from 'components/table/cells/DeleteCell'
import { StatusCell } from 'components/table/cells/StatusCell'
import { Timestamp } from 'components/resource/Timestamp'
import { translate } from '_helpers/translate'
import { commonConstants } from '_constants'
import { LANGS_IRI } from '_lib/langs'
import routes from '../../routes'
import clsx from 'clsx'
import ViewDayOutlinedIcon from "@material-ui/icons/ViewDayOutlined";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    fontSize: 14,
    '& svg': {
      fontSize: 20,
    },
  },
  listItem: {
    minWidth: 32,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  menuTrigger: {
    borderRadius: 4,
    padding: 7,
  },
  menuTriggerOpen: {
    border: '1px solid #d3d4d5',
    borderBottomColor: '#fff',
    backgroundColor: '#fff',

    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  calendarIcon: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    color: theme.palette.secondary.main,
  },
}))

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',

    borderTopRightRadius: 0,
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

export const operations = (
  accessor = `translations.${
    LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
  }.title`,
  editable = true,
  deletable = true,
  statable = true,
  timestampable = true,
  cloneEndpoint = null
) => ({
  header: translate('T_GENERAL_OPERATIONS'),
  accessor,
  sortable: false,
  filterable: false,
  Cell: ({
    resource,
    accessor,
    tableState,
    tableStateDispatch,
    editPath = null,
  }) => {
    const classes = useStyles()
    const [menuAnchor, setMenuAnchor] = useState(null)

    const handleMenu = e => {
      setMenuAnchor(e.currentTarget)
    }

    const handleClose = () => {
      setMenuAnchor(null)
    }

    const profile = useSelector(state => state.profile)

    const dispatch = useDispatch()

    const onSuccess = () => {
      dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })
    }

    const maxDepth = process.env.REACT_APP_SHOP_CATEGORY_MAX_DEPTH

    const isSubPageable = resource.depth < maxDepth

    const showMenu = editable || !!cloneEndpoint || deletable || isSubPageable

    return (
      <div className={classes.root}>
        {showMenu && (
          <>
            <Link
              to={routes().calendar.path.replace(':id', resource?.uuid)}
              className={classes.icon}
            >
              <Tooltip title={translate('Kalendarz')}>
                <Event className={classes.calendarIcon} />
              </Tooltip>
            </Link>
            <Link
              to={routes().statistics.path.replace(':id', resource?.uuid)}
              className={classes.icon}
            >
              <Tooltip title={translate('Statystyki')}>
                <EqualizerOutlined className={classes.calendarIcon} />
              </Tooltip>
            </Link>
            <Link
              to={routes().formItemsIndex.path.replace(
                ':pid',
                resource[process.env.REACT_APP_RESOURCE_ID]
              )}
              className={classes.calendarIcon}
            >
              <Tooltip title={translate('Pola formularza')}>
                <ViewDayOutlinedIcon />
              </Tooltip>
            </Link>
            <IconButton
              aria-label="record menu"
              aria-controls="record-menu"
              aria-haspopup="true"
              onClick={handleMenu}
              className={clsx(
                classes.menuTrigger,
                menuAnchor && classes.menuTriggerOpen
              )}
            >
              <MoreVertOutlined color="inherit" />
            </IconButton>
            <StyledMenu
              id="record-menu"
              anchorEl={menuAnchor}
              keepMounted
              open={!!menuAnchor}
              onClose={handleClose}
            >
              {editable && (
                <MenuItem className={classes.menuItem}>
                  <EditLink
                    resource={resource}
                    accessor={accessor}
                    disabled={!profile?.openform && !resource.isEditable}
                    editPath={editPath}
                  />
                </MenuItem>
              )}
              {deletable && (
                <MenuItem className={classes.menuItem}>
                  <DeleteCell
                    resource={resource}
                    accessor={accessor}
                    disabled={!profile?.openform && !resource.isDeletable}
                    onSuccess={onSuccess}
                    tableState={tableState}
                    tableStateDispatch={tableStateDispatch}
                  />
                </MenuItem>
              )}
            </StyledMenu>
          </>
        )}
        {statable && (
          <StatusCell
            resource={resource}
            disabled={!profile?.openform && !resource.isStatable}
            tableStateDispatch={tableStateDispatch}
          />
        )}
        {timestampable && <Timestamp resource={resource} />}
      </div>
    )
  },
})
