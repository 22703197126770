import React from 'react'
import { Form } from 'pages'
import schema from '_schema/reservationEvent'
import routes from './routes'
import { BlockCollection } from '../../components/block'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      modifyProperties={properties => {
        delete properties.parent

        return properties
      }}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarDisableStat={() => true}
      width={600}
    >
      <BlockCollection
        endpoint={schema.subresources.block.endpoint}
        pid={match.params.id}
        parentIri={iri}
        definitionSchema={definitions[schema.subresources.block.definition]}
        types={schema.subresources.block.types}
        contextualHint={schema.subresources.block.contextualHint}
        statable={true}
        key={iri}
      />
    </Form>
  )
}
