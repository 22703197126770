import FIELD_TYPES from '../components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/reservation_event_reservations',
  access: 'reservationEvent',
  resource: {
    definition: '#ReservationEventReservation',
    properties: {
      firstName: {
        type: FIELD_TYPES.STRING,
        description: 'Imię',
      },
      lastName: {
        type: FIELD_TYPES.STRING,
        description: 'Nazwisko',
      },
      email: {
        type: FIELD_TYPES.STRING,
        description: 'Email',
      },
      phone: {
        type: FIELD_TYPES.STRING,
        description: 'Numer telefonu',
      },
      phone2: {
        type: FIELD_TYPES.STRING,
        description: 'Numer telefonu opiekuna',
      },
      numberOfParticipants: {
        type: FIELD_TYPES.INTEGER,
        description: 'Liczba uczestników',
      },
      canceled: {
        type: FIELD_TYPES.BOOLEAN,
        description: 'Anulowano',
      },
      notes: {
        type: FIELD_TYPES.TEXTAREA,
        description: 'Uwagi administratora',
      },
      dataForm: {
        type: FIELD_TYPES.RESERVATION_EVENT_FORM_DATA,
        label: 'Dodatkowe pola',
      },
      activatedAt: {
        type: FIELD_TYPES.DATETIME,
        label: 'Data aktywacji za pomocą aplikacji',
      },
    },
  },
}

export default schema
