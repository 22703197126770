import React from 'react'
import { SvgIcon } from '@material-ui/core'

function Archive(props) {
  return (
    <SvgIcon {...props} width="15" height="16" viewBox="0 0 15 16" fill="none">
      <path
        d="m7.36 12.352 3.195-3.192-1.117-1.12-1.282 1.28V5.965H6.562V9.32L5.285 8.04 4.168 9.16ZM1.77 4.367v8.785h11.18V4.367Zm0 10.38a1.522 1.522 0 0 1-1.13-.47 1.543 1.543 0 0 1-.468-1.125v-9.96c0-.188.031-.368.09-.54.062-.175.152-.336.27-.48l1-1.219c.144-.183.327-.328.546-.43A1.68 1.68 0 0 1 2.77.375h9.18c.241 0 .472.05.69.148.22.102.403.247.547.43l1 1.219c.118.148.208.305.27.48.059.172.09.352.09.54v9.956c0 .442-.156.817-.469 1.13a1.522 1.522 0 0 1-1.129.468ZM2.09 2.77h10.539l-.68-.797H2.77Zm0 0"
        fill="#aaa"
        style={{ color: 'transparent' }}
      />
    </SvgIcon>
  )
}

export default Archive
