import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { AddCircleOutline } from '@material-ui/icons'
import {Button, Chip, Dialog, DialogActions, DialogContent} from '@material-ui/core'
import { DialogTitleWithClose } from 'components/DialogTitleWithClose'
import { useSelector } from 'react-redux'
import { SchemaError } from '../../../_helpers/schemaError'
import { Form } from '../../../components/form'
import addShipmentSchema from '../../../_schema/shopInpostAddShipment'
import { constants } from '../../../components/table/_state'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles({
  content: {
    textAlign: 'left',
  },
  multipleButtons: {
    marginLeft: 10,
  },
})

export const InpostLabelModal = ({
  minWidth = '150px',
  classes = {},
  defaultData = {},
  tableState,
  tableStateDispatch,
  resource,
  disabledButton = false,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const [formState, setFormState] = useState(null)

  const currentValues = formState?.values

  const handleDialogToggle = useCallback(() => {
    setIsDialogOpen(state => !state)
  }, [])

  const [buttonRef, setButtonRef] = useState(null)

  const onButtonRef = element => {
    setButtonRef(element)
  }

  const isArray = Array.isArray(resource)
  const firstResource = isArray ? resource[0] ?? null : resource

  //PRZESYLKA_POLECONA_KRAJOWA
  //PACZKA_POCZTOWA
  //POCZTEX20
  //POCZTEX_PICKUP_POINT
  //PACZKA_ZAGRANICZNA

  const cashOnDeliveryMethods = ['INPOST_KURIER', 'INPOST_PACZKOMATY']
  const foreignMethods = []
  const pickupPoint = ['INPOST_PACZKOMATY']
  const courier = ['INPOST_KURIER']
  //const normal = ['INPOST_KURIER', 'INPOST_PACZKOMATY']

  let availableMethods = []
  if (firstResource?.cashOnDelivery) {
    availableMethods = [...availableMethods, ...cashOnDeliveryMethods]
  } else if (
    firstResource?.countryTitle &&
    firstResource?.countryTitle !== 'Polska'
  ) {
    availableMethods = [...availableMethods, ...foreignMethods]
  } else if (firstResource?.shippingOperator?.name === 'INPOST_PACZKOMATY') {
    availableMethods = [...availableMethods, ...pickupPoint]
  } else if (firstResource?.shippingOperator?.name === 'INPOST_KURIER') {
    availableMethods = [...availableMethods, ...courier]
  }

  if (!firstResource?.countryTitle) {
    availableMethods = []
  }

  let typeEndpoint = addShipmentSchema.resource.properties.type.endpoint

  for (let i = 0; i < availableMethods.length; i++) {
    const endpointFilter = '&name[]=' + availableMethods[i]

    if (!typeEndpoint.includes(endpointFilter)) {
      typeEndpoint = typeEndpoint + endpointFilter
    }
  }

  addShipmentSchema.resource.properties.type.allowedNames = availableMethods

  const getProperties = () => {
    if (typeof currentValues?.type === 'string') {
      const enumName = currentValues.type.split('/').pop()
      if (addShipmentSchema.hasOwnProperty(enumName)) {
        return {
          ...addShipmentSchema.resource.properties,
          ...addShipmentSchema[enumName],
        }
      } else {
        return addShipmentSchema.resource.properties
      }
    } else {
      return addShipmentSchema.resource.properties
    }
  }

  const defaultClasses = useStyles()

  const schemaDefinitions = useSelector(state => state.schema)

  if (!schemaDefinitions) {
    throw new SchemaError()
  }

  const handleSuccess = resource => {
    tableStateDispatch({ type: constants.RELOAD })
    handleDialogToggle()
  }

  const formDisabled = isArray && resource.length === 0
  const title = isArray ? 'Generuj etykiety (Inpost)' : 'Generuj etykietę'

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={<AddCircleOutline />}
        onClick={handleDialogToggle}
        style={{ minWidth: minWidth }}
        className={isArray ? defaultClasses.multipleButtons : ''}
        disabled={disabledButton}
      >
        {title}
      </Button>
      <Dialog maxWidth="md" onClose={handleDialogToggle} open={isDialogOpen}>
        <DialogTitleWithClose onClose={handleDialogToggle}>
          {title} - Inpost
        </DialogTitleWithClose>
        <DialogContent
          className={clsx(defaultClasses.content, classes.content)}
        >
          {!firstResource?.countryTitle && (
            <Alert severity="error">
              Zamówienie nie ma ustawionego kraju dostawy!
            </Alert>
          )}
          {firstResource?.cashOnDelivery && (
            <div>
              <b>Płatność za pobraniem</b>
            </div>
          )}
          {firstResource?.shippingOperator?.name === 'INPOST_PACZKOMATY' &&
            !isArray && (
              <div>
                <div>
                  <b>Odbiór w punkcie (inpost)</b>
                </div>
                <div>
                  Punkt odbioru: {firstResource?.pickupPointName} (
                  {firstResource?.pickupPoint ? (
                    firstResource.pickupPoint
                  ) : (
                    <Chip
                      label="Nie wybrano punktu odbioru"
                      color="secondary"
                      style={{ backgroundColor: 'red' }}
                    />
                  )}
                  )
                </div>
              </div>
            )}
          <div>Kraj docelowy: {firstResource?.countryTitle}</div>
          <Form
            properties={getProperties()}
            method="POST"
            url={addShipmentSchema.endpoint}
            showSubmitAndStayButton={false}
            showCancelButton={false}
            buttonContainerRef={buttonRef}
            handleSuccess={handleSuccess}
            width={600}
            previousState={formState}
            setCurrentState={setFormState}
            defaultData={defaultData}
            disabled={formDisabled}
          />
          {formDisabled && (
            <Alert severity="error">Nie można wygenerować etykiety</Alert>
          )}
        </DialogContent>
        <DialogActions ref={onButtonRef}></DialogActions>
      </Dialog>
    </>
  )
}

InpostLabelModal.propTypes = {
  minWidth: PropTypes.string,
  classes: PropTypes.shape({
    content: PropTypes.string,
  }),
  defaultData: PropTypes.object,
  tableState: PropTypes.shape({
    data: PropTypes.shape({
      items: PropTypes.array.isRequired,
    }).isRequired,
    config: PropTypes.shape({
      page: PropTypes.number,
    }).isRequired,
  }).isRequired,
  tableStateDispatch: PropTypes.func.isRequired,
  resource: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  disabledButton: PropTypes.bool,
}
