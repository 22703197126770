import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { translate } from '_helpers/translate'
import reservationEventSchema from '_schema/reservationEvent'
import statisticsSchema from '_schema/reservationEventStatistics'
import routes from './routes'
import { useResourceState } from '../../_helpers/useResourceState'
import { useResourceFetch } from '../../_helpers/useResourceFetch'
import { Loader } from '../../components/Loader'
import { NotFound } from '../NotFound'
import { operations } from 'components/table/_columns/operations'
import { DateCell } from '../../components/table/cells/DateCell'
import { ExportStatisticsModal } from './components/ExportStatisticsModal'

export const CollectionStatistics = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const parentIri = useMemo(
    () => `${reservationEventSchema.endpoint}/${match.params.id}`,
    [match.params.id]
  )

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    parentIri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false
  )

  const columns = useMemo(
    () => [
      {
        columnId: 'parent.nativeTitle',
        header: translate('Obiekt'),
        accessor: `parent.nativeTitle`,
        filterable: true,
        filterName: 'parent.nativeTitle',
        width: '20%',
      },
      {
        columnId: 'date',
        header: translate('Data'),
        accessor: `date`,
        filterable: true,
        sortable: true,
        filterName: 'date',
        width: '20%',
        Cell: DateCell,
      },
      {
        columnId: 'entrances',
        header: translate('Liczba wejść'),
        accessor: `entrances`,
        filterable: false,
        sortable: true,
        width: '20%',
      },
      {
        columnId: 'entrancesCanceled',
        header: translate('Anulowanych'),
        accessor: `entrancesCanceled`,
        filterable: false,
        sortable: true,
        width: '20%',
      },
      {
        columnId: 'operations',
        ...operations(`date`, true, true, false, true, false),
        width: '5%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj dane')}
        buttonPath={`${routes().statisticsNew.path}?pid=${resource.uuid}`}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={statisticsSchema.endpoint}
        additionalButtons={
          <ExportStatisticsModal
            defaultData={{ objects: [parentIri] }}
            hideSelect={true}
          ></ExportStatisticsModal>
        }
      >
        {`Statystyki: ${resource?.nativeTitle}`}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${statisticsSchema.endpoint}.get`}
        endpoint={statisticsSchema.endpoint}
        definition={statisticsSchema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={statisticsSchema.resource}
        extraQueryParameters={{
          'iri[parent]': parentIri,
        }}
        parameters={[
          {
            name: 'parent.nativeTitle',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'date',
            in: 'query',
            required: false,
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[parent.nativeTitle]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[date]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[entrances]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[entrancesCanceled]',
            in: 'query',
            type: 'string',
          },
        ]}
        orderable={false}
        numerable={false}
        defaultSorters={{ date: 'desc' }}
        isRowLinkable={true}
        editPath={routes().statisticsEdit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
