import { LANGS_IRI } from '../_lib/langs'
import FIELD_TYPES from '../components/form/_helpers/fieldTypes'
import photoSchema from './photo'

const schema = {
  endpoint: '/api/guides',
  access: 'guide',
  resource: {
    definition: '#Guide',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          shortDescription: {
            type: FIELD_TYPES.TEXTAREA,
            description: 'Krótki opis',
          },
          description: {
            type: FIELD_TYPES.TINYMCE,
            description: 'Opis',
          },
        },
      },
      name: {
        description: 'T_GENERAL_GUIDE_NAME',
        initialValue: '',
        validate: ['required'],
      },
      surname: {
        initialValue: '',
        validate: ['required'],
      },
      email: {
        initialValue: '',
      },
      phone: {},
      addressStreet: {},
      addressPostal: {},
      addressCity: {},
      isGuide: {},
      isInstructor: {},
      isTPNEmployee: {},
      sexType: {
        type: FIELD_TYPES.RESOURCE,
        endpoint: '/api/person_sex_types?pagination=false',
        titleAccessor: `trans`,
        transTitle: true,
        description: 'Płeć',
        validate: ['required'],
      },
      languages: {
        type: FIELD_TYPES.MULTIPLE_RESOURCE,
        endpoint: '/api/guide_languages?pagination=false',
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        //transTitle: true,
        description: 'Języki',
      },
      filters: {
        type: FIELD_TYPES.MULTIPLE_RESOURCE,
        endpoint: '/api/guide_filters?pagination=false',
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        //transTitle: true,
        description: 'Filtry',
      },
      media: {
        type: FIELD_TYPES.IMAGE,
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/guide_thumbs',
        },
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              alt: media.translations[LANGS_IRI.PL].alt,
            },
            /* todo en version
            [LANGS_IRI.EN]: {
              alt: media.translations[LANGS_IRI.EN].alt,
            }
             */
          })
        },
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
