import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { translate } from '_helpers/translate'
import schema from '_schema/reservationEventReservation'
import routes from './routesReservation'
import { LANGS_IRI } from '../../_lib/langs'
import { DatetimeCell } from '../../components/table/cells/DatetimeCell'
import { StringFilter } from '../../components/table/filters/StringFilter'
import CancelReservationButton from './components/CancelReservationButton'
import { ChoiceFilter } from '../../components/table/filters/ChoiceFilter'
import ChangeReservationDateButton from './components/ChangeReservationDateButton'
import { reservationOperations } from './table/_columns/reservationOperations'

export const CollectionReservation = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'reservationNumber',
        header: translate('Numer rezerwacji'),
        accessor: `reservationNumber`,
        filterable: true,
        filterName: 'reservationNumber',
        width: '4%',
      },
      {
        columnId: 'parent.parent.nativeTitle',
        header: translate('Nazwa obiektu'),
        accessor: `parent.parent.nativeTitle`,
        filterable: true,
        sortable: true,
        filterName: 'parent.parent.nativeTitle',
        width: '15%',
      },
      {
        columnId: 'fullName',
        filtersList: ['firstName', 'lastName'],
        header: translate('Imię i nazwisko'),
        accessor: `fullName`,
        filterable: true,
        sortable: true,
        width: '12%',
      },
      {
        columnId: 'email',
        header: translate('Email'),
        accessor: `fullName`,
        filterable: true,
        filterName: 'email',
        width: '12%',
      },
      {
        columnId: 'phone',
        header: translate('Telefon'),
        accessor: `phone`,
        filterable: true,
        filterName: 'phone',
        width: '6%',
      },
      {
        columnId: 'phone2',
        header: translate('Telefon opiekuna'),
        accessor: `phone2`,
        filterable: true,
        filterName: 'phone2',
        width: '8%',
      },
      {
        columnId: 'canceled',
        header: translate('Status'),
        accessor: `canceled`,
        filterable: true,
        filterName: 'canceled',
        width: '10%',
        Filter: ChoiceFilter,
        choices: {
          true: 'Anulowane',
          false: 'Aktywne',
        },
        Cell: CancelReservationButton,
      },
      {
        columnId: 'parent.startDate',
        header: translate('Data rezerwacji'),
        accessor: `parent.startDate`,
        filterable: true,
        sortable: true,
        filterName: 'parent.startDate',
        width: '8%',
        Cell: ChangeReservationDateButton,
      },
      {
        columnId: 'createdAt',
        header: translate('Data utworzenia'),
        accessor: `createdAt`,
        filterable: true,
        sortable: true,
        filterName: 'createdAt',
        width: '8%',
        Cell: DatetimeCell,
      },
      {
        columnId: 'operations',
        ...reservationOperations(
          `translations.${LANGS_IRI.PL}.title`,
          true,
          true,
          false,
          true,
          false
        ),
        width: '5%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('Dodaj rezerwację')}
        buttonPath={''}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
        hideButton={true}
      >
        {translate('Rezerwacje')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        parameters={[
          {
            name: 'parent.parent.nativeTitle',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'createdAt',
            in: 'query',
            required: false,
            type: 'string',
          },
          {
            name: 'firstName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'lastName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'reservationNumber',
            in: 'query',
            type: 'string',
          },
          {
            name: 'email',
            in: 'query',
            type: 'string',
          },
          {
            name: 'phone',
            in: 'query',
            type: 'string',
          },
          {
            name: 'phone2',
            in: 'query',
            type: 'string',
          },
          {
            name: 'canceled',
            in: 'query',
            type: 'string',
          },
          {
            name: 'parent.startDate',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[parent.parent.nativeTitle]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[createdAt]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[parent.startDate]',
            in: 'query',
            type: 'string',
          },
        ]}
        customFilters={[
          {
            name: 'firstName',
            title: 'Imię',
            Filter: StringFilter,
          },
          {
            name: 'lastName',
            title: 'Nazwisko',
            Filter: StringFilter,
          },
        ]}
        orderable={false}
        numerable={false}
        defaultSorters={{ createdAt: 'desc' }}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
