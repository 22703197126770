import React from 'react'
import schema from '_schema/chart'
import routes from './routes'
import ChartDataGenerator from './component/ChartDataGenerator'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  /*
  <Form
        definitionSchema={definitions[schema.resource.definition]}
        definitions={definitions}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        collectionPath={routes().index.path}
        modifyProperties={properties => {
          delete properties.parent

          return properties
        }}
        storeCollectionId={schema.endpoint}
        sidebar={true}
        sidebarDisableStat={() => true}
        width={600}
      ></Form>
   */
  return (
    <div>
      <ChartDataGenerator
        iri={iri}
        schema={schema}
        collectionPath={routes().index.path}
      ></ChartDataGenerator>
    </div>
  )
}
