import { LANGS_IRI } from '../_lib/langs'
import shopVariantSchema from './shopVariant'
import shopShippingTypeSchema from './shopShippingType'
import photoSchema from './photo'
import mediaSchema from './media'
import FIELD_TYPES from '../components/form/_helpers/fieldTypes'
import moment from 'moment/moment'
import fileSchema from './media'

const reviewGradeChoices = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
}

const schema = {
  endpoint: '/api/shop_products',
  endpointSelect: '/api/shop_products/select',
  endpointCollection: '/api/shop_products/collection',
  clone_endpoint: '/api/shop_product/clone',
  access: 'shopProduct',
  resource: {
    definition: '#ShopProduct',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          model: {
            description: 'Model',
            hint: 'Używany tylko w CMS',
          },
          description: {
            description: 'T_GENERAL_DESCRIPTION_NORMAL',
            type: FIELD_TYPES.TINYMCE,
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {
            type: FIELD_TYPES.SLUG,
            routes: {
              [LANGS_IRI.PL]: '/sklep/:slug',
              // [LANGS_IRI.EN]: '/en/shop/:slug',
            },
          },
        },
      },
      createdAt: {
        type: FIELD_TYPES.DATETIME,
        description: 'Data utworzenia',
        hint: 'Data po której produkt jest sortowany',
        validate: ['required'],
        defaultValue: moment().format(),
      },
      floatPrice: {
        type: FIELD_TYPES.FLOAT,
        description: 'T_GENERAL_PRICE',
        errorAlertWhenNullOrEmpty: 'Cena nie uzupełniona',
        validate: ['money'],
        asFloat: true,
      },
      vat: {
        type: FIELD_TYPES.RESOURCE,
        endpoint: `/api/shop_config_vats?pagination=false`,
        titleAccessor: `title`,
        label: 'Stawka vat',
        validate: ['required'],
      },
      mainVariant: {
        type: FIELD_TYPES.RESOURCE,
        endpoint: `${shopVariantSchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_SHOP_PRODUCT_MAIN_VARIANT',
        validate: ['required'],
      },
      secondaryVariant: {
        type: FIELD_TYPES.RESOURCE,
        endpoint: `${shopVariantSchema.endpoint}?pagination=false&exists[defaultVariant]=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_SHOP_PRODUCT_SECONDARY_VARIANT',
      },
      category: {
        type: FIELD_TYPES.RESOURCE,
        endpoint: `/api/shop_categories_product?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.titleWithParent`,
        label: 'Główna kategoria',
      },
      categories: {
        type: FIELD_TYPES.MULTIPLE_RESOURCE,
        endpoint: `/api/shop_categories_product?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'Kategorie',
      },
      /*
      suggestedCategories: {
        type: FIELD_TYPES.MULTIPLE_RESOURCE,
        endpoint: `${shopCategorySchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'T_MODULE_TRAIL_SUGGESTED_CATEGORY',
      },
       */
      media: {
        type: FIELD_TYPES.IMAGE,
        description: 'T_MODULE_SHOP_PRODUCT_MAIN_MEDIA',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/shop_product_thumbs',
        },
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              alt: media.translations[LANGS_IRI.PL].alt,
            },
            /* todo en version
            [LANGS_IRI.EN]: {
              alt: media.translations[LANGS_IRI.EN].alt,
            }
             */
          })
        },
      },
      newUntil: {
        type: FIELD_TYPES.DATETIME,
        description: 'T_MODULE_SHOP_PRODUCT_NEW_UNTIL',
      },
      new: {
        description: 'T_MODULE_SHOP_PRODUCT_IS_NEW',
      },
      bestseller: {
        description: 'T_MODULE_SHOP_PRODUCT_BESTSELLER',
      },
      suggested: {
        description: 'T_MODULE_SHOP_PRODUCT_SUGGESTED',
      },
      subscription: {
        description: 'T_MODULE_SHOP_PRODUCT_SUBSCRIPTION',
      },
      subscriptionFrom: {
        type: FIELD_TYPES.NUMBER,
        description: 'T_MODULE_SHOP_PRODUCT_SUBSCRIPTION_FROM',
      },
      sender: {
        type: FIELD_TYPES.RESOURCE,
        endpoint: '/api/shop_subscription_senders',
        titleAccessor: 'trans',
        transTitle: true,
        description: 'T_MODULE_SHOP_SUBSCRIPTION_SENDER',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
      freeShipping: {
        description: 'Darmowa wysyłka',
      },
      limitedShippingTypes: {
        type: FIELD_TYPES.MULTIPLE_RESOURCE,
        endpoint: `${shopShippingTypeSchema.endpoint}?pagination=false`,
        titleAccessor: `translations.${LANGS_IRI.PL}.title`,
        label: 'Dostępne metody wysyłki',
        hint: 'Po wybraniu, tylko te metody będą dostępne dla tego produktu!',
      },
      frontStockLevelMedium: {
        description: 'Stan - "na wyczerpaniu"',
      },
      frontStockLevelLow: {
        description: 'Stan - "ostatnie sztuki"',
      },
    },
  },
  subresources: {
    options: {
      endpoint: '/api/shop_product_variants',
      definition: '#ShopProductVariant',
      properties: {
        amount: {
          description: 'T_GENERAL_AMOUNT',
        },
        mainVariantOption: {
          type: FIELD_TYPES.RESOURCE,
          definitionRef: '#ShopVariantOption',
          endpoint: `${shopVariantSchema.subresources.options.endpoint}?pagination=false&order[ord]=asc`,
          endpoint_base: `${shopVariantSchema.subresources.options.endpoint}?pagination=false&order[ord]=asc&iri[parent]=:id`,
          titleAccessor: `translations.${LANGS_IRI.PL}.titleWithParent`,
          label: 'T_MODULE_SHOP_PRODUCT_MAIN_VARIANT',
          validate: ['required'],
        },
        secondaryVariantOption: {
          type: FIELD_TYPES.RESOURCE,
          definitionRef: '#ShopVariantOption',
          endpoint: `${shopVariantSchema.subresources.options.endpoint}?pagination=false&order[ord]=asc`,
          endpoint_base: `${shopVariantSchema.subresources.options.endpoint}?pagination=false&order[ord]=asc&iri[parent]=:id`,
          titleAccessor: `translations.${LANGS_IRI.PL}.titleWithParent`,
          label: 'T_MODULE_SHOP_PRODUCT_VARIANT_SECONDARY_VARIANT',
        },
        floatPrice: {
          type: FIELD_TYPES.FLOAT,
          description: 'T_GENERAL_PRICE',
          validate: ['money'],
          asFloat: true,
        },
        isDigital: {
          description: 'T_MODULE_SHOP_PRODUCT_VARIANT_IS_DIGITAL',
        },
        subscriptionCount: {
          type: FIELD_TYPES.NUMBER,
          description: '[PRENUMERATA] Liczba egzemplarzy',
          fullWidth: true,
        },
        frontStockLevelMedium: {
          description: 'Stan - "na wyczerpaniu"',
        },
        frontStockLevelLow: {
          description: 'Stan - "ostatnie sztuki"',
        },
      },
    },
    information: {
      endpoint: '/api/shop_product_informations',
      definition: '#ShopProductInformation',
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {},
            description: {
              description: 'T_GENERAL_DESCRIPTION_NORMAL',
              label: 'T_GENERAL_DESCRIPTION_NORMAL',
              type: FIELD_TYPES.STRING,
            },
          },
        },
      },
    },
    images: {
      endpoint: '/api/shop_product_images',
      definition: '#ShopProductImage',
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {},
            videoDescription: {
              type: 'string',
              label: 'Opis filmu',
            },
            alt: {
              description: 'T_GENERAL_ALT',
            },
          },
        },
        variants: {
          type: FIELD_TYPES.MULTIPLE_RESOURCE,
          endpoint: `/api/shop_product_variants?pagination=false`,
          titleAccessor: `fullTitle`,
          label: 'Warianty',
          filterByParent: true,
          hint: 'Na stronie wariantu zdjęcie wyświetli się jako pierwsze',
        },
        media: {
          type: FIELD_TYPES.IMAGE,
          description: 'T_MODULE_SHOP_PRODUCT_MEDIA',
          endpoint: photoSchema.endpoint.single,
          validate: ['maxSize'],
          thumbs: {
            endpoint: '/api/shop_product_image_thumbs',
          },
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                title: media.translations[LANGS_IRI.PL].title,
                alt: media.translations[LANGS_IRI.PL].alt,
              },
              /* todo en version
              [LANGS_IRI.EN]: {
                alt: media.translations[LANGS_IRI.EN].alt,
              }
               */
            })
          },
        },
        file: {
          type: 'file',
          description: 'T_GENERAL_FILE_VIDEO',
          endpoint: fileSchema.endpoint,
          validate: ['maxSize(20971520)'],
        },
        ytCode: {
          type: 'string',
          description: 'T_GENERAL_YOUTUBE_CODE',
          hint: 'T_GENERAL_YOUTUBE_CODE_INFO',
        },
        hasSound: {
          type: 'boolean',
          description: 'Zawiera dźwięk',
        },
        transcription: {
          type: 'file',
          description: 'Plik video z transkrypcją',
          endpoint: fileSchema.endpoint,
          validate: ['maxSize(20971520)'],
        },
        audioDescription: {
          type: 'file',
          description: 'Plik audio z audiodeskrypcją',
          endpoint: fileSchema.endpoint,
          validate: ['maxSize(20971520)'],
        },
      },
    },
    files: {
      endpoint: '/api/shop_product_files',
      definition: '#ShopProductFile',
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {},
          },
        },
        media: {
          type: FIELD_TYPES.FILE,
          description: 'T_GENERAL_FILE',
          endpoint: mediaSchema.protectedEndpoint,
          validate: ['maxSize'],
        },
      },
    },
    reviews: {
      endpoint: '/api/shop_product_reviews',
      definition: '#ShopProductReview',
      properties: {
        name: {
          fullwidth: true,
          validate: ['required'],
        },
        text: {
          type: FIELD_TYPES.TEXTAREA,
          fullwidth: true,
          validate: ['required'],
        },
        gradeHelper: {
          type: FIELD_TYPES.CHOICE,
          choices: reviewGradeChoices,
          description: 'T_GENERAL_GRADE',
          validate: ['required'],
        },
        media: {
          type: FIELD_TYPES.IMAGE,
          description: 'T_GENERAL_PHOTO',
          endpoint: photoSchema.endpoint.single,
          validate: ['maxSize'],
          thumbs: {
            endpoint: '/api/shop_product_review_thumbs',
          },
        },
      },
    },
  },
}

export default schema
