import fileSchema from './media'

const seasonChoices = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
}

const schema = {
  endpoint: '/api/matter_form_requests',
  endpointPdf: '/api/matter_form_request/pdf',
  resource: {
    definition: '#MatterFormRequest',
    properties: {
      contactName: {
        description: 'Imię i nazwisko / Nazwa',
        validate: ['required'],
      },
      contactEmail: {
        description: 'Adres e-mail',
      },
      contactPhone: {
        description: 'Numer telefonu',
      },
      dateFrom: {
        description: 'Data od',
        type: 'date',
        validate: ['required'],
      },
      dateTo: {
        description: 'Data do',
        type: 'date',
        validate: ['required'],
      },
    },
  },

  newFormProperties: {
    properties: {
      type: {
        label: 'Typ',
        type: 'resource',
        endpoint: '/api/matter_form_config_group_types',
        titleAccessor: 'trans',
        transTitle: true,
      },
    },
  },

  carProperties: {
    // Wjazd na teren TPN
    properties: {
      name: {
        type: 'string',
        description: 'Imię i nazwisko',
        validate: ['required'],
      },
      address: {
        type: 'string',
        description: 'Adres',
        validate: ['required'],
      },
      car: {
        type: 'string',
        description: 'Marka samochodu',
        validate: ['required'],
      },
      carElectric: {
        type: 'boolean',
        description: 'Samochód elektryczny',
      },
      number: {
        type: 'string',
        description: 'Numer rejestracyjny samochodu',
        validate: ['required'],
      },
      area: {
        type: 'string',
        description: 'Miejsce wjazdu',
        validate: ['required'],
      },
      purpose: {
        type: 'textarea',
        description: 'Cel wjazdu',
        validate: ['required'],
      },
      additional: {
        type: 'textarea',
        description: 'Informacje dodatkowe',
      },
    },
  },

  moveProperties: {
    // Poruszanie się po obszarze TPN
    properties: {
      name: {
        type: 'string',
        description: 'Imię i nazwisko',
        validate: ['required'],
      },
      address: {
        type: 'string',
        description: 'Adres',
        validate: ['required'],
      },
      persons: {
        type: 'number',
        description: 'Liczba osób',
        validate: ['required'],
      },
      area: {
        type: 'string',
        description: 'Obszar',
        validate: ['required'],
      },
      purpose: {
        type: 'textarea',
        description: 'Cel',
        validate: ['required'],
      },
      /*
      file: {
        type: 'file',
        description: 'Załącznik',
        endpoint: fileSchema.endpoint,
        validate: ['maxSize'],
      },
       */
      additional: {
        type: 'textarea',
        description: 'Informacje dodatkowe',
      },
    },
  },

  sessionWeddingProperties: {
    // Fotografowanie uroczystości rodzinnych np. sesje ślubne
    properties: {
      name: {
        type: 'string',
        description: 'Imię i nazwisko',
        validate: ['required'],
      },
      address: {
        type: 'string',
        description: 'Adres',
        validate: ['required'],
      },
      persons: {
        type: 'number',
        description: 'Liczba osób',
        validate: ['required'],
      },
      area: {
        type: 'string',
        description: 'Obszar',
        validate: ['required'],
      },
      additional: {
        type: 'textarea',
        description: 'Informacje dodatkowe',
      },
    },
  },

  weddingProperties: {
    // Ceremonia ślubna na terenie TPN
    properties: {
      dateFrom: {
        description: 'Data',
        type: 'date',
        validate: ['required'],
      },
      dateTo: {
        description: 'Data do',
        type: 'date',
        // validate: ['required'],
        disabled: true,
      },
      hours: {
        // todo select 8-17
        description: 'Godzina',
        type: 'string',
        validate: ['required'],
      },
      name: {
        type: 'string',
        description: 'Imię i nazwisko',
        validate: ['required'],
      },
      address: {
        type: 'string',
        description: 'Adres',
        validate: ['required'],
      },
      persons: {
        type: 'number',
        description: 'Liczba osób',
        validate: ['required'],
      },
      area: {
        // todo select
        /*
        ['Dolina Chochołowska  - Polana Chochołowska - przy ogródku IMGW, obok schroniska PTTK', 'Dolina Kościeliska –  Smreczyński Staw', 'Dolina Strążyska – Polana Strążyska, przy ławostołach', 'Dolina Bystrej –  Polana Kalatówki – na morenie,  w bezpośrednim sąsiedztwie schroniska', 'Kasprowy Wierch –  przy dzwonie (obok budynku IMGW)', 'Polana Rusinowa  –  polana, przy ławostołach', 'Dolina Rybiego Potoku  –  nad Morskim Okiem, przy kapliczce Matki Bożej od szczęśliwych powrotów', 'Kaplica w Jaszczurówce', 'Chrzest święty Kaplica Jaszczurówka']
         */
        type: 'string',
        description: 'Obszar',
        validate: ['required'],
      },
      additional: {
        type: 'textarea',
        description: 'Informacje dodatkowe',
      },
    },
  },

  carDwProperties: {
    // Wjazd dla osób niepełnosprawnych Palenica Białczańska - Włosienica
    properties: {
      dateFrom: {
        description: 'Data wjazdu',
        type: 'date',
        validate: ['required'],
      },
      dateTo: {
        description: 'Data do',
        type: 'date',
        disabled: true,
      },
      hours: {
        description: 'Godzina wjazdu',
        type: 'choice',
        choices: {
          'to_09:00': 'do 9:00',
          'after_17:00': 'po 17:00',
          'after_18:00': 'po 18:00',
        },
        validate: ['required'],
      },
      name: {
        type: 'string',
        description: 'Imię i nazwisko',
        validate: ['required'],
      },
      address: {
        type: 'string',
        description: 'Adres korespondencyjny',
        validate: ['required'],
      },
      persons: {
        type: 'number',
        description: 'Liczba osób',
        validate: ['required'],
      },
      carType: {
        type: 'resource',
        endpoint: '/api/matter_car_types',
        label: 'Rodzaj pojazdu',
        titleAccessor: 'trans',
        transTitle: true,
      },
      car: {
        type: 'string',
        description: 'Marka samochodu',
        validate: ['required'],
      },
      number: {
        type: 'string',
        description: 'Numer rejestracyjny samochodu',
        validate: ['required'],
      },
      idNumber: {
        type: 'string',
        description: 'Numer legitymacji osoby niepełnosprawnej',
      },
      // judgment: {
      //   type: 'string',
      //   description: 'Orzeczenie o stopniu niepełnosprawności',
      //   hint:
      //     'Pole opcjonalne, dotyczy wjazdów grupowych lista osób wraz z numerem legitymacji.',
      //   disabled: true,
      // },
      additional: {
        type: 'textarea',
        description: 'Informacje dodatkowe',
      },
    },
  },

  photoProperties: {
    // Wykonywanie zdjęć fotograficznych
    properties: {
      name: {
        type: 'string',
        description: 'Imię i nazwisko',
        validate: ['required'],
      },
      address: {
        type: 'string',
        description: 'Adres',
        validate: ['required'],
      },
      persons: {
        type: 'number',
        description: 'Liczba osób',
        validate: ['required'],
      },
      area: {
        type: 'string',
        description: 'Obszar',
        validate: ['required'],
      },
      /*
      file: {
        type: 'file',
        description: 'Załącznik',
        endpoint: fileSchema.endpoint,
        validate: ['maxSize'],
      },
       */
      additional: {
        type: 'textarea',
        description: 'Informacje dodatkowe',
      },
    },
  },

  movieProperties: {
    // Filmowanie na terenie TPN
    properties: {
      name: {
        type: 'string',
        description: 'Producent',
        validate: ['required'],
      },
      address: {
        type: 'string',
        description: 'Adres producenta',
        validate: ['required'],
      },
      title: {
        type: 'string',
        description: 'Tytuł filmu',
        validate: ['required'],
      },
      persons: {
        type: 'number',
        description: 'Liczba osób',
        validate: ['required'],
      },
      area: {
        type: 'string',
        description: 'Obszar',
        validate: ['required'],
      },
      /*
      file: {
        type: 'file',
        description: 'Załącznik',
        endpoint: fileSchema.endpoint,
        validate: ['maxSize'],
      },
       */
      additional: {
        type: 'textarea',
        description: 'Informacje dodatkowe',
      },
    },
  },

  sportProperties: {
    // Filmowanie na terenie TPN
    properties: {
      name: {
        type: 'string',
        description: 'Nazwa imprezy sportowej',
        validate: ['required'],
      },
      organizer: {
        type: 'string',
        description: 'Nazwa organizatora',
        validate: ['required'],
      },
      address: {
        type: 'string',
        description: 'Adres',
        validate: ['required'],
      },
      email: {
        type: 'string',
        description: 'Email',
        validate: ['required'],
      },
      phone: {
        type: 'string',
        description: 'Telefon',
        validate: ['required'],
      },
      runCount: {
        type: 'number',
        description:
          'Liczba biegów/zawodów skiturowych w nw. formatach odbywających się w ramach 1 imprezy. ',
        validate: ['required'],
        hint: '(1-3)',
      },
      headerContactData: {
        type: 'header',
        label: 'Osoba upoważniona do kontaktu',
      },
      contactName: {
        type: 'string',
        description: 'Imię i nazwisko / Nazwa',
        validate: ['required'],
      },
      contactPhone: {
        type: 'string',
        description: 'Numer telefonu',
        validate: ['required'],
      },
      contactEmail: {
        type: 'string',
        description: 'Adres e-mail',
        validate: ['required'],
      },
      /*
      runs: {
        type: 'embeddedCollection',
        //type: 'collection',
        label: 'T_GENERAL_BLOCK_TYPE_MULTI_BRANCHES_BRANCHES',
        titleAccessor: `route`,
        definitionRef: '#MatterFormRequestRun',
        endpoint: '/api/matter_form_request_runs',
        fullWidth: true,
        properties: {
          translations: {
            type: 'translation',
            properties: {
              route: {},
            },
          },
        },
      },*/
    },
  },

  subresources: {
    files: {
      endpoint: '/api/matter_form_request_files',
      definition: '#MatterFormRequestFile',
      properties: {
        media: {
          type: 'file',
          description: 'T_GENERAL_FILE',
          endpoint: fileSchema.protectedEndpoint,
          validate: ['maxSize'],
          isProtected: true,
        },
      },
    },
    runs: {
      endpoint: '/api/matter_form_request_runs',
      definition: '#MatterFormRequestRun',
      properties: {
        eventFormat: {
          type: 'resource',
          endpoint: '/api/matter_form_run_formats',
          label: 'Format zawodów',
          titleAccessor: 'trans',
          transTitle: true,
        },
        eventSeasons: {
          type: 'choice',
          choices: seasonChoices,
          label: 'Liczba sezonów',
          hint:
            'Liczba sezonów/lata na które jest składany wniosek wraz z uzasadnieniem i podaniem daty planowanej imprezy w każdym roku',
          isNumber: true,
        },
        reason: {
          type: 'textarea',
          fullWidth: true,
          description: 'Uzasadnienie',
        },
        startDate1: {
          type: 'date',
          description: ' ',
          hint: 'Data organizacji Imprezy w kolejnym roku',
        },
        startDateRes1: {
          type: 'date',
          description: ' ',
          hint: 'Rezerwowa data organizacji Imprezy w kolejnym roku',
        },
        startDate2: {
          type: 'date',
          description: ' ',
          hint: 'Data organizacji Imprezy w kolejnym (2) roku',
        },
        startDateRes2: {
          type: 'date',
          description: ' ',
          hint: 'Rezerwowa data organizacji Imprezy w kolejnym (2) roku',
        },
        startDate3: {
          type: 'date',
          description: ' ',
          hint: 'Data organizacji Imprezy w kolejnym (3) roku',
        },
        startDateRes3: {
          type: 'date',
          description: ' ',
          hint: 'Rezerwowa data organizacji Imprezy w kolejnym (3) roku',
        },
        startDate4: {
          type: 'date',
          description: ' ',
          hint: 'Data organizacji Imprezy w kolejnym (4) roku',
        },
        startDateRes4: {
          type: 'date',
          description: ' ',
          hint: 'Rezerwowa data organizacji Imprezy w kolejnym (4) roku',
        },
        startDate5: {
          type: 'date',
          description: ' ',
          hint: 'Data organizacji Imprezy w kolejnym (5) roku',
        },
        startDateRes5: {
          type: 'date',
          description: ' ',
          hint: 'Rezerwowa data organizacji Imprezy w kolejnym (5) roku',
        },
        route: {
          type: 'textarea',
          fullWidth: true,
          description: 'Planowana trasa zawodów',
        },
        gpx: {
          type: 'file',
          description: 'Plik GPX',
          endpoint: fileSchema.protectedEndpoint,
          validate: ['maxSize'],
        },
        eatPoints: {
          type: 'textarea',
          fullWidth: true,
          description: 'Liczba i lokalizacja punktów żywieniowych',
        },
        railingPoints: {
          type: 'textarea',
          fullWidth: true,
          description:
            'Liczba i lokalizacja miejsc zakładania poręczówek (wraz z opisem sposobu ich montowania)*',
        },
        startLocation: {
          type: 'resource',
          endpoint: '/api/matter_form_run_locations',
          label: 'Lokalizacja startu',
          titleAccessor: 'trans',
          transTitle: true,
        },
        startLocationTpn: {
          fullWidth: true,
          label: 'Lokalizacja startu w TPN',
        },
        endLocation: {
          type: 'resource',
          endpoint: '/api/matter_form_run_locations',
          label: 'Lokalizacja mety',
          titleAccessor: 'trans',
          transTitle: true,
        },
        endLocationTpn: {
          fullWidth: true,
          label: 'Lokalizacja mety w TPN',
        },
        tpnStartHour: {
          fullWidth: true,
          label: 'Planowana godzina startu w granicach TPN',
        },
        tpnEndHour: {
          fullWidth: true,
          label: 'Planowana godzina mety w granicach TPN',
        },
        length: {
          fullWidth: true,
          label: 'Długość planowanej trasy zawodów',
        },
        height: {
          fullWidth: true,
          label: 'Przewyższenie planowanej trasy zawodów',
        },
        altRoute: {
          type: 'textarea',
          fullWidth: true,
          description: 'Alternatywna trasa zawodów',
        },
        altGpx: {
          type: 'file',
          description: 'Alternatywny plik GPX',
          endpoint: fileSchema.protectedEndpoint,
          validate: ['maxSize'],
        },
        altLength: {
          fullWidth: true,
          label: 'Długość alternatywnej trasy zawodów',
        },
        altHeight: {
          fullWidth: true,
          label: 'Przewyższenie alternatywnej trasy zawodów',
        },
        rules: {
          type: 'file',
          description: 'Regulamin lub projekt regulaminu zawodów',
          endpoint: fileSchema.protectedEndpoint,
          validate: ['maxSize'],
        },
        mission: {
          type: 'textarea',
          fullWidth: true,
          description:
            'Analiza wpływu imprezy na przyrodę TPN (opis max. 1000 znaków)',
        },
        ownCups: {
          label:
            'Korzystanie przez zawodników z własnych kubków/bidonów/butelek',
        },
        plastic: {
          label: 'Obecność plastiku w pakiecie startowym',
        },
        plasticHow: {
          fullWidth: true,
          label: 'Jaki plastik?',
        },
        education: {
          type: 'textarea',
          fullWidth: true,
          label: 'Blok edukacyjny przed/w trakcie imprezy',
        },
        activitiesDescription: {
          type: 'textarea',
          fullWidth: true,
          label: ' ',
          hint:
            'Opis podjętych działań na rzecz realizacji celów ochrony przyrody podczas ostatniej edycji imprezy/w roku poprzedzającym zgłaszane zawody zgodnie z treścią sprawozdania przesłanego po zawodach (§2 ust 22 Zarządzenia nr 50 /2022 Dyrektora TPN w sprawie zasad organizacji imprez rekreacyjno-sportowych na trasach turystycznych i narciarskich na terenie TPN)*',
        },
        cars: {
          label: 'Wjazd samochodów obsługi technicznej na teren TPN',
        },
        carsCount: {
          fullWidth: true,
          label: 'Liczba samochodów',
        },
        carsCountElectric: {
          fullWidth: true,
          label: 'Liczba samochodów elektrycznych',
        },
        persons: {
          fullWidth: true,
          label:
            'Liczba chętnych do wzięcia udziału w imprezie w poprzedniej edycji zawodów',
        },
        personsBefore: {
          fullWidth: true,
          label:
            'Liczba chętnych do wzięcia udziału w imprezie na 1 miejsce w poprzedniej edycji zawodów',
        },
        championship: {
          type: 'resource',
          endpoint: '/api/matter_form_run_championships',
          label: 'Oświadczenie o zgłoszeniu/wpisaniu zawodów do...',
          titleAccessor: 'trans',
          transTitle: true,
        },
        championshipOther: {
          fullWidth: true,
          label: 'Jakie inne?',
        },
        security: {
          label: 'Zabezpieczenie trasy zawodów przez wykwalifikowaną kadrę',
        },
        insurance: {
          label: 'Ubezpieczenie zawodów',
        },
        edition: {
          type: 'resource',
          endpoint: '/api/matter_form_run_editions',
          label: 'Edycja imprezy/zawodów',
          titleAccessor: 'trans',
          transTitle: true,
        },
        editionDetails: {
          fullWidth: true,
          label: 'Od ilu dokładnie lat odbywa się impreza?',
        },
        periodicity: {
          type: 'resource',
          endpoint: '/api/matter_form_run_periodicities',
          label: 'Cykliczność imprezy/zawodów',
          titleAccessor: 'trans',
          transTitle: true,
        },
        periodicityOther: {
          fullWidth: true,
          label: 'Jakie inne?',
        },
        petent: {
          fullWidth: true,
          label:
            'Dane osoby składającej wniosek i funkcja pełniona w klubie/stowarzyszeniu*',
        },
        additionalInfo: {
          type: 'textarea',
          fullWidth: true,
          label: 'Informacje dodatkowe',
        },
        files: {
          type: 'embeddedCollection',
          //type: 'collection',
          label: 'Dodatkowe załączniki',
          titleAccessor: `media.originalName`,
          definitionRef: '#MatterFormRequestRunFile',
          endpoint: '/api/matter_form_request_run_files',
          fullWidth: true,
          properties: {
            media: {
              type: 'file',
              description: 'Plik',
              endpoint: fileSchema.protectedEndpoint,
              validate: ['maxSize'],
            },
          },
        },
      },
    },
  },
}

export default schema
