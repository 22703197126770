import React from 'react'
import { makeStyles } from '@material-ui/styles'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Button from '@material-ui/core/Button'
import { authHeader } from '_helpers/authHeader'
import { notification } from '_helpers/notification'
import { getApiUrl } from '_services/apiUrlProvider'

const useStyles = makeStyles({
  title: {
    marginRight: '1.0em',
  },
})

export const MainCell = ({
  resource,
  isRowLinkable = false,
  editPath = null,
}) => {
  function getfiledownload(url) {
    let xtitle = null
    fetch(url, {
      method: 'GET',
      headers: {
        ...authHeader(),
      },
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Error')
        }
        xtitle = response.headers.get('x-title')
        return response.blob()
      })
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', xtitle ?? 'plik')

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
      .catch(e => {
        notification('error', 'Error, nie można pobrać', e)
      })
  }

  const classes = useStyles()

  return (
    <div>
      <span className={classes.title}>{resource.title}</span>
      {resource.title && (
        <Button
          startIcon={<CloudUploadIcon />}
          color="primary"
          size="small"
          onClick={() => {
            getfiledownload(
              `${getApiUrl()}/api/accommodation/pdf/download/${resource.uuid}`
            )
          }}
        >
          pobierz
        </Button>
      )}
    </div>
  )
}
