const FIELD_TYPES = {
  INTEGER: 'integer',
  NUMBER: 'number',
  FLOAT: 'float',
  DECIMAL: 'decimal',
  BOOLEAN: 'boolean',
  STRING: 'string',
  TEXTAREA: 'textarea',
  TINYMCE: 'tinymce',
  CHOICE: 'choice',
  DATE: 'date',
  DATETIME: 'datetime',
  TIME: 'time',
  PASSWORD: 'password',
  PASSWORD_REPEAT: 'passwordRepeat',
  SLUG: 'slug',
  RESOURCE: 'resource',
  MULTIPLE_RESOURCE: 'multipleResource',
  MAP: 'map',
  TRANSLATION: 'translation',
  FILE: 'file',
  IMAGE: 'image',
  YOUTUBE: 'youtube',
  AUTOCOMPLETE: 'autocomplete',
  COLLECTION: 'collection',
  EMBEDDED_COLLECTION: 'embeddedCollection',
  JSON_DATA: 'json_data',
  HEADER: 'header',
  ELEKTRONICZNY_NADAWCA_RESOURCE: 'elektronicznyNadawcaResourceType',
  SIMPLE_ARRAY: 'simpleArray',
  RESERVATION_EVENT_FORM_DATA: 'reservationEventFormData',
  JSON: 'json',
}

export default FIELD_TYPES
