import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import chartSchema from '../chart'

export const chart = () => ({
  name: BLOCK_TYPES_IRI.CHART,
  label: 'T_GENERAL_BLOCK_TYPE_CHART',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {},
      },
    },
    chart: {
      type: 'resource',
      endpoint: `${chartSchema.endpoint}?pagination=false`,
      titleAccessor: 'workingTitle',
      description: 'Wybierz wykres',
    },
  },
})
