import FIELD_TYPES from '../components/form/_helpers/fieldTypes'
import moment from 'moment/moment'
import photoSchema from './photo'
import { LANGS_IRI } from '../_lib/langs'
import _block from "./_block";
import mediaSchema from "./media";

const schema = {
  endpoint: '/api/reservation_events',
  access: 'reservationEvent',
  resource: {
    definition: '#ReservationEvent',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
          shortLead: {
            type: FIELD_TYPES.TEXTAREA,
            description: 'Krótki opis',
          },
          description: {
            type: FIELD_TYPES.TINYMCE,
            description: 'Opis',
          },
          tourTime: {
            type: FIELD_TYPES.STRING,
            description: 'Czas zwiedzania',
          },
          location: {
            type: FIELD_TYPES.STRING,
            description: 'Lokalizacja',
          },
          alt: {
            description: 'T_GENERAL_ALT',
          },
          slug: {},
        },
      },
      media: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/reservation_event_thumbs',
        },
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              alt: media.translations[LANGS_IRI.PL].alt,
            },
            /* todo en version
            [LANGS_IRI.EN]: {
              alt: media.translations[LANGS_IRI.EN].alt,
            }
             */
          })
        },
      },
      numberOfParticipants: {
        type: FIELD_TYPES.INTEGER,
        description: 'Maksymalna łączna liczba uczestników wydarzenia',
        validate: ['required'],
      },
      startDate: {
        type: FIELD_TYPES.DATETIME,
        description: 'Data od której rezerwacje będą dostępne',
        validate: ['required'],
        defaultValue: moment().format(),
      },
      daysInFuture: {
        type: FIELD_TYPES.INTEGER,
        description: 'Ilość dni do przodu',
        hint: 'W których można złożyć rezerwację',
        initialValue: 30,
      },
      hoursCanCancelBeforeStart: {
        type: FIELD_TYPES.INTEGER,
        description: 'Czas do kiedy można anulować rezerwację',
        hint: 'W godzinach',
      },
      minimumParticipants: {
        type: FIELD_TYPES.INTEGER,
        description: 'Minimalna liczba uczestników [PRZY ZAPISIE]',
        validate: ['required'],
      },
      maximumParticipants: {
        type: FIELD_TYPES.INTEGER,
        description: 'Maksymalna liczba uczestników [PRZY ZAPISIE]',
        validate: ['required'],
      },
      days: {
        type: 'multipleResource',
        endpoint: `/api/days?pagination=false`,
        titleAccessor: 'trans',
        label: 'Dozwolone dni',
        transTitle: true,
      },
      availableHours: {
        type: FIELD_TYPES.SIMPLE_ARRAY,
        description: 'Dostępne godziny',
        addInputLabel: 'Godzina',
        addButtonLabel: 'Dodaj godzinę',
        inputValidate: ['hour'],
        orderable: false,
      },
      notificationEmails: {
        type: FIELD_TYPES.SIMPLE_ARRAY,
        description: 'Emaile do powiadomienia',
        addInputLabel: 'Email',
        addButtonLabel: 'Dodaj email',
        inputValidate: ['email'],
        orderable: false,
      },
      /*
      notificationEmails: {
        type: FIELD_TYPES.RESERVATION_DATES,
        description: 'Dostępne godziny',
        addInputLabel: 'Godzina',
        addButtonLabel: 'Dodaj godzinę',
        inputValidate: ['hour'],
      },
       */
      generateTicket: {
        type: FIELD_TYPES.BOOLEAN,
        description: 'Generuj bilet pdf',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/api/reservation_event_blocks',
      definition: '#ReservationEventBlock',
      types: _block(
        mediaSchema.endpoint,
        '/api/reservation_event_block_thumbs',
        '/api/reservation_event_block_anchors',
        '/api/reservation_event_block_files',
        '/api/reservation_event_block_links',
        '/api/reservation_event_block_link_thumbs',
        '/api/reservation_event_block_list_items',
        '/api/reservation_event_block_list_item_thumbs'
      ).types,
    },
  },
}

export default schema
