import React from 'react'
import { Route } from 'react-router-dom'
import { TuneOutlined, EqualizerOutlined } from '@material-ui/icons'
import { Collection } from './Collection'
import { SchemableComponent } from 'components/SchemableComponent'
import { New } from './New'
import { Edit } from './Edit'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import parentSchema from '_schema/reservationEvent'
import { Calendar } from './Calendar'
import { CollectionReservation } from './CollectionReservation'
import { CollectionStatistics } from './CollectionStatistics'
import { NewStatistics } from './NewStatistics'
import { EditStatistics } from './EditStatistics'
import { FormItemCollection } from './FormItemCollection'
import { FormItemNew } from './FormItemNew'
import { FormItemEdit } from './FormItemEdit'

const basePath = '/reservation_events'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: TuneOutlined,
        //allowedParks: ['TATRZANSKI'],
      },
      new: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={New} {...props} />
        ),
        exact: true,
        path: `${basePath}/new`,
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent path="definitions" component={Edit} {...props} />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },
      calendar: {
        subtitle: 'Kalendarz',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={Calendar}
            {...props}
          />
        ),
        exact: true,
        path: `${basePath}/calendar/:id`,
      },
      reservations: {
        subtitle: 'Rezerwacje',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={CollectionReservation}
            {...props}
          />
        ),
        exact: true,
        path: `${basePath}_reservations`,
      },
      statistics: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <CollectionStatistics {...props} />,
        path: `${basePath}/statistics/:id`,
        exact: true,
        icon: EqualizerOutlined,
        //allowedParks: ['TATRZANSKI'],
      },
      statisticsNew: {
        subtitle: 'T_GENERAL_RECORD_NEW',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={NewStatistics}
            {...props}
          />
        ),
        path: `${basePath}/statistics-new/new`,
        exact: true,
        //allowedParks: ['TATRZANSKI'],
      },
      statisticsEdit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={EditStatistics}
            {...props}
          />
        ),
        path: `${basePath}/statistics/edit/:id`,
        exact: true,
      },
      formItemsIndex: {
        subtitle: 'Lista pól formularza',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={FormItemCollection}
            {...props}
          />
        ),
        path: `${basePath}/:pid/form-items`,
        exact: true,
      },
      formItemNew: {
        subtitle: 'Nowe pole',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={FormItemNew}
            {...props}
          />
        ),
        path: `${basePath}/:pid/form-item/new`,
        exact: true,
      },
      formItemEdit: {
        subtitle: 'Edycja pola',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={FormItemEdit}
            {...props}
          />
        ),
        path: `${basePath}/:pid/form-item/:id`,
        exact: true,
      },
    },
    {
      title: 'Obiekty',
      resourceAccess: parentSchema.access,
      indexPath: basePath,
    }
  )

export default routes
