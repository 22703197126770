import schema from '_schema/reservationEvent'
import dateSchema from '_schema/reservationEventDate'
import React, { useState, useEffect, useRef } from 'react'
import {
  Paper,
  Grid,
  Typography,
  Button,
  Box,
  IconButton,
  Divider,
  FormControlLabel,
  Switch,
  Tooltip,
} from '@material-ui/core'
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Event as EventIcon,
  WarningOutlined as WarningIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { authHeader } from '../../_helpers/authHeader'
import { notification } from '../../_helpers/notification'
import buildUrl from 'build-url'
import { getApiUrl } from '../../_services/apiUrlProvider'
import CircularProgress from '@material-ui/core/CircularProgress'
import { LANGS_IRI } from '../../_lib/langs'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import moment from 'moment/moment'
import DateEditDialog from './components/DateEditDialog'
import DatesLegend from './components/DatesLegend'
import DateGenerationDialog from './components/DateGenerationDialog'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  dayContainer: {
    height: '100%',
    padding: theme.spacing(1),
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
  today: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  dayHeader: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.elements.background,
  },
  timeSlot: {
    width: '100%',
    padding: theme.spacing(1),
    margin: `${theme.spacing(1)}px 0`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
  },
  noReservation: {
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  availableReservation: {
    backgroundColor: '#a5d6a7', // Light green
    color: '#1b5e20', // Dark green for text
    '&:hover': {
      backgroundColor: '#81c784', // Slightly darker green on hover
    },
  },
  fullReservation: {
    backgroundColor: '#ef9a9a', // Light red
    color: '#b71c1c', // Dark red for text
    '&:hover': {
      backgroundColor: '#e57373', // Slightly darker red on hover
    },
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  weekToggle: {
    marginLeft: theme.spacing(2),
  },
  timeSlotText: {
    fontWeight: 500,
  },
  countBadge: {
    borderRadius: '50%',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    fontWeight: 'bold',
  },
  toggleButtonsContainer: {
    display: 'flex',
    gap: 4,
    '& button': {
      flex: 1,
    },
  },
  warningTypography: {
    display: 'flex',
    alignItems: 'center',
  },
  warningIcon: {
    color: 'red',
  },
}))

export const Calendar = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  // State for DateEditDialog component
  const [dateEditDialogOpen, setDateEditDialogOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)

  const [isFetchingAvailability, setIsFetchingAvailability] = useState(false)

  const [currentDate, setCurrentDate] = useState(new Date())
  const [days, setDays] = useState([])
  const [showFullWeek, setShowFullWeek] = useState(false)
  const [parentResource, setParentResource] = useState(null)
  const [collection, setCollection] = useState(null)
  const [isFetchingCollection, setIsFetchingCollection] = useState(false)
  const [defaultFilters, setDefaultFilters] = useState({
    'iri[parent]': iri,
    'order[startDate]': 'ASC',
  })

  const handleClickDateEditDialogOpen = (date, item) => {
    setSelectedDate(date)
    setSelectedItem(item)
    setDateEditDialogOpen(true)
  }

  const handleDateEditDialogClose = () => {
    setDateEditDialogOpen(false)
    setSelectedDate(null)
    setSelectedItem(null)
  }

  const updateDateAvailability = (enabled, date) => {
    if (isFetchingAvailability) {
      return
    }

    setIsFetchingAvailability(true)

    fetch(`${getApiUrl()}/api/reservation_event_dates/availability`, {
      method: 'POST',
      headers: {
        ...authHeader(),
      },
      body: JSON.stringify({
        parent: iri,
        enabled: enabled,
        date: date,
      }),
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Error')
        }

        return response.json()
      })
      .then(jsonData => {
        // forces reload of data
        setIsFetchingAvailability(false)
        setCollection(null)
      })
      .catch(e => {
        setIsFetchingAvailability(false)
        setCollection(null)
        console.error(e)
      })
  }

  const fetchParentResource = () => {
    fetch(`${getApiUrl()}${iri}`, {
      method: 'GET',
      headers: {
        ...authHeader(),
      },
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Error')
        }

        return response.json()
      })
      .then(jsonData => {
        setParentResource(jsonData)
      })
      .catch(e => {
        setParentResource(null)
        console.error(e)
      })
  }

  const fetchParentResourceRef = useRef(fetchParentResource)

  const fetchData = (queryParams = {}) => {
    let jsonResponse = null
    setIsFetchingCollection(true)
    const endpoint = buildUrl(`${getApiUrl()}${dateSchema.endpoint}`, {
      queryParams: queryParams,
    })

    fetch(endpoint, {
      method: 'GET',
      headers: {
        ...authHeader(),
      },
    })
      .then(response => {
        if (response.status !== 200) {
          jsonResponse = response.json()
          throw new Error('Error')
        }

        return response.json()
      })
      .then(jsonData => {
        setCollection(jsonData['hydra:member'] ?? [])
        setIsFetchingCollection(false)
      })
      .catch(e => {
        setCollection([])
        setIsFetchingCollection(false)
        if (jsonResponse) {
          jsonResponse.then(data => {
            notification('error', data.error)
          })
        }
        console.error(e)
      })
  }

  // Format date as YYYY-MM-DD for comparison with reservation data
  const formatDate = date => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  // Get the day name (e.g., "Mon", "Tue")
  const getDayName = date => {
    return date.toLocaleDateString('pl-PL', { weekday: 'short' })
  }

  // Check if date is today
  const isToday = date => {
    const today = new Date()
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    )
  }

  const addOneDay = date => {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + 1)
    return newDate
  }

  const getTimeSlotClassForItem = item => {
    if (!item?.stat) {
      return classes.noReservation
    }

    if (item.calendarReservationsCount === 0) {
      return classes.availableReservation // Green
      //return classes.noReservation // Grey - no reservations
    } else if (item.calendarReservationsCount >= item.calendarMaxParticipants) {
      return classes.fullReservation // Red - fully booked
    } else {
      return classes.availableReservation // Green - has reservations but seats available
    }
  }

  useEffect(() => {
    if (iri && parentResource === null) {
      fetchParentResourceRef.current()
    }
  }, [iri, parentResource])

  useEffect(() => {
    if (iri && collection === null && days.length > 1) {
      const firstDate = days[0]
      const lastDate = addOneDay(days[days.length - 1])

      fetchData({
        ...defaultFilters,
        'startDate[after]': formatDate(firstDate),
        'startDate[before]': formatDate(lastDate),
      })
    }
  }, [collection, days, defaultFilters, iri])

  useEffect(() => {
    if (days.length > 1) {
      const firstDate = days[0]
      const lastDate = addOneDay(days[days.length - 1])

      fetchData({
        ...defaultFilters,
        'startDate[after]': formatDate(firstDate),
        'startDate[before]': formatDate(lastDate),
      })
    }
  }, [days, defaultFilters])

  // Generate the 7-day period to display
  useEffect(() => {
    const daysArray = []
    const dayMs = 24 * 60 * 60 * 1000 // Milliseconds in a day

    let startDate
    if (showFullWeek) {
      // Calculate the first day of the current week (Monday)
      const dayOfWeek = currentDate.getDay()
      const diff =
        currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
      startDate = new Date(currentDate.setDate(diff))
    } else {
      // Just use the current date as the start date
      startDate = new Date(currentDate)
    }

    // Generate 7 days starting from the calculated start date
    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate.getTime() + i * dayMs)
      daysArray.push(date)
    }

    setDays(daysArray)
  }, [currentDate, showFullWeek])

  // Handle date navigation
  const navigateDays = direction => {
    const newDate = new Date(currentDate)
    if (showFullWeek) {
      // Navigate by weeks
      newDate.setDate(newDate.getDate() + direction * 7)
    } else {
      // Navigate by days
      newDate.setDate(newDate.getDate() + direction)
    }
    setCurrentDate(newDate)
  }

  // Handle toggle between 7 consecutive days and current week
  const handleToggleWeekView = () => {
    setShowFullWeek(!showFullWeek)
  }

  const getAvailabilityTextForItem = item => {
    /*
    if (item.calendarReservationsCount === 0) {
      return 'Available'
    } else if (item.calendarReservationsCount >= item.calendarMaxParticipants) {
      return 'Fully Booked'
    } else {
      return `${item.calendarReservationsCount}/${item.calendarMaxParticipants} zarezerwowano`
    }
     */

    return `${item.calendarReservationsCount}/${item.calendarMaxParticipants} rezerwacji`
  }

  return (
    <Paper className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="h6">
          <EventIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
          Kalendarz rezerwacji -{' '}
          {parentResource?.translations?.[LANGS_IRI.PL]?.title}
        </Typography>

        <Box>
          <DateGenerationDialog
            buttonText={'Generuj daty'}
            dialogTitle={'Generuj daty'}
            dialogContent={'Na pewno chcesz wygenerować daty?'}
            apiEndpoint={'/api/reservation_events/generate_dates'}
            requestData={{ parent: parentResource?.['@id'] }}
            alert={
              <Alert severity="info" variant={'filled'}>
                Daty są generowane automatycznie o północy
              </Alert>
            }
            setCollection={setCollection}
          ></DateGenerationDialog>
          <DateGenerationDialog
            buttonText={'Usuń daty'}
            dialogTitle={'Usuń daty'}
            buttonVariant={'secondary'}
            dialogContent={'Na pewno chcesz usunąć daty?'}
            apiEndpoint={'/api/reservation_events/remove_dates'}
            requestData={{ parent: parentResource?.['@id'] }}
            alert={
              <Alert severity="info" variant={'filled'}>
                Daty, które zawierają rezerwacje zostaną pominięte
              </Alert>
            }
            setCollection={setCollection}
            buttonStyle={{ marginLeft: 10, backgroundColor: '#dd6f6f' }}
          ></DateGenerationDialog>
          <FormControlLabel
            className={classes.weekToggle}
            control={
              <Switch
                checked={showFullWeek}
                onChange={handleToggleWeekView}
                color="primary"
              />
            }
            label="Pokaż tygodniowo"
          />
        </Box>
      </Box>

      <Divider />
      <DatesLegend />

      <Box className={classes.controls}>
        <IconButton onClick={() => navigateDays(-1)}>
          <ChevronLeftIcon />
        </IconButton>

        <Typography variant="h6">
          {showFullWeek
            ? `Tydzień ${
                days.length > 0 ? days[0].toLocaleDateString('pl-PL') : ''
              }`
            : `${currentDate.toLocaleDateString('pl-PL', {
                month: 'long',
                year: 'numeric',
              })}`}
        </Typography>

        <IconButton onClick={() => navigateDays(1)}>
          <ChevronRightIcon />
        </IconButton>
      </Box>

      <Grid container spacing={2}>
        {days.map((day, index) => {
          const items = collection
            ? collection.filter(item => formatDate(day) === item.calendarDate)
            : []
          return (
            <Grid item xs={12} sm={6} md key={index}>
              <Paper
                className={`${classes.dayContainer} ${
                  isToday(day) ? classes.today : ''
                }`}
                elevation={1}
              >
                <Box className={classes.dayHeader}>
                  <Typography variant="subtitle1">{getDayName(day)}</Typography>
                  <Typography variant="h6">{day.getDate()}</Typography>
                </Box>
                {items.length > 0 && (
                  <Box mt={1} className={classes.toggleButtonsContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        updateDateAvailability(
                          true,
                          moment(day).format('YYYY-MM-DD')
                        )
                      }}
                      disabled={isFetchingAvailability}
                    >
                      Włącz
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() => {
                        updateDateAvailability(
                          false,
                          moment(day).format('YYYY-MM-DD')
                        )
                      }}
                      disabled={isFetchingAvailability}
                    >
                      Wyłącz
                    </Button>
                  </Box>
                )}
                {!isFetchingCollection && items.length === 0 && (
                  <Typography variant="body2">Brak danych</Typography>
                )}

                <Box mt={1}>
                  {isFetchingCollection && items.length === 0 && (
                    <CircularProgress />
                  )}
                  {items.map(item => {
                    return (
                      <Box
                        key={item.uuid}
                        className={`${
                          classes.timeSlot
                        } ${getTimeSlotClassForItem(item)}`}
                        onClick={() => {
                          handleClickDateEditDialogOpen(formatDate(day), item)
                          // Alert can be removed when using the dialog
                          // alert(
                          //   `Clicked on ${formatDate(day)} at ${
                          //     item.calendarHour
                          //   } - ${getAvailabilityTextForItem(item)}`
                          // )
                        }}
                      >
                        <Typography className={classes.timeSlotText}>
                          {item.calendarHour}
                        </Typography>

                        <Typography className={classes.warningTypography}>
                          {getAvailabilityTextForItem(item)}
                          {!item.stat && item.calendarReservationsCount > 0 && (
                            <Tooltip
                              title={'Wyłączona data posiada rezerwacje!'}
                            >
                              <WarningIcon
                                className={classes.warningIcon}
                              ></WarningIcon>
                            </Tooltip>
                          )}
                        </Typography>
                      </Box>
                    )
                  })}
                </Box>
              </Paper>
            </Grid>
          )
        })}
      </Grid>

      {/* Use the new DateEditDialog component instead of inline Dialog */}
      <DateEditDialog
        open={dateEditDialogOpen}
        onClose={handleDateEditDialogClose}
        fullScreen={fullScreen}
        selectedDate={selectedDate}
        selectedItem={selectedItem}
        definitions={definitions}
        setCollection={setCollection}
      />
    </Paper>
  )
}
