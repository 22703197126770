import FIELD_TYPES from '../components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/reservation_event_statistics',
  access: 'reservationEvent',
  resource: {
    definition: '#ReservationEventStatistics',
    properties: {
      date: {
        type: FIELD_TYPES.DATE,
        description: 'Data',
        validate: ['required'],
      },
      entrances: {
        type: FIELD_TYPES.INTEGER,
        description: 'Liczba wejść',
        validate: ['required'],
      },
      entrancesCanceled: {
        type: FIELD_TYPES.INTEGER,
        description: 'Anulowanych',
        validate: ['required'],
      },
    },
  },
}

export default schema
