export const modifyPropertiesByPage = (properties, resource, schema) => {
  //example
  let modifiedProperties = JSON.parse(JSON.stringify(properties))

  const withoutDescription = [
    'park_interactive_map',
    'park_search',
    'shop_search',
    'park_sitemap',
    'map',
    //'park_hiking_trails',
    'park_tickets_buy',
  ]

  if (withoutDescription.includes(resource.idName)) {
    delete modifiedProperties.translations.properties.description
  }

  switch (resource.idName) {
    // Modify by page here
    case 'home':
      delete modifiedProperties.media
      delete modifiedProperties.translations.properties.description
      delete modifiedProperties.translations.properties.alt
      delete modifiedProperties.translations.properties.link
      break

    case 'park_education_competitions':
      delete modifiedProperties.media
      delete modifiedProperties.translations.properties.alt
      delete modifiedProperties.translations.properties.link
      break

    case 'page_declaration_a11y':
      delete modifiedProperties.translations.properties.description
      delete modifiedProperties.translations.properties.link
      break

    case 'park_guides':
      modifiedProperties.guidesFormVisible = {
        type: 'boolean',
        description: 'Wyszukiwarka przewodników',
      }
      break

    default:
      break
  }

  return modifiedProperties
}
