import { translate } from '_helpers/translate'

const required = value => {
  const result = value !== null && value !== undefined && value !== ''
  //const result =
  //  value !== null && value !== undefined && value !== ''
  return { result, message: !result && 'T_VALIDATION_REQUIRED' }
}

const email = value => {
  const result = value
    ? /^([a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*)@([a-zA-Z0-9]+(?:[.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,})$/i.test(
        value
      )
    : true

  return { result, message: !result && 'T_VALIDATION_EMAIL' }
}

const phone = value => {
  const result = value ? /^[0-9\-+\s]{9,}$/.test(value) : true

  return { result, message: !result && 'T_VALIDATION_PHONE' }
}

const password = value => {
  const result = value
    ? /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
        value
      )
    : true

  return { result, message: !result && 'T_VALIDATION_PASSWORD' }
}

const length = (value, length) => {
  const result = value ? value.toString().length >= length : true

  return {
    result,
    message: !result && translate('T_VALIDATION_LENGTH', [length]),
  }
}

const money = value => {
  const regex = /^[0-9]+(\.[0-9]{1,2})?$/

  let result = true
  let error = null

  if (value === '' || value === null || value === undefined) {
    return true
  }

  if (typeof value === 'string' && value?.includes(',')) {
    result = false
    error = 'T_VALIDATION_MONEY_HAS_COMMA'
  }

  if (result === true) {
    result = regex.test(value)
    error = 'T_VALIDATION_MONEY'
  }

  return { result, message: !result && error }
}

const withoutComma = value => {
  if (!value) {
    return true
  }

  let result = true

  if (typeof value === 'string' && value?.includes(',')) {
    result = false
    //return { result: true, message: 'T_VALIDATION_HAS_COMMA' }
  }

  return { result, message: !result && 'T_VALIDATION_HAS_COMMA' }
}

const minValue = (value, min) => {
  const result = value ? value >= parseInt(min) : true

  return {
    result,
    message: !result && translate('T_VALIDATION_MIN_VALUE', [min]),
  }
}

const maxValue = (value, max) => {
  const result = value ? value <= parseInt(max) : true

  return {
    result,
    message: !result && translate('T_VALIDATION_MAX_VALUE', [max]),
  }
}

const maxSize = (value, max = process.env.REACT_APP_MAX_UPLOAD_SIZE) => {
  if (Array.isArray(value)) {
    return maxSizeMultiple(value, max)
  }

  const result = value ? (value.size || value) <= parseInt(max) : true

  return {
    result,
    message:
      !result &&
      translate('T_VALIDATION_MAX_UPLOAD_SIZE', [`${max / (1024 * 1024)} MB`]),
  }
}

const maxSizeMultiple = (
  values,
  max = process.env.REACT_APP_MAX_UPLOAD_SIZE
) => {
  let completeSize = 0

  for (let i = 0; i < values.length; i++) {
    completeSize += values[i].size
  }

  const result = values.length ? completeSize <= parseInt(max) : true

  return {
    result,
    message:
      !result &&
      translate('T_VALIDATION_MAX_UPLOAD_MULTIPLE_SIZE', [
        `${max / (1024 * 1024)} MB`,
      ]),
  }
}

const hour = value => {
  const result = value ? /^([01]\d|2[0-3]):([0-5]\d)$/.test(value) : true

  return { result, message: !result && 'T_VALIDATION_HOUR' }
}

export const validators = {
  required,
  email,
  phone,
  password,
  length,
  money,
  withoutComma,
  maxSize,
  maxSizeMultiple,
  minValue,
  maxValue,
  hour,
}
