import React, { useMemo } from 'react'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { translate } from '_helpers/translate'
import schema from '_schema/shopPromotion'
import { DateCell } from '../../components/table/cells/DateCell'
import { StringFilter } from '../../components/table/filters/StringFilter'
import { ResourceFilter } from '../../components/table/filters/ResourceFilter'

export const Orders = () => {
  const columns = useMemo(
    () => [
      {
        filtersList: [],
        columnId: 'startDate_',
        header: translate('Data rozpoczęcia'),
        accessor: `startDate`,
        filterable: true,
        sortable: true,
        width: '20%',
        Cell: DateCell,
      },
      {
        filtersList: [],
        columnId: 'endDate_',
        header: translate('Data zakończenia'),
        accessor: `endDate`,
        filterable: true,
        sortable: true,
        width: '20%',
        Cell: DateCell,
      },
      {
        columnId: 'ordersCount',
        header: translate('Liczba zamówień'),
        accessor: `ordersCount`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'productsCount',
        header: translate('Liczba produktów'),
        accessor: `productsCount`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'totalValue',
        header: translate('Suma'),
        accessor: `totalValue`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
    ],
    []
  )

  const today = new Date().toISOString().slice(0, 10)

  return (
    <SchemableCollection
      component={CollectionTable}
      path={`paths.${schema.endpoint}.get`}
      endpoint={'/api/shop_report_orders'}
      definition={schema.resource.definition}
      columns={columns}
      customResourceSchema={schema.resource}
      defaultSorters={{ ord: 'desc' }}
      orderable={false}
      isRowLinkable={false}
      numerable={false}
      autoWidth={false}
      horizontalFilterBar={true}
      paginable={false}
      customFilters={[
        {
          name: 'startDate',
          title: 'Data rozpoczęcia',
          Filter: StringFilter,
          value: today,
        },
        {
          name: 'endDate',
          title: 'Data zakończenia',
          Filter: StringFilter,
          value: today,
        },
        {
          name: 'groupBy',
          title: 'Grupowanie',
          Filter: ResourceFilter,
          endpoint: '/api/shop_report_group_bies?pagination=false',
          titleAccessor: 'trans',
          horizontal: true,
        },
        {
          name: 'status',
          title: 'Status',
          Filter: ResourceFilter,
          endpoint: '/api/shop_order_statuses?pagination=false',
          titleAccessor: 'title',
          horizontal: true,
        },
      ]}
      parameters={[
        {
          name: 'startDate',
          in: 'query',
          type: 'string',
        },
        {
          name: 'endDate',
          in: 'query',
          type: 'string',
        },
        {
          name: 'groupBy',
          in: 'query',
          type: 'string',
        },
        {
          name: 'status',
          in: 'query',
          type: 'string',
        },
      ]}
    />
  )
}
