import TYPES from '../components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/exception_logs',
  access: 'exceptionLog',
  resource: {
    definition: '#ExceptionLog',
    properties: {
      createdAt: {
        type: TYPES.DATETIME,
        description: 'T_GENERAL_CREATED_AT',
      },
      data: {
        type: TYPES.JSON,
        label: 'Szczegóły',
      },
    },
  },
}

export default schema
