import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FileCopy } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { DialogLoader } from 'components/DialogLoader'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { translate } from '_helpers/translate'

const useStyles = makeStyles(theme => ({
  withIcon: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'inherit',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  withoutIcon: {
    width: '100%',
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  disabled: {
    color: theme.palette.disabled,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& span': {
      fontSize: 14,
      paddingLeft: 10,
    },
  },
}))

export const TourismNoticeVersion = ({
  resource = null,
  endpoint,
  disabled = false,
  onSuccess = null,
  isIcon = false,
  customIri = null,
}) => {
  const [isFetching, setIsFetching] = useState(false)

  const classes = useStyles()
  const handleClick = () => {
    if (disabled) {
      return
    }

    setIsFetching(true)

    fetchDataHandleAuthError(
      endpoint,
      'POST',
      {
        body: JSON.stringify({
          iri: customIri ? customIri : resource['@id'],
        }),
      },
      resource => {
        setIsFetching(false)

        notification(
          'success',
          'T_GENERAL_PUBLISHED_NEW_VERSION',
          'T_FORM_SUCCESS'
        )
        onSuccess && onSuccess(resource)
      },
      error => {
        setIsFetching(false)

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  if (!resource) {
    return null
  }

  return (
    <>
      {disabled ? (
        <div className={classes.container}>
          <FileCopy className={classes.disabled} disabled />
          <span className={classes.disabled}>
            {translate('T_GENERAL_PUBLISH_NEW_VERSION')}
          </span>
        </div>
      ) : (
        <div className={classes.container} onClick={handleClick}>
          <FileCopy />
          <span>{translate('T_GENERAL_PUBLISH_NEW_VERSION')}</span>
        </div>
      )}
      <DialogLoader
        isOpen={isFetching}
        title={translate('T_GENERAL_PUBLISHING_NEW_VERSION')}
      />
    </>
  )
}

TourismNoticeVersion.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }),
  endpoint: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  isIcon: PropTypes.bool,
  customIri: PropTypes.string,
}
