import React from 'react'
import { Box, Typography } from '@material-ui/core'

const DatesLegend = () => {
  const legendContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginBottom: '8px',
    marginTop: '8px',
  }

  const legendItemStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  }

  const dotStyle = {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
  }

  return (
    <Box sx={legendContainerStyle}>
      <Box sx={legendItemStyle}>
        <Box
          sx={{
            ...dotStyle,
            backgroundColor: '#a5d6a7',
          }}
        />
        <Typography variant="body2">Wolne miejsca</Typography>
      </Box>
      <Box sx={legendItemStyle}>
        <Box
          sx={{
            ...dotStyle,
            backgroundColor: '#ef9a9a',
          }}
        />
        <Typography variant="body2">Brak miejsc</Typography>
      </Box>
      <Box sx={legendItemStyle}>
        <Box
          sx={{
            ...dotStyle,
            backgroundColor: '#e0e0e0',
          }}
        />
        <Typography variant="body2">Wyłączone</Typography>
      </Box>
    </Box>
  )
}

export default DatesLegend
