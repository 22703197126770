import React from 'react'
import { Form } from 'pages'
import { SidebarNotStatable } from 'components/resource/sidebar'
import { modifyPropertiesByPage } from './_helpers/modifyPropertiesByPage'
import schema from '_schema/reservationEventFormItem'
import routes from './routes'
import { translate } from '../../_helpers/translate'
import { LANGS_IRI } from '../../_lib/langs'
import { EmbeddedCollection } from '../../components/embedded'
import { modifyOptionPropertiesByPage } from './_helpers/modifyOptionPropertiesByPage'

export const FormItemEdit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [parentResource, setParentResource] = React.useState(null)

  const optionProperties = parentResource
    ? modifyOptionPropertiesByPage(
        schema.subresources.options.properties,
        parentResource,
        definitions[schema.subresources.options.definition]
      )
    : null

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().formItemsIndex.path.replace(
        ':pid',
        match.params.pid
      )}
      storeCollectionId={schema.endpoint}
      sidebar={true}
      sidebarTitleAccessor={`translations.${process.env.REACT_APP_LOCALE}.title`}
      SidebarComponent={SidebarNotStatable}
      width="100%"
      modifyProperties={(properties, resource, schema) => {
        return modifyPropertiesByPage(properties, resource, schema)
      }}
      setParentResource={setParentResource}
    >
      <EmbeddedCollection
        endpoint={`${schema.subresources.options.endpoint}?iri[parent]=${iri}`}
        pid={match.params.id}
        parentIri={iri}
        properties={optionProperties}
        definitionSchema={definitions[schema.subresources.options.definition]}
        headerTitle={translate('Opcje pola')}
        expandTitle={translate('Rozwiń opcje')}
        panelTitle={translate('Opcja')}
        titleAccessor={`translations.${LANGS_IRI.PL}.title`}
        statable={false}
        order={'desc'}
      />
    </Form>
  )
}
