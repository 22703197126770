import { BLOCK_TYPES_IRI } from '_lib/blockTypes'
import shopCategory from '../shopCategory'
import { LANGS_IRI } from '../../_lib/langs'

export const shopCarousel = (
  photoEndpoint,
  thumbEndpoint,
  labelTrans = null
) => ({
  name: BLOCK_TYPES_IRI.SHOP_CAROUSEL,
  label: labelTrans ?? 'T_GENERAL_BLOCK_TYPE_SHOP_CAROUSEL',
  properties: {
    blockAnchor: {
      type: 'string',
      description: 'T_GENERAL_BLOCK_ANCHOR_ID',
      fullWidth: true,
    },
    translations: {
      type: 'translation',
      properties: {
        title: {
          description: 'Title',
        },
        shortLead: {
          type: 'string',
          description: 'T_GENERAL_DESCRIPTION',
        },
        alt: {
          type: 'string',
          description: 'T_GENERAL_ALT',
        },
      },
    },
    products: {
      type: 'multipleResource',
      endpoint: `/api/shop_products/select?pagination=false&order[nativeTitle]=asc`,
      titleAccessor: 'nativeTitle',
      label: 'Produkty',
      hint: {
        text:
          'Gdy wybrane są produkty, inne opcje poniżej zostaną zignorowane!',
        color: '#cc0000',
      },
    },
    productsOrder: {
      type: 'array',
      label: 'Kolejność produktów',
      endpoint: `/api/shop_products/select?pagination=false&order[nativeTitle]=asc`,
    },
    // todo shop category
    shopCategory1: {
      type: 'resource',
      endpoint: `${shopCategory.endpoint}?pagination=false`,
      titleAccessor: `translations.${LANGS_IRI.PL}.title`,
      description: 'Select shop category',
    },
    shopIsNew: {
      type: 'boolean',
      description: 'T_BLOCK_SHOP_CAROUSEL_IS_NEW',
    },
    shopIsBestseller: {
      type: 'boolean',
      description: 'T_BLOCK_SHOP_CAROUSEL_IS_BESTSELLER',
    },
    shopIsSuggested: {
      type: 'boolean',
      description: 'T_BLOCK_SHOP_BANNER_PROMOTION',
    },
    media: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      thumbs: thumbEndpoint && {
        endpoint: `${thumbEndpoint}?name[]=shop_carousel`,
      },
      validate: ['maxSize'],
      handleFileManagerMetadata: (setValue, media) => {
        setValue('injectTranslations', {
          [LANGS_IRI.PL]: {
            alt: media.translations[LANGS_IRI.PL].alt,
          },
          /* todo en version
          [LANGS_IRI.EN]: {
            alt: media.translations[LANGS_IRI.EN].alt,
          }
           */
        })
      },
    },
  },
})
