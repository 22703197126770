export const modifyPropertiesByPage = (properties, resource, schema) => {
  //let modifiedProperties = properties
  return properties

  /*
  if (resource?.homepage === true) {
    delete modifiedProperties.translations.properties.description
  }

  return modifiedProperties
  */
}
