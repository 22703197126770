const schema = {
  endpoint: '/api/weather_locations',
  access: 'weatherLocation',
  resource: {
    definition: '#WeatherLocation',
    properties: {
      title: {
        description: 'Nazwa punktu pogodowego',
        validate: ['required'],
      },
      // location: { //only of
      //   description: 'Nazwa punktu pogodowego (ID name)',
      //   disable: true,
      // },
      // locationId: { //only of
      //   description: 'ID punktu pogodowego (ID name)',
      //   disable: true,
      // },
      terytId: {},
      html: {
        type: 'textarea',
        description: 'Kod html popupa',
      },
      printInPdf: {
        type: 'boolean',
        description: 'Drukuj w PDF noclegów',
      },
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
  resourceAdd: {
    definition: '#WeatherLocation',
    properties: {
      title: {
        description: 'Nazwa punktu pogodowego',
        validate: ['required'],
      },
      location: {
        description: 'Nazwa punktu pogodowego (ID name)',
        validate: ['required'],
      },
      terytId: {},
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
