import { LANGS_IRI } from '../_lib/langs'

// to samo co w formsetitem.php
const formsetItemsTypeList = {
  text: 'Pole tekstowe (input)',
  radio: 'Pole z opcjami (radio)',
  checkbox: 'Pole wyboru (checkbox)',
  textarea: 'Obszar tekstowy',
  select: 'Lista wyboru',
  multiple: 'Lista wyboru z możliwością zaznaczenia kilku opcji',
  email: 'Pole do wprowadzenia adresu email',
  // 'url'   : 'Pole do wprowadzenia adresu url',
  number: 'Pole liczbowe',
  tel: 'Pole do wprowadzenia numeru telefonu',
  // 'date'   : 'Pole do wprowadzenia daty',
  // 'time'   : 'Pole do wprowadzenia godziny',
  //file: 'Pole do dodania załączników',
  consent: 'Pole do zatwierdzenia zgód',
  header: 'Nagłówek podgrupy',
}

const schema = {
  endpoint: '/api/reservation_event_form_items',
  resourceforadd: {
    definition: '#ReservationEventFormItem',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Tytuł',
          },
        },
      },
      type: {
        type: 'choice',
        choices: formsetItemsTypeList,
        label: 'Typ pola',
        validate: ['required'],
      },
    },
  },
  resource: {
    definition: '#ReservationEventFormItem',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Tytuł',
            hint: 'nazwa widoczna tylko w PA',
          },
          shortLead: {
            type: 'textarea',
          },
          placeholder: {},
          errorMessage: {},
        },
      },
      type: {
        type: 'choice',
        choices: formsetItemsTypeList,
        label: 'Typ pola',
        validate: ['required'],
        disabled: true,
      },
      required: {},
      fileSize: {
        type: 'number',
        hint: 'podaj maksymalny rozmiar w MB',
        validate: ['required'],
      },
      fileExtension: {
        hint: 'podaj po przecinku, np jpg,gif,png',
        validate: ['required'],
      },
      textareaSize: {},
      maxCount: {
        type: 'integer',
        minValue: 0,
        defaultValue: 0,
        hint: 'wpisz 0 lub puste pole aby wyłaczyć',
      },
      stat: {},
      /*
      options: {
        properties: {
          translations: {
            type: 'translation',
            properties: {
              title: {
                type: 'string',
              },
              shortLead: {
                type: 'tinymce3',
              },
            },
          },
          expandItem: {
            type: 'resource',
            endpoint: (form) => `/api/reservation_event_form_items?pagination=false&parent.uuid=${form.parentEventUuid}`,
            test55: (form) => `/api/reservation_event_form_items?pagination=false&parent.uuid=${form.parentEventUuid}`,
            titleAccessor: `translations.${
              LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
            }.title`,
            label: 'Zależne pole',
          },
          isDefault: {},
          required: {},
          stat: {},
        },
      },*/
    },
  },
  subresources: {
    options: {
      endpoint: '/api/reservation_event_form_item_options',
      definition: '#ReservationEventFormItemOption',
      properties: {
        translations: {
          type: 'translation',
          properties: {
            title: {
              type: 'string',
            },
            shortLead: {
              type: 'tinymce3',
            },
          },
        },
        expandItems: {
          type: 'multipleResource',
          endpoint: form =>
            `/api/reservation_event_form_items?pagination=false&parent.uuid=${form.parentEventUuid}`,
          titleAccessor: `translations.${
            LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
          }.title`,
          label: 'Zależne pola',
        },
        isDefault: {},
        required: {},
        stat: {},
      },
    },
  },
}

export default schema
