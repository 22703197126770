export const BLOCK_TYPES = {
  '/api/block_types/TEXT': 'text',
  '/api/block_types/TEXT_WITH_PHOTO': 'text_with_photo',
  '/api/block_types/TEXT_WITH_PHOTO_FLOAT': 'text_with_photo_float',
  '/api/block_types/TEXT_WITH_PHOTO_ZOOM': 'text_with_photo_zoom',
  '/api/block_types/TEXT_WITH_VIDEO': 'text_with_video',
  '/api/block_types/TEXT_WITH_LINK': 'text_with_link',
  '/api/block_types/GALLERY': 'gallery',
  '/api/block_types/FILES': 'files',
  '/api/block_types/FAQ': 'faq',
  '/api/block_types/LOGOTYPES': 'logotypes',
  '/api/block_types/ANCHORS': 'anchors',
  '/api/block_types/TRAILS': 'trails',
  '/api/block_types/ALERT': 'alert',
  '/api/block_types/LIST': 'list',
  '/api/block_types/BANNER1': 'banner1',
  '/api/block_types/BANNER2': 'banner2',
  '/api/block_types/PRODUCT': 'product',
  '/api/block_types/NEWSLETTER': 'newsletter',
  '/api/block_types/LINKS': 'links',
  '/api/block_types/COLUMNS': 'columns',
  '/api/block_types/MIGINFO': 'miginfo',
  '/api/block_types/CREW': 'crew',
  '/api/block_types/CREW2': 'crew2',
  '/api/block_types/FORMSETBLOCK': 'formsetblock',
  '/api/block_types/TRAIL_CONDITIONS': 'trail_conditions',
  '/api/block_types/TOURISM_INFORMATION_NOTICES': 'tourism_information_notices',
  '/api/block_types/FINALIST': 'finalist',
  '/api/block_types/CONTEST': 'contestauto',
  '/api/block_types/CONTESTFORM': 'formcontest',
  '/api/block_types/NEWSHOME': 'newshome',
  '/api/block_types/VIDEOHOME': 'videohome',
  '/api/block_types/LINKSHOME': 'linkshome',
  '/api/block_types/TRAILHOME': 'trailhome',
  '/api/block_types/EVENTHOME': 'eventhome',
  '/api/block_types/SHOPHOME': 'shophome',
  '/api/block_types/SHORTCUTS': 'shortcuts',
  '/api/block_types/GOODTOKNOWN': 'gtk',
  '/api/block_types/EMBED': 'embed',
  '/api/block_types/HOMEATRACTION': 'homeattraction',
  '/api/block_types/HOMEGETMORE': 'homegetmore',
  '/api/block_types/HOME_AWAA': 'home_awaa',
  '/api/block_types/PRICELIST': 'pricelist',
  '/api/block_types/INLINELOGO': 'inlinelogo',
  '/api/block_types/LOGOTYPES_WITH_CONTENT': 'logotypes_with_content',
  '/api/block_types/CONTACT2US': 'contact2us',
  '/api/block_types/STOPS': 'stops',
  '/api/block_types/HYDROCHART': 'hydrochart',
  '/api/block_types/HERO_CAROUSEL': 'hero_carousel',
  '/api/block_types/SHOP_CAROUSEL': 'shop_carousel',
  '/api/block_types/SHOP_BENEFITS': 'shop_benefits',
  '/api/block_types/SHOP_BANNER1': 'shop_banner1',
  '/api/block_types/SHOP_BANNER2': 'shop_banner2',
  '/api/block_types/SHOP_PHOTO_VIDEO': 'shop_photo_video',
  '/api/block_types/A11_TEXT': 'a11text',
  '/api/block_types/A11_STRING': 'a11string',
  '/api/block_types/NEWS_CATEGORIES': 'news_categories',
  '/api/block_types/AUDIO': 'audio',
  '/api/block_types/DROPLABS': 'droplabs',
  '/api/block_types/CHART': 'chart',
}

export const BLOCK_TYPES_IRI = {
  TEXT: '/api/block_types/TEXT',
  TEXT_WITH_PHOTO: '/api/block_types/TEXT_WITH_PHOTO',
  TEXT_WITH_PHOTO_FLOAT: '/api/block_types/TEXT_WITH_PHOTO_FLOAT',
  TEXT_WITH_PHOTO_ZOOM: '/api/block_types/TEXT_WITH_PHOTO_ZOOM',
  TEXT_WITH_VIDEO: '/api/block_types/TEXT_WITH_VIDEO',
  TEXT_WITH_LINK: '/api/block_types/TEXT_WITH_LINK',
  GALLERY: '/api/block_types/GALLERY',
  FILES: '/api/block_types/FILES',
  FAQ: '/api/block_types/FAQ',
  LOGOTYPES: '/api/block_types/LOGOTYPES',
  ANCHORS: '/api/block_types/ANCHORS',
  TRAILS: '/api/block_types/TRAILS',
  ALERT: '/api/block_types/ALERT',
  LIST: '/api/block_types/LIST',
  BANNER1: '/api/block_types/BANNER1',
  BANNER2: '/api/block_types/BANNER2',
  PRODUCT: '/api/block_types/PRODUCT',
  NEWSLETTER: '/api/block_types/NEWSLETTER',
  LINKS: '/api/block_types/LINKS',
  COLUMNS: '/api/block_types/COLUMNS',
  MIGINFO: '/api/block_types/MIGINFO',
  CREW: '/api/block_types/CREW',
  CREW2: '/api/block_types/CREW2',
  FORMSETBLOCK: '/api/block_types/FORMSETBLOCK',
  TRAIL_CONDITIONS: '/api/block_types/TRAIL_CONDITIONS',
  TOURISM_INFORMATION_NOTICES: '/api/block_types/TOURISM_INFORMATION_NOTICES',
  FINALIST: '/api/block_types/FINALIST',
  CONTEST: '/api/block_types/CONTEST',
  CONTESTFORM: '/api/block_types/CONTESTFORM',
  NEWSHOME: '/api/block_types/NEWSHOME',
  VIDEOHOME: '/api/block_types/VIDEOHOME',
  LINKSHOME: '/api/block_types/LINKSHOME',
  TRAILHOME: '/api/block_types/TRAILHOME',
  EVENTHOME: '/api/block_types/EVENTHOME',
  SHOPHOME: '/api/block_types/SHOPHOME',
  SHORTCUTS: '/api/block_types/SHORTCUTS',
  GOODTOKNOWN: '/api/block_types/GOODTOKNOWN',
  EMBED: '/api/block_types/EMBED',
  HOMEATRACTION: '/api/block_types/HOMEATRACTION',
  HOMEGETMORE: '/api/block_types/HOMEGETMORE',
  HOME_AWAA: '/api/block_types/HOME_AWAA',
  PRICELIST: '/api/block_types/PRICELIST',
  INLINELOGO: '/api/block_types/INLINELOGO',
  LOGOTYPES_WITH_CONTENT: '/api/block_types/LOGOTYPES_WITH_CONTENT',
  CONTACT2US: '/api/block_types/CONTACT2US',
  STOPS: '/api/block_types/STOPS',
  HYDROCHART: '/api/block_types/HYDROCHART',
  HERO_CAROUSEL: '/api/block_types/HERO_CAROUSEL',
  SHOP_CAROUSEL: '/api/block_types/SHOP_CAROUSEL',
  SHOP_BENEFITS: '/api/block_types/SHOP_BENEFITS',
  SHOP_BANNER1: '/api/block_types/SHOP_BANNER1',
  SHOP_BANNER2: '/api/block_types/SHOP_BANNER2',
  SHOP_PHOTO_VIDEO: '/api/block_types/SHOP_PHOTO_VIDEO',
  A11_TEXT: '/api/block_types/A11_TEXT',
  A11_STRING: '/api/block_types/A11_STRING',
  NEWS_CATEGORIES: '/api/block_types/NEWS_CATEGORIES',
  AUDIO: '/api/block_types/AUDIO',
  DROPLABS: '/api/block_types/DROPLABS',
  CHART: '/api/block_types/CHART',
}
