import React from 'react'
import { Route } from 'react-router-dom'
import { TuneOutlined } from '@material-ui/icons'
import { SchemableComponent } from 'components/SchemableComponent'
import { addRoutesConfig } from '_helpers/addRoutesConfig'
import parentSchema from '_schema/reservationEvent'
import { CollectionReservation } from './CollectionReservation'
import { EditReservation } from './EditReservation'

const basePath = '/reservation_events_reservations'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'T_GENERAL_RECORD_LIST',
        type: Route,
        render: props => <CollectionReservation {...props} />,
        path: basePath,
        exact: true,
        icon: TuneOutlined,
        //allowedParks: ['TATRZANSKI'],
      },
      edit: {
        subtitle: 'T_GENERAL_RECORD_EDIT',
        type: Route,
        render: props => (
          <SchemableComponent
            path="definitions"
            component={EditReservation}
            {...props}
          />
        ),
        path: `${basePath}/:id`,
        exact: true,
      },
    },
    {
      title: 'Rezerwacje',
      resourceAccess: parentSchema.access,
      indexPath: basePath,
    }
  )

export default routes
