import { LANGS_IRI } from '../_lib/langs'

// to samo co w formsetitem.php
const formsetItemsTypeList = {
  text: 'Pole tekstowe (input)',
  radio: 'Pole z opcjami (radio)',
  checkbox: 'Pole wyboru (checkbox)',
  textarea: 'Obszar tekstowy',
  select: 'Lista wyboru',
  multiple: 'Lista wyboru z możliwością zaznaczenia kilku opcji',
  email: 'Pole do wprowadzenia adresu email',
  // 'url'   : 'Pole do wprowadzenia adresu url',
  number: 'Pole liczbowe',
  tel: 'Pole do wprowadzenia numeru telefonu',
  // 'date'   : 'Pole do wprowadzenia daty',
  // 'time'   : 'Pole do wprowadzenia godziny',
  file: 'Pole do dodania załączników',
  consent: 'Pole do zatwierdzenia zgód',
  header: 'Nagłówek podgrupy',
}

const schema = {
  endpoint: '/api/formset_items',
  resourceforadd: {
    definition: '#FormsetItem',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Tytuł',
          },
        },
      },
      type: {
        type: 'choice',
        choices: formsetItemsTypeList,
        label: 'Typ pola',
        validate: ['required'],
      },
    },
  },
  resource: {
    definition: '#FormsetItem',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            label: 'Tytuł',
            hint: 'nazwa widoczna tylko w PA',
          },
          shortLead: {
            type: 'textarea',
          },
          placeholder: {},
          errorMessage: {},
        },
      },
      type: {
        type: 'choice',
        choices: formsetItemsTypeList,
        label: 'Typ pola',
        validate: ['required'],
        disabled: true,
      },
      required: {},
      fileSize: {
        type: 'number',
        hint: 'podaj maksymalny rozmiar w MB',
        validate: ['required'],
      },
      fileExtension: {
        hint: 'podaj po przecinku, np jpg,gif,png',
        validate: ['required'],
      },
      textareaSize: {},
      maxCount: {
        type: 'integer',
        minValue: 0,
        defaultValue: 0,
        hint: 'wpisz 0 lub puste pole aby wyłaczyć',
      },
      stat: {},

      options: {
        type: 'embeddedCollection',
        endpoint: '/api/formset_item_options',
        definitionRef: '#FormsetItemOption',
        description: 'Opcje pola',
        order: 'desc',
        titleAccessor: `translations.${
          LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
        }.title`,

        properties: {
          translations: {
            type: 'translation',
            properties: {
              title: {
                type: 'string',
              },
              shortLead: {
                type: 'tinymce3',
              },
            },
          },
          isDefault: {},
          required: {},
          stat: {},
        },
      },
    },
  },
}

export default schema
