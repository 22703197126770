import 'date-fns'
import moment from 'moment'
import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { SystemUpdateAlt } from '@material-ui/icons'
import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core'
import { DialogTitleWithClose } from 'components/DialogTitleWithClose'
import { getApiUrl } from '../../../_services/apiUrlProvider'
import { authHeader } from '../../../_helpers/authHeader'
import { notification } from '../../../_helpers/notification'
import { MultipleResourceType } from '../../../components/form/fields/MultipleResourceType'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

const useStyles = makeStyles({
  content: {
    textAlign: 'left',
  },
})

export const ExportStatisticsModal = ({
  title,
  buttonTitle = 'Eksportuj',
  defaultData = {},
  classes = {},
  hideSelect = false,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleDialogToggle = useCallback(() => {
    setIsDialogOpen(state => !state)
  }, [])

  const [isFetching, setIsFetching] = useState(false)

  const [selectValue, setSelectValue] = useState(null)

  const [dateFrom, setDateFrom] = useState(new Date())
  const [dateTo, setDateTo] = useState(new Date())

  const defaultClasses = useStyles()

  let xTitle = null
  let responseError = null

  const handleExportClick = () => {
    setIsFetching(true)
    fetch(`${getApiUrl()}/api/reservation_event_statistics/export`, {
      method: 'POST',
      headers: {
        ...authHeader(),
      },
      body: JSON.stringify({
        dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
        dateTo: moment(dateTo).format('YYYY-MM-DD'),
        objects: selectValue || [],
        ...defaultData,
      }),
    })
      .then(response => {
        if (response.status !== 200) {
          responseError = response.json()
          throw new Error('Error')
        }
        xTitle = response.headers.get('x-title')
        setIsFetching(false)
        return response.blob()
      })
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', xTitle ?? 'export.xlsx')

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
        setIsFetching(false)

        notification(
          'success',
          'Pomyślnie wyeksportowano dane',
          'T_FORM_SUCCESS'
        )
      })
      .catch(e => {
        notification('error', 'Błąd podczas eksportu danych')
        setIsFetching(false)

        responseError.then(data => {
          notification('error', data.error)
        })
      })
  }

  const setError = useCallback((name, error) => {}, [])

  const setValue = useCallback((name, value, setRenderError = true) => {
    setSelectValue(value)
  }, [])

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<SystemUpdateAlt />}
        onClick={handleDialogToggle}
        disabled={isFetching}
      >
        {buttonTitle}
      </Button>
      <Dialog maxWidth="md" onClose={handleDialogToggle} open={isDialogOpen}>
        <DialogTitleWithClose onClose={handleDialogToggle}>
          {title}
        </DialogTitleWithClose>
        <DialogContent
          className={clsx(defaultClasses.content, classes.content)}
        >
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ width: '100%' }}
                margin="normal"
                id="date-picker-date-from"
                label="Data od"
                format="yyyy-MM-dd"
                value={dateFrom}
                onChange={date => setDateFrom(date)}
                KeyboardButtonProps={{
                  'aria-label': 'zmień datę',
                }}
                required={true}
                autoOk={true}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                style={{ width: '100%' }}
                margin="normal"
                id="date-picker-date-to"
                label="Data od"
                format="yyyy-MM-dd"
                value={dateTo}
                onChange={date => setDateTo(date)}
                KeyboardButtonProps={{
                  'aria-label': 'zmień datę',
                }}
                required={true}
              />
            </MuiPickersUtilsProvider>
          </div>
          {!hideSelect && (
            <div style={{ marginTop: '20px' }}>
              <MultipleResourceType
                setError={setError}
                setValue={setValue}
                name={'objects'}
                disabled={false}
                renderError={false}
                endpoint={'/api/reservation_events?pagination=false'}
                label={'Wybierz obiekty'}
                titleAccessor={'nativeTitle'}
                fullWidth={true}
                value={selectValue}
              ></MultipleResourceType>
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            startIcon={<SystemUpdateAlt />}
            onClick={handleExportClick}
            style={{ marginTop: '20px', width: '100%' }}
            disabled={isFetching}
          >
            Eksportuj
          </Button>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  )
}

ExportStatisticsModal.propTypes = {
  title: PropTypes.string,
  buttonTitle: PropTypes.string,
  defaultData: PropTypes.object,
  classes: PropTypes.shape({
    content: PropTypes.string,
  }),
  hideSelect: PropTypes.bool,
}
