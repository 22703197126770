import photoSchema from './photo'
import { LANGS_IRI } from '../_lib/langs'
import FIELD_TYPES from '../components/form/_helpers/fieldTypes'
import fileSchema from './media'

const schema = {
  endpoint: '/api/shop_config',
  resource: {
    definition: '#ShopConfig',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          shopOrderAddress: {
            type: FIELD_TYPES.TEXTAREA,
          },
          orderSummarySubtitle: {
            type: FIELD_TYPES.TINYMCE,
            description: 'Podtytuł podsumowania zamówienia',
          },
          shopFooterEmail: {
            type: FIELD_TYPES.STRING,
          },
          shopFooterPhone: {
            type: FIELD_TYPES.STRING,
          },
          consent1: {
            type: FIELD_TYPES.TINYMCE,
            description: 'Treść zgody 1 (proces zakupowy)',
            hint: 'Wyświetla się tylko gdy uzupełniona',
          },
          consent2: {
            type: FIELD_TYPES.TINYMCE,
            description: 'Treść zgody 2 (proces zakupowy)',
            hint: 'Wyświetla się tylko gdy uzupełniona',
          },
          returnDescription: {
            type: FIELD_TYPES.TINYMCE,
            description: 'Treść na przyciskiem zwrotu zamówienia',
          },
          maintenanceText1: {
            description: 'Przerwa techniczna - tytuł',
          },
          maintenanceText2: {
            description: 'Przerwa techniczna - podtytuł',
          },
          maintenanceText3: {
            type: FIELD_TYPES.TINYMCE,
            description: 'Przerwa techniczna - treść',
          },
          maintenanceAlt: {
            description: 'Przerwa techniczna - alt obrazka',
          },
        },
      },
      timeMarkedAsNew: {
        type: 'number',
        description: 'Czas oznaczania produktów jako nowe',
        hint: 'W dniach',
      },
      maintenanceImage: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
        thumbs: {
          endpoint: '/api/shop_config_thumbs',
        },
        handleFileManagerMetadata: (setValue, media) => {
          setValue('injectTranslations', {
            [LANGS_IRI.PL]: {
              alt: media.translations[LANGS_IRI.PL].alt,
            },
            /* todo en version
            [LANGS_IRI.EN]: {
              alt: media.translations[LANGS_IRI.EN].alt,
            }
             */
          })
        },
      },

      randomOrderNumber: {
        description: 'Generuj losowe numery zamówień',
      },
      minimalOrderNumber: {
        description: 'Minimalny numer zamówienia',
        hint: 'Dotyczy wygenerowanego numeru zamówienia',
      },

      shopFreeShippingThresholdFloat: {
        type: FIELD_TYPES.NUMBER,
        description: 'T_SHOP_CONFIG_FREE_SHIPPING_THRESHOLD',
        validate: ['money'],
      },
      elektronicznyNadawcaLogin: {
        type: FIELD_TYPES.STRING,
        description: 'T_SHOP_CONFIG_ELEKTRONICZNY_NADAWCA_LOGIN',
      },
      elektronicznyNadawcaPassword: {
        type: FIELD_TYPES.PASSWORD,
        description: 'T_SHOP_CONFIG_ELEKTRONICZNY_NADAWCA_PASSWORD',
      },
      elektronicznyNadawcaBankAccountNumber: {
        type: FIELD_TYPES.STRING,
        description: 'Numer konta bankowego',
        hint: 'Do przelewów pobraniowych (POCZTA POLSKA)',
      },
      inpostIntegration: {
        type: FIELD_TYPES.HEADER,
        description: 'Integracja z Inpost',
      },
      inpostId: {
        type: FIELD_TYPES.INTEGER,
        description: 'Inpost ID',
      },
      inpostShipxToken: {
        type: FIELD_TYPES.STRING,
        description: 'Inpost token ShipX',
      },
      geoWidgetKey: {
        type: FIELD_TYPES.STRING,
        description: 'Inpost token Geowidget',
        hint: 'Do paczkomatów',
      },
      stanyMagazynowe2: {
        type: FIELD_TYPES.HEADER,
        description: 'Stany magazynowe - detal produktu',
      },
      frontStockLevelMedium: {
        description: 'Stan - "na wyczerpaniu"',
      },
      frontStockLevelLow: {
        description: 'Stan - "ostatnie sztuki"',
      },
      tockNotificationEmail: {
        type: FIELD_TYPES.STRING,
        description: 'Email do powiadomień o brakach w magazynie',
      },
      stockNotificationFirstLevel: {
        description: 'Próg pierwszego powiadomienia',
      },
      stockNotificationSecondLevel: {
        description: 'Próg drugiego powiadomienia',
      },
      dotpayInsteadOfPrzelewy24: {
        description: 'Dotpay zamiast Przelewy24',
      },
      dotpayId: {
        type: FIELD_TYPES.STRING,
        description: 'Id dotpay',
      },
      disabled: {
        description: 'Wyłącz sklep',
      },
      maintenance: {
        description: 'Tryb konserwacji',
      },
    },
  },
  subresources: {
    categories: {
      endpoint: '/api/shop_config_categories',
      definition: '#ShopConfigCategory',
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {},
            description: {
              type: FIELD_TYPES.TINYMCE,
              description: 'T_GENERAL_DESCRIPTION',
            },
            videoDescription: {
              type: 'string',
              label: 'Opis filmu',
            },
            alt: {
              type: FIELD_TYPES.STRING,
              description: 'T_GENERAL_ALT',
            },
          },
        },
        filterType: {
          type: FIELD_TYPES.RESOURCE,
          endpoint: `/api/shop_filter_types?pagination=false`,
          titleAccessor: `trans`,
          transTitle: true,
          label: 'Filtr',
          validate: ['required'],
        },
        media: {
          type: FIELD_TYPES.IMAGE,
          description: 'T_GENERAL_PHOTO',
          endpoint: photoSchema.endpoint.single,
          validate: ['maxSize'],
          thumbs: {
            endpoint: '/api/shop_config_category_thumbs',
          },
          handleFileManagerMetadata: (setValue, media) => {
            setValue('injectTranslations', {
              [LANGS_IRI.PL]: {
                alt: media.translations[LANGS_IRI.PL].alt,
              },
              /* todo en version
              [LANGS_IRI.EN]: {
                alt: media.translations[LANGS_IRI.EN].alt,
              }
               */
            })
          },
        },
        file: {
          type: 'file',
          description: 'T_GENERAL_FILE_VIDEO',
          endpoint: fileSchema.endpoint,
          validate: ['maxSize(20971520)'],
        },
        ytCode: {
          type: 'string',
          description: 'T_GENERAL_YOUTUBE_CODE',
          hint: 'T_GENERAL_YOUTUBE_CODE_INFO',
        },
        hasSound: {
          type: 'boolean',
          description: 'Zawiera dźwięk',
        },
        transcription: {
          type: 'file',
          description: 'Plik video z transkrypcją',
          endpoint: fileSchema.endpoint,
          validate: ['maxSize(20971520)'],
        },
        audioDescription: {
          type: 'file',
          description: 'Plik audio z audiodeskrypcją',
          endpoint: fileSchema.endpoint,
          validate: ['maxSize(20971520)'],
        },
      },
    },
    vat: {
      endpoint: '/api/shop_config_vats',
      definition: '#ShopConfigVat',
      properties: {
        title: {
          type: FIELD_TYPES.STRING,
          description: 'Tytuł',
          validate: ['required'],
        },
        rate: {
          type: FIELD_TYPES.NUMBER,
          description: 'Stawka VAT',
          validate: ['required'],
        },
      },
    },
    alerts: {
      endpoint: '/api/shop_config_alerts',
      definition: '#ShopConfigAlert',
      properties: {
        translations: {
          type: FIELD_TYPES.TRANSLATION,
          properties: {
            title: {
              type: FIELD_TYPES.STRING,
              description: 'T_GENERAL_TITLE',
            },
            description: {
              type: FIELD_TYPES.TEXTAREA,
              description: 'T_GENERAL_DESCRIPTION',
            },
          },
        },
        dateFrom: {
          type: FIELD_TYPES.DATETIME,
          description: 'Data od',
          validate: ['required'],
        },
        dateTo: {
          type: FIELD_TYPES.DATETIME,
          description: 'Data do',
          validate: ['required'],
        },
      },
    },
  },
}

export default schema
