import FIELD_TYPES from '../components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/charts',
  access: 'chart',
  resource: {
    definition: '#Chart',
    properties: {
      workingTitle: {
        type: FIELD_TYPES.STRING,
        description: 'Tytuł',
        validate: ['required'],
      },
    },
  },
}

export default schema
