import FIELD_TYPES from '../components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/guide_languages',
  resource: {
    definition: '#GuideLanguage',
    properties: {
      translations: {
        type: FIELD_TYPES.TRANSLATION,
        properties: {
          title: {},
        },
      },
      isoCode: {
        type: FIELD_TYPES.STRING,
        description: '2 znakowy kod ISO kraju',
        hint:
          'https://www.iso.org/obp/ui/#search/code/',
        validate: ['required'],
      },
      stat: {
        type: FIELD_TYPES.BOOLEAN,
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
}

export default schema
