import React, { useMemo, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import {
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  Checkbox,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import {
  BodySkeleton,
  TableLoader,
  ManuallyOrderableTableBody,
  ManuallyOrderableTableRow,
  ManualOrderDragHandle,
  ManualOrderPageDropzone,
  OrderBy,
  MassSelectChoice,
  MassSelectCheckbox,
  PaginationActions,
  useCollectionTableStyles,
} from '.'
import PropTypes from 'prop-types'
import { DefaultCell } from 'components/table/cells/DefaultCell'
import {
  prepareColumns,
  prepareFilters,
  prepareSorters,
  handleSorter as deafultHandleSorter,
  performManualOrderChange,
  performManualOrderBetweenPageChange,
  addCustomFilters,
  useCollectionFetch,
  usePageDropzoneRef,
} from './_helpers'
import { applyProperties } from '_helpers/applyProperties'
import { translate } from '_helpers/translate'
import { constants, reducer } from './_state'
import { FilterRow } from './FilterRow'
import { FilterBarHorizontal } from './FilterBarHorizontal'
import { isObjectEmpty } from '../../_helpers/isObjectEmpty'

export const CollectionTable = ({
  // schema,
  definitionSchema,
  endpoint,
  customResourceSchema = {},
  columns,
  defaultSorters,
  defaultFilters,
  customFilters = [],
  autoWidth = true,
  isRowLinkable = false,
  perPage = null,
  perPageOptions = null,
  resetable = true,
  paginable = true,
  filterable = true,
  sortable = true,
  orderable = false,
  numerable = true,
  selectable = [],
  selectableComponent = null,
  isManualOrderSorterVisible = true,
  isPaginationVisible = true,
  isRowSelectable = true,
  isRowKeyTimestamped = true,
  prepareResources = null,
  storeCollectionId = null,
  editPath = null,
  transparent = false,
  children = null,
  classes = {},
  parameters = [],
  setItemCount = null,
  reloadCollection = false,
  setReloadCollection = null,
  schemaParameters = [
    {
      name: 'order[ord]',
      in: 'query',
      type: 'string',
    },
  ],
  parentResource = null,
  nestedUuid = null,
  reloadFilters = false,
  setReloadFilters = null,
  horizontalFilterBar = false,
  orderAdditionalActions = [],
  extraQueryParameters = null,
  ...rest
}) => {
  const history = useHistory()

  const preparedColumns = useMemo(
    () => prepareColumns(columns, definitionSchema.properties),
    [columns, definitionSchema.properties]
  )

  const preparedFilters = useMemo(
    () => prepareFilters(defaultFilters, preparedColumns, parameters),
    [defaultFilters, preparedColumns, parameters]
  )

  const filters = useMemo(
    () => addCustomFilters(customFilters, preparedFilters, parameters),
    [customFilters, preparedFilters, parameters]
  )

  const sorters = useMemo(
    () =>
      prepareSorters(
        orderable,
        defaultSorters,
        preparedColumns,
        schemaParameters
      ),
    [orderable, defaultSorters, preparedColumns, schemaParameters]
  )

  // const preparedFilters = useMemo(
  //   () => prepareFilters(defaultFilters, preparedColumns, schema.parameters),
  //   [defaultFilters, preparedColumns, schema.parameters]
  // )

  // const filters = useMemo(
  //   () => addCustomFilters(customFilters, preparedFilters, schema.parameters),
  //   [customFilters, preparedFilters, schema.parameters]
  // )

  // const sorters = useMemo(
  //   () =>
  //     prepareSorters(
  //       orderable,
  //       defaultSorters,
  //       preparedColumns,
  //       schema.parameters
  //     ),
  //   [orderable, defaultSorters, preparedColumns, schema.parameters]
  // )

  const [state, dispatch] = useCollectionFetch(
    reducer,
    endpoint,
    filters,
    sorters,
    perPage,
    paginable,
    prepareResources,
    storeCollectionId,
    extraQueryParameters
  )

  const resourceMergedProperties = useMemo(
    () =>
      applyProperties(
        definitionSchema.properties,
        customResourceSchema.properties
      ),
    [definitionSchema.properties, customResourceSchema.properties]
  )

  const handleFilters = useCallback(
    filters =>
      dispatch({ type: constants.HANDLE_COLUMN_FILTERS, payload: { filters } }),
    [dispatch]
  )

  const handleSorter = useCallback(
    (column, order) => deafultHandleSorter(column, order, orderable, dispatch),
    [dispatch, orderable]
  )

  const {
    previous: pageDropzonePreviousRef,
    next: pageDropzoneNextRef,
    onPrevious: onPageDropzonePreviousRef,
    onNext: onPageDropzoneNextRef,
  } = usePageDropzoneRef()

  const handleManualOrderSorter = useCallback(
    (column, order) =>
      dispatch({
        type: constants.HANDLE_MANUAL_ORDER_SORTER,
        payload: { order },
      }),
    [dispatch]
  )

  const handleShowManualOrderPageDropzone = useCallback(
    () => dispatch({ type: constants.MANUAL_ORDER_PAGE_DROPZONE_SHOW }),
    [dispatch]
  )

  const handleManualOrderChange = useCallback(
    ({ oldIndex, newIndex }, e) => {
      dispatch({ type: constants.MANUAL_ORDER_PAGE_DROPZONE_HIDE })

      if (oldIndex === newIndex) {
        return
      }

      if ([pageDropzonePreviousRef, pageDropzoneNextRef].includes(e.target)) {
        performManualOrderBetweenPageChange(
          state.data.items,
          oldIndex,
          dispatch,
          state.config.sorters.ord?.order,
          pageDropzoneNextRef === e.target ? 'next' : 'previous'
        )
      } else {
        performManualOrderChange(
          state.data.items,
          state.data.selected,
          oldIndex,
          newIndex,
          dispatch
        )
      }
    },
    [
      state.data.selected,
      state.data.items,
      state.config.sorters.ord,
      pageDropzonePreviousRef,
      pageDropzoneNextRef,
      dispatch,
    ]
  )

  const handleReset = useCallback(() => {
    dispatch({ type: constants.RESET })
  }, [dispatch])

  const setPage = useCallback(
    (e, page) =>
      dispatch({ type: constants.SET_PAGE, payload: { page: page + 1 } }),
    [dispatch]
  )

  const setPerPage = useCallback(
    e => {
      const perPage = e.target.value
      dispatch({ type: constants.SET_PER_PAGE, payload: { perPage } })
    },
    [dispatch]
  )

  const setSorters = useCallback(
    sorters => dispatch({ type: constants.SET_SORTERS, payload: { sorters } }),
    [dispatch]
  )

  const setFilters = useCallback(
    filters => dispatch({ type: constants.SET_FILTERS, payload: { filters } }),
    [dispatch]
  )

  const handleSingleSelect = useCallback(
    row => () =>
      dispatch({ type: constants.SET_SINGLE_SELECT, payload: { row } }),
    [dispatch]
  )

  const defaultClasses = useCollectionTableStyles()

  const TableBodyComponent = orderable /*&& state.config.sorters.ord?.order*/
    ? ManuallyOrderableTableBody
    : TableBody

  const tableBodyComponentProps = orderable /*&& state.config.sorters.ord?.order*/
    ? {
        onSortStart: handleShowManualOrderPageDropzone,
        onSortEnd: handleManualOrderChange,
        helperClass: clsx(defaultClasses.draggedRow, classes.draggedRow),
        useDragHandle: true,
      }
    : {}

  const TableRowComponent = orderable /*&& state.config.sorters.ord?.order*/
    ? ManuallyOrderableTableRow
    : TableRow

  const isSelectable = !!(
    (selectable && selectable.length) ||
    selectableComponent
  )

  const columnsAmount =
    preparedColumns.length +
    (numerable && 1) +
    ((isSelectable || (orderable && isManualOrderSorterVisible)) && 1)

  useEffect(() => {
    if (setItemCount) {
      setItemCount(state.data.totalItems)
    }

    if (reloadCollection) {
      setReloadCollection(false)
      dispatch({ type: constants.RESET })
    }

    if (reloadFilters) {
      setReloadFilters(false)
      dispatch({ type: constants.SET_FILTERS, payload: { filters } })
    }
  }, [
    state,
    setItemCount,
    reloadCollection,
    dispatch,
    setReloadCollection,
    reloadFilters,
    setReloadFilters,
    filters,
  ])

  /*
  {filterable && !isObjectEmpty(filters) && !horizontalFilterBar && (
      <FilterBar
        filters={state.config.filters}
        disabled={state.isInit || state.isFetching}
        handleFilters={handleFilters}
      />
    )}
    {filterable && !isObjectEmpty(filters) && horizontalFilterBar && (
      <FilterBarHorizontal
        filters={state.config.filters}
        disabled={state.isInit || state.isFetching}
        handleFilters={handleFilters}
      />
    )}
   */

  return (
    <TableContainer
      className={clsx(defaultClasses.container, classes.container)}
    >
      <TableLoader show={!state.init && state.isFetching} />
      <div className={clsx(defaultClasses.toolbar, classes.toolbar)}>
        {filterable && !isObjectEmpty(filters) && horizontalFilterBar && (
          <FilterBarHorizontal
            filters={state.config.filters}
            disabled={state.isInit || state.isFetching}
            handleFilters={handleFilters}
          />
        )}
      </div>
      {children &&
        children({
          init: state.init,
          isFetching: state.isFetching,
          sorters: state.config.sorters,
          filters: state.config.filters,
          setSorters,
          setFilters,
        })}
      {orderable && (
        <div
          className={clsx(
            defaultClasses.manualOrderContainer,
            classes.manualOrderContainer
          )}
        >
          {isManualOrderSorterVisible && !numerable && sortable && (
            <>
              <span
                className={clsx(
                  defaultClasses.manualOrderContainerTitle,
                  classes.manualOrderContainerTitle
                )}
              >
                {translate('T_GENERAL_MANUAL_ORDER')}
              </span>
              {!state.isInit && !state.isFetching && (
                <OrderBy
                  order={state.config.sorters.ord?.order}
                  column="ord"
                  handleSort={handleManualOrderSorter}
                />
              )}
              {orderAdditionalActions.map((action, i) => action)}
            </>
          )}
          {paginable &&
            state.config.sorters.ord?.order &&
            !state.isInit &&
            !state.isFetching && (
              <ManualOrderPageDropzone
                isFirst={state.data.page === 1}
                isLast={
                  state.data.page ===
                  Math.ceil(state.data.totalItems / state.data.perPage)
                }
                previousPageRef={onPageDropzonePreviousRef}
                nextPageRef={onPageDropzoneNextRef}
                hide={!state.showManualOrderPageDropzone}
              />
            )}
        </div>
      )}
      {isSelectable && !selectableComponent && (
        <MassSelectChoice
          selectable={selectable}
          tableState={state}
          tableStateDispatch={dispatch}
          classes={{
            container: classes.massSelectContainer,
            title: classes.massSelectContainerTitle,
          }}
        />
      )}
      {isSelectable &&
        selectableComponent &&
        React.cloneElement(selectableComponent, {
          tableState: state,
          tableStateDispatch: dispatch,
          classes: {
            container: classes.massSelectContainer,
            title: classes.massSelectContainerTitle,
          },
        })}
      <Table size="small">
        <TableHead
          className={
            transparent ? defaultClasses.tHeadTransparent : defaultClasses.tHead
          }
        >
          <TableRow>
            {(isSelectable || (orderable && isManualOrderSorterVisible)) && (
              <TableCell
                key="header-mass"
                style={{
                  width: (autoWidth ? 100 / columnsAmount : 5) + '%',
                }}
              >
                <div
                  className={clsx(
                    defaultClasses.massHeadColumn,
                    orderable && defaultClasses.massHeadColumnIsOrderable,
                    classes.massHeadColumn
                  )}
                >
                  {isSelectable && (
                    <MassSelectCheckbox
                      tableState={state}
                      tableStateDispatch={dispatch}
                      isRowSelectable={isRowSelectable}
                    />
                  )}
                </div>
              </TableCell>
            )}
            {numerable && (
              <TableCell
                key="header-number"
                style={{
                  width: (autoWidth ? 100 / columnsAmount : 7) + '%',
                }}
              >
                {translate('T_GENERAL_NO')}
                {orderable &&
                  isManualOrderSorterVisible &&
                  !state.isInit &&
                  !state.isFetching && (
                    <OrderBy
                      order={state.config.sorters.ord?.order}
                      column="ord"
                      handleSort={handleManualOrderSorter}
                    />
                  )}
              </TableCell>
            )}
            {preparedColumns.map((column, i) => (
              <TableCell
                key={`header-${i}`}
                style={
                  autoWidth
                    ? { width: 100 / columnsAmount + '%' }
                    : column.width && { width: column.width }
                }
              >
                {column.header}
                {!state.isInit &&
                  !state.isFetching &&
                  sortable &&
                  column.sortable &&
                  (column.sorterName || column.accessor) &&
                  state.config.sorters[
                    column.sorterName || column.accessor
                  ] && (
                    <OrderBy
                      order={
                        state.config.sorters[
                          column.sorterName || column.accessor
                        ].order
                      }
                      column={column.sorterName || column.accessor}
                      handleSort={handleSorter}
                    />
                  )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow className={defaultClasses.tHeadFilterBackground}>
            {(isSelectable || (orderable && isManualOrderSorterVisible)) && (
              <TableCell
                key="header-mass"
                style={{
                  width: (autoWidth ? 100 / columnsAmount : 5) + '%',
                }}
              >
                <div
                  className={clsx(
                    defaultClasses.massHeadColumn,
                    orderable && defaultClasses.massHeadColumnIsOrderable,
                    classes.massHeadColumn
                  )}
                ></div>
              </TableCell>
            )}
            {numerable && (
              <TableCell
                key="header-number"
                style={{
                  width: (autoWidth ? 100 / columnsAmount : 7) + '%',
                }}
              ></TableCell>
            )}
            <FilterRow
              filters={state.config.filters}
              disabled={state.isInit || state.isFetching}
              handleFilters={handleFilters}
              preparedColumns={preparedColumns}
              defaultClasses={defaultClasses}
              autoWidth={autoWidth}
              columnsAmount={columnsAmount}
              resetable={resetable}
              state={state}
              handleReset={handleReset}
            />
          </TableRow>
        </TableHead>
        <TableBodyComponent {...tableBodyComponentProps}>
          {state.init ? (
            <BodySkeleton rows={state.config.perPage} columns={columnsAmount} />
          ) : state.data.items.length > 0 ? (
            state.data.items.map((item, i) => {
              const rowAsLink =
                typeof isRowLinkable === 'boolean'
                  ? isRowLinkable
                  : isRowLinkable(item)

              const handleRowClick = e => {
                if (rowAsLink && e.target.tagName === 'TD') {
                  editPath &&
                    history.push(editPath.replace(':id', item['uuid']))
                }
              }

              return (
                <TableRowComponent
                  onClick={handleRowClick}
                  className={clsx(
                    defaultClasses.row,
                    classes.row,
                    rowAsLink &&
                      clsx(defaultClasses.rowAsLink, classes.rowAsLink)
                  )}
                  index={i}
                  key={`${item['uuid']}${
                    isRowKeyTimestamped ? `-${Date.now()}` : ''
                  }`}
                >
                  {(isSelectable ||
                    (orderable && isManualOrderSorterVisible)) && (
                    <TableCell
                      key="header-mass"
                      style={{
                        width: (autoWidth ? 100 / columnsAmount : 5) + '%',
                      }}
                    >
                      <div
                        className={clsx(
                          defaultClasses.massColumn,
                          classes.massColumn
                        )}
                      >
                        {orderable &&
                        isManualOrderSorterVisible /*&& state.config.sorters.ord?.order*/ && (
                            <ManualOrderDragHandle />
                          )}
                        {isSelectable &&
                          (typeof isRowSelectable === 'boolean'
                            ? isRowSelectable
                            : isRowSelectable(item)) && (
                            <Checkbox
                              checked={!!state.data.selected[i]}
                              onChange={handleSingleSelect(i)}
                              name="mass"
                              color="secondary"
                              className={
                                !orderable ||
                                state.config.sorters.ord?.order === null
                                  ? defaultClasses.massHeadColumnIsOrderable
                                  : null
                              }
                            />
                          )}
                      </div>
                    </TableCell>
                  )}
                  {numerable && (
                    <TableCell
                      key="column-number"
                      style={{
                        width: (autoWidth ? 100 / columnsAmount : 7) + '%',
                      }}
                    >
                      {orderable && state.data.ord === 'asc'
                        ? state.data.totalItems -
                          ((state.data.page - 1) * state.data.perPage + i)
                        : (state.data.page - 1) * state.data.perPage + i + 1}
                    </TableCell>
                  )}
                  {preparedColumns.map((column, j) => {
                    const CellComponent = column.Cell || DefaultCell

                    return (
                      <TableCell
                        className={clsx(defaultClasses.cell, classes.cell)}
                        key={`column-${j}`}
                        style={
                          autoWidth
                            ? { width: 100 / columnsAmount + '%' }
                            : column.width && { width: column.width }
                        }
                      >
                        <CellComponent
                          resource={item}
                          accessor={column.accessor}
                          accessorNested={column.accessorNested}
                          accessors={column.accessors}
                          propertySchema={
                            resourceMergedProperties?.[column.accessor]
                          }
                          tableState={state}
                          tableStateDispatch={dispatch}
                          isRowLinkable={rowAsLink}
                          editPath={editPath}
                          orderable={orderable}
                          parentResource={parentResource}
                          nestedUuid={nestedUuid}
                          translateAccessor={column?.translateAccessor}
                          customEndpoint={column?.customEndpoint}
                          routes={column?.routes}
                          {...rest}
                        />
                      </TableCell>
                    )
                  })}
                </TableRowComponent>
              )
            })
          ) : (
            <TableRow>
              <TableCell
                colSpan={columnsAmount}
                className={clsx(
                  defaultClasses.emptyResults,
                  classes.emptyResults
                )}
              >
                {translate('T_TABLE_NO_RESULTS')}
              </TableCell>
            </TableRow>
          )}
        </TableBodyComponent>
        {paginable && isPaginationVisible && (
          <TableFooter>
            <TableRow>
              <TablePagination
                page={state.init ? 0 : state.data.page - 1}
                count={state.data.totalItems}
                onPageChange={setPage}
                onRowsPerPageChange={setPerPage}
                rowsPerPage={state.data.perPage}
                rowsPerPageOptions={
                  perPageOptions ||
                  process.env.REACT_APP_COLLECTION_PER_PAGE_OPTIONS.split(
                    ','
                  ).map(option => parseInt(option.trim()))
                }
                ActionsComponent={PaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}

CollectionTable.propTypes = {
  /*schema: PropTypes.shape({
      parameters: PropTypes.array,
    }).isRequired,*/
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object,
  }).isRequired,
  endpoint: PropTypes.string.isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object,
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      accessor: PropTypes.string,
      sortable: PropTypes.bool,
      filterable: PropTypes.bool,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      Filter: PropTypes.elementType,
      Cell: PropTypes.elementType,
    })
  ),
  defaultSorters: PropTypes.objectOf(PropTypes.oneOf(['asc', 'desc'])),
  defaultFilters: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
      PropTypes.object,
    ])
  ),
  customFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  autoWidth: PropTypes.bool,
  isRowLinkable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  perPage: PropTypes.number,
  perPageOptions: PropTypes.arrayOf(PropTypes.number),
  resetable: PropTypes.bool,
  paginable: PropTypes.bool,
  filterable: PropTypes.bool,
  sortable: PropTypes.bool,
  orderable: PropTypes.bool,
  numerable: PropTypes.bool,
  selectable: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string,
      title: PropTypes.string,
      execute: PropTypes.func,
    })
  ),
  isManualOrderSorterVisible: PropTypes.bool,
  isPaginationVisible: PropTypes.bool,
  isRowSelectable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  isRowKeyTimestamped: PropTypes.bool,
  prepareResources: PropTypes.func,
  storeCollectionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.func,
  editPath: PropTypes.string,
  transparent: PropTypes.bool,
  setItemCount: PropTypes.func,
  reloadCollection: PropTypes.bool,
  setReloadCollection: PropTypes.func,
  classes: PropTypes.shape({
    container: PropTypes.string,
    toolbar: PropTypes.string,
    row: PropTypes.string,
    rowAsLink: PropTypes.string,
    cell: PropTypes.string,
    emptyResults: PropTypes.string,
    manualOrderContainer: PropTypes.string,
    manualOrderContainerTitle: PropTypes.string,
    draggedRow: PropTypes.string,
    massSelectContainer: PropTypes.string,
    massSelectContainerTitle: PropTypes.string,
    massHeadColumn: PropTypes.string,
    massColumn: PropTypes.string,
  }),
  reloadFilters: PropTypes.bool,
  setReloadFilters: PropTypes.func,
  horizontalFilterBar: PropTypes.bool,
  orderAdditionalActions: PropTypes.array,
  extraQueryParameters: PropTypes.object,
}
