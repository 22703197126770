import FIELD_TYPES from '../components/form/_helpers/fieldTypes'

const schema = {
  endpoint: '/api/reservation_event_dates',
  access: 'reservationEvent',
  resource: {
    definition: '#ReservationEventDate',
    properties: {
      numberOfParticipants: {
        type: FIELD_TYPES.INTEGER,
        description: 'Maksymalna liczba uczestników',
        hint:
          'Gdy pole jest puste, wartość jest pobierana z ustawień globalnych',
      },
      startDate: {
        type: FIELD_TYPES.DATETIME,
        description: 'Data',
      },
      hoursCanCancelBeforeStart: {
        type: FIELD_TYPES.INTEGER,
        description: 'Czas do kiedy można anulować rezerwację',
        hint: 'W godzinach (nadpisuje ustawienia globalne)',
      },
      /*
      notificationEmails: {
        //type: FIELD_TYPES.ARRAY, todo add new type
        type: FIELD_TYPES.STRING,
        description: 'Adresy email do powiadomień',
      },*/
      stat: {
        description: 'T_GENERAL_PUBLICATION',
      },
    },
  },
  subresources: {
    reservations: {
      endpoint: '/api/reservation_event_reservations',
      definition: '#ReservationEventReservation',
      properties: {
        firstName: {
          type: FIELD_TYPES.STRING,
          description: 'Imię',
        },
        lastName: {
          type: FIELD_TYPES.STRING,
          description: 'Nazwisko',
        },
        email: {
          type: FIELD_TYPES.STRING,
          description: 'Email',
        },
        phone: {
          type: FIELD_TYPES.STRING,
          description: 'Telefon',
        },
        phone2: {
          type: FIELD_TYPES.STRING,
          description: 'Numer telefonu opiekuna',
        },
        numberOfParticipants: {
          type: FIELD_TYPES.INTEGER,
          description: 'Liczba uczestników',
        },
        canceled: {
          type: FIELD_TYPES.BOOLEAN,
          description: 'Anulowano',
        },
        notes: {
          type: FIELD_TYPES.TEXTAREA,
          description: 'Uwagi administratora',
          fullWidth: true,
        },
        activatedAt: {
          type: FIELD_TYPES.DATETIME,
          label: 'Data aktywacji za pomocą aplikacji',
        },
      },
    },
  },
}

export default schema
