import React, { useState } from 'react'
import { Form } from 'pages'
import schema from '_schema/matterFormRequest'
import schemaPermission from '_schema/matterFormPermission'
import routes from './routes'
import routesPermission from '../MatterFormPermission/routes'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
  Add,
  AssignmentTurnedIn,
  Backspace,
  DescriptionOutlined, Print,
} from '@material-ui/icons'
import { translate } from '../../_helpers/translate'
import { EmbeddedCollection } from '../../components/embedded'
import { prop } from '../../_helpers/prop'
import { fetchDataHandleAuthError } from '../../_helpers/fetchDataHandleAuthError'
import { notification } from '../../_helpers/notification'
import { useHistory } from 'react-router-dom'
import {getApiUrl} from "../../_services/apiUrlProvider";
import {authHeader} from "../../_helpers/authHeader";

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const useStyles = makeStyles(theme => ({
    container: {
      padding: theme.spacing(4),
      backgroundColor: 'transparent',
    },
    button: {
      margin: theme.spacing(1),
    },
  }))

  const classes = useStyles()

  const [parentResource, setParentResource] = useState(null)

  const [status, setStatus] = useState(prop(parentResource, 'status'))

  const history = useHistory()

  const changeStatus = newStatus => {
    setStatus(newStatus)
    fetchDataHandleAuthError(
      parentResource['@id'],
      'PUT',
      {
        body: JSON.stringify({
          status: newStatus,
        }),
      },
      resource => {
        notification('success', 'T_GENERAL_STATUS_CHANGED', 'T_FORM_SUCCESS')
        setParentResource(resource)
      },
      error => {
        setStatus(status)
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const generatePermission = () => {
    fetchDataHandleAuthError(
      schemaPermission.endpointGenerate,
      'POST',
      {
        body: JSON.stringify({
          uuid: parentResource['uuid'],
        }),
      },
      resource => {
        notification('success', 'T_GENERAL_STATUS_CHANGED', 'T_FORM_SUCCESS')
        history.push(routesPermission().edit.path.replace(':id', resource.uuid))
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const rejectRequest = () => {
    changeStatus('api/matter_form_statuses/REJECTED')
  }

  const acceptRequest = () => {
    changeStatus('api/matter_form_statuses/ACCEPTED')
  }

  const redirectToPermission = () => {
    if (parentResource && parentResource['permissions'][0]) {
      history.push(
        routesPermission().edit.path.replace(
          ':id',
          parentResource['permissions'][0].uuid
        )
      )
    }
  }

  function getfiledownload() {
    let xtitle = null
    fetch(`${getApiUrl()}${schema.endpointPdf}`, {
      method: 'POST',
      headers: {
        ...authHeader(),
      },
      body: JSON.stringify({ uuid: parentResource?.uuid }),
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Error')
        }
        xtitle = response.headers.get('x-title')
        return response.blob()
      })
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', xtitle ?? 'plik')

        // Append to html link element page
        document.body.appendChild(link)

        // Start download
        link.click()

        // Clean up and remove the link
        link.parentNode.removeChild(link)
      })
      .catch(e => {
        notification('error', 'Error, nie można pobrać', e)
      })
  }

  return (
    <div>
      <div className={classes.container}>
        {parentResource && [0, 1, 2, 3].includes(parentResource?.status.value) && (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<AssignmentTurnedIn />}
            onClick={acceptRequest}
          >
            Akceptuj wniosek
          </Button>
        )}
        {parentResource && [0, 1, 2, 3].includes(parentResource?.status.value) && (
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<Backspace />}
            onClick={rejectRequest}
          >
            Odrzuć wniosek
          </Button>
        )}
        {parentResource && parentResource?.permissions.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<DescriptionOutlined />}
            onClick={redirectToPermission}
          >
            Zezwolenia
          </Button>
        )}
        {parentResource &&
          parentResource?.status.value === 4 &&
          parentResource?.permissions.length === 0 && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<Add />}
              onClick={generatePermission}
            >
              Dodaj zezwolenie
            </Button>
          )}
        <Button
          variant="contained"
          color="primary"
          startIcon={<Print />}
          onClick={() => {
            getfiledownload()
          }}
        >
          Drukuj
        </Button>
      </div>
      <Form
        definitionSchema={definitions[schema.resource.definition]}
        definitions={definitions}
        customResourceSchema={schema.resource}
        iri={iri}
        method="PUT"
        url={iri}
        collectionPath={routes().index.path}
        storeCollectionId={schema.endpoint}
        sidebar={true}
        sidebarStatAccessor={''}
        width={600}
        setParentResource={setParentResource}
        modifyProperties={(properties, resource) => {
          const resourceTypeMappings = {
            car: schema.carProperties.properties,
            move: schema.moveProperties.properties,
            session_wedding: schema.sessionWeddingProperties.properties,
            wedding: schema.weddingProperties.properties,
            car_dw: schema.carDwProperties.properties,
            car_dmo: schema.carDwProperties.properties,
            photo: schema.photoProperties.properties,
            movie: schema.movieProperties.properties,
            sport: schema.sportProperties.properties,
          }

          const resourceType = resource?.type.value
          if (
            resourceType &&
            resourceTypeMappings.hasOwnProperty(resourceType)
          ) {
            if (resourceType !== 'sport') {
              let out = { ...properties, ...resourceTypeMappings[resourceType] }
              if (
                resourceType === 'wedding' ||
                resourceType === 'car_dmo' ||
                resourceType === 'car_dw'
              ) {
                delete out.dateTo
              }
              return out
            } else {
              return { ...resourceTypeMappings[resourceType] }
            }
          }

          return properties
        }}
      >
        {parentResource?.type?.value === 'sport' && (
          <EmbeddedCollection
            endpoint={`${schema.subresources.runs.endpoint}?iri[parent]=${iri}`}
            pid={match.params.id}
            parentIri={iri}
            properties={schema.subresources.runs.properties}
            definitionSchema={definitions[schema.subresources.runs.definition]}
            headerTitle={translate('Biegi')}
            panelTitle={translate('Biegi')}
            titleAccessor={`media.originalName`}
            statable={false}
            orderable={false}
          />
        )}
        <div style={{ marginTop: '20px' }}>
          <EmbeddedCollection
            endpoint={`${schema.subresources.files.endpoint}?iri[parent]=${iri}`}
            pid={match.params.id}
            parentIri={iri}
            properties={schema.subresources.files.properties}
            definitionSchema={definitions[schema.subresources.files.definition]}
            headerTitle={translate('Pliki')}
            panelTitle={translate('Pliki')}
            titleAccessor={`media.originalName`}
            statable={false}
            orderable={false}
          />
        </div>
      </Form>
    </div>
  )
}
