import React, { useEffect, useState } from 'react'
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core'
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { fetchDataHandleAuthError } from '../../../_helpers/fetchDataHandleAuthError'
import { notification } from '../../../_helpers/notification'

export const ArrayType = ({
  endpoint = null,
  name,
  label,
  hint,
  initialValue,
  compareValue,
  compare,
  disabled,
  form,
  value,
  setValue,
}) => {
  const sensors = useSensors(useSensor(PointerSensor))
  const [choices, setChoices] = useState([])

  const handleDragEnd = event => {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = value.findIndex(item => item === active.id)
      const newIndex = value.findIndex(item => item === over.id)
      setValue(name, arrayMove(value, oldIndex, newIndex))
    }
  }

  useEffect(() => {
    if (!endpoint) {
      return
    }

    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      endpoint,
      'GET',
      { signal },
      response => {
        setChoices(response['hydra:member'])
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [endpoint, setChoices])

  useEffect(() => {
    const productIris = form.products.map(product =>
      typeof product === 'object' ? product['@id'] : product
    )

    const updatedValue = value.filter(uuid => productIris.includes(uuid))

    productIris.forEach(uuid => {
      if (!updatedValue.includes(uuid)) {
        updatedValue.push(uuid)
      }
    })

    if (JSON.stringify(value) !== JSON.stringify(updatedValue)) {
      setValue(name, updatedValue)
    }
  }, [form.products, name, setValue, value])

  if (!value || value.length === 0) {
    return null
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <Typography variant="subtitle1" component="h6">
        {label}
      </Typography>
      <SortableContext
        items={value}
        strategy={verticalListSortingStrategy}
        disabled={disabled}
      >
        <List component={Paper}>
          {value.map(item => (
            <SortableItem key={item} item={item} products={choices} />
          ))}
        </List>
      </SortableContext>
    </DndContext>
  )
}

const SortableItem = ({ item, products }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: item })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: 'flex',
    alignItems: 'center',
  }

  return (
    <ListItem
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      divider
    >
      <IconButton {...attributes} {...listeners} edge="start">
        <DragIndicatorIcon />
      </IconButton>
      <ListItemText
        primary={products.find(product => product['@id'] === item)?.nativeTitle}
      />
    </ListItem>
  )
}
