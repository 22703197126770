import TYPES from '../components/form/_helpers/fieldTypes'

const inpostLockerFormat = {
  small: 'Gabaryt A (8 x 38 x 64 cm) 25kg',
  medium: 'Gabaryt B (19 x 38 x 64 cm) 25kg',
  large: 'Gabaryt C (41 x 38 x 64 cm) 25kg',
}

const inpostSendingMethod = {
  dispatch_order: 'Odbiór przez kuriera',
  parcel_locker: 'Nadanie w paczkomacie',
}

const schema = {
  endpoint: '/api/shop_inpost_add_shipment',
  endpointPdf: '/api/shop_inpost_print_label',
  endpointMultiPdf: '/api/shop_inpost_print_label_multiple',
  resource: {
    properties: {
      type: {
        type: TYPES.ELEKTRONICZNY_NADAWCA_RESOURCE,
        endpoint: `/api/inpost_package_types?pagination=false`,
        titleAccessor: `trans`,
        transTitle: true,
        label: 'Typ przesyłki',
        validate: ['required'],
      },
    },
  },
  INPOST_KURIER: {
    sending_method: {
      type: TYPES.CHOICE,
      description: 'Sposób nadania',
      choices: inpostSendingMethod,
      validate: ['required'],
    },
    dropoff_point: {
      type: TYPES.STRING,
      description: 'Id paczkomatu',
      hint: 'Tylko dla nadania w paczkomacie',
    },
    insurance: {
      type: TYPES.FLOAT,
      description: 'Kwota ubezpieczenia',
      validate: ['required'],
    },
    template: {
      type: TYPES.CHOICE,
      description: 'Gabaryt',
      choices: inpostLockerFormat,
      hint: 'Tylko gdy nie podano wymiarów paczki poniżej!',
    },
    height: {
      type: TYPES.INTEGER,
      description: 'Wysokość (mm)',
    },
    width: {
      type: TYPES.INTEGER,
      description: 'Szerokość (mm)',
    },
    length: {
      type: TYPES.INTEGER,
      description: 'Długość (mm)',
    },
    weight: {
      type: TYPES.FLOAT,
      description: 'Waga (kg)',
    },
  },
  INPOST_PACZKOMATY: {
    template: {
      type: TYPES.CHOICE,
      description: 'Gabaryt',
      choices: inpostLockerFormat,
      validate: ['required'],
    },
    sending_method: {
      type: TYPES.CHOICE,
      description: 'Sposób nadania',
      choices: inpostSendingMethod,
      validate: ['required'],
    },
    dropoff_point: {
      type: TYPES.STRING,
      description: 'Id paczkomatu',
      hint: 'Tylko dla nadania w paczkomacie',
    },
    insurance: {
      type: TYPES.FLOAT,
      description: 'Kwota ubezpieczenia',
    },
  },
}

export default schema
