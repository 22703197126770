import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { translate } from '_helpers/translate'
import schema from '_schema/shopOrder'
import customerSchema from '_schema/customer'
import routes from './routes'
import { orderOperations } from './table/columns/orderOperations'
import { DatetimeCell } from '../../components/table/cells/DatetimeCell'
import { StringFilter } from '../../components/table/filters/StringFilter'
import { elektronicznyNadawcaXls } from './table/_mass/'
import { PriceCell } from '../../components/table/cells/PriceCell'
import { ResourceFilter } from '../../components/table/filters/ResourceFilter'
import { exportselected } from './table/_mass/exportselected'
import { StatusCell } from './table/cells/StatusCell'
import { ShippingCell } from './table/cells/ShippingCell'
import { status } from '../../components/table/_columns/status'
import { ChoiceFilter } from '../../components/table/filters/ChoiceFilter'
import { InvoiceCell } from './table/cells/InvoiceCell'
import { MassSelectChoiceShopOrder } from '../../components/table/MassSelectChoiceShopOrder'
import CheckboxWithLabel from '../../components/table/filters/CheckboxWithLabelFilter'

export const Collection = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  /*
  const parentIri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])
   */
  let customerIri = null

  const [reloadFilters, setReloadFilters] = useState(!!match.params.id)
  const [onlySubscriptions, setOnlySubscriptions] = useState(null)

  const handleOnlySubscriptions = value => {
    setOnlySubscriptions(value ? true : null)
    setReloadFilters(true)
  }

  let endpoint = schema.endpoint
  let newFilters = {}

  if (match.params.id) {
    newFilters = {
      'iri[customer]': `${customerSchema.endpoint}/${match.params.id}`,
    }
    customerIri = `${customerSchema.endpoint}/${match.params.id}`
    endpoint = schema.endpointCustomer
  } else {
    newFilters = {
      'iri[customer]': null,
    }
  }

  const customFilters = [
    {
      name: 'firstName',
      title: 'Imię',
      Filter: StringFilter,
    },
    {
      name: 'lastName',
      title: 'Nazwisko',
      Filter: StringFilter,
    },
    {
      name: 'totalPrice[gte]',
      title: 'Cena od',
      Filter: StringFilter,
    },
    {
      name: 'totalPrice[lte]',
      title: 'Cena do',
      Filter: StringFilter,
    },
    {
      name: 'iri[status]',
      title: 'Status',
      Filter: ResourceFilter,
      endpoint: '/api/shop_order_statuses_enum?pagination=false',
      titleAccessor: 'trans',
    },
    {
      name: 'iri[newStatus]',
      title: 'Nowy status',
      Filter: ResourceFilter,
      endpoint: '/api/shop_order_statuses?pagination=false',
      titleAccessor: 'title',
    },
    {
      name: 'iri[legacyStatus]',
      title: 'Status(IMPORT)',
      Filter: ResourceFilter,
      endpoint: '/api/shop_legacy_order_statuses?pagination=false',
      titleAccessor: 'trans',
    },
    {
      name: 'exists[subscriptions.shopOrder]',
      title: 'Status',
      value: onlySubscriptions,
    },
  ]

  //setDefaultFilters(newFilters)

  /*
  useEffect(() => {
    if (onlySubscriptions) {
      newFilters['subscriptions.uuid'] = true
    } else {
      delete newFilters['subscriptions.uuid']
    }

    setDefaultFilters(newFilters)
  }, [onlySubscriptions])
   */

  const columns = useMemo(
    () => [
      {
        columnId: 'orderId',
        header: translate('Numer zamówienia'),
        accessor: `orderId`,
        filterable: true,
        sortable: true,
        width: '8%',
      },
      {
        columnId: 'fullName',
        filtersList: ['firstName', 'lastName'],
        header: translate('Zamawiający'),
        accessor: `fullName`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'email',
        header: translate('Email'),
        accessor: `email`,
        filterable: true,
        sortable: true,
        width: '8%',
      },
      {
        columnId: 'shippingTitle',
        header: translate('Metoda wysyłki'),
        accessor: `shippingTitle`,
        filterName: 'shippingTitle',
        filterable: true,
        sortable: true,
        Cell: ShippingCell,
        width: '10%',
      },
      {
        columnId: 'countryTitle',
        header: translate('Kraj wysyłki'),
        accessor: `countryTitle`,
        filterName: 'countryTitle',
        filterable: true,
        sortable: true,
        width: '5%',
      },
      {
        columnId: 'totalPrice',
        filtersList: ['totalPrice[gte]', 'totalPrice[lte]'],
        header: translate('Łączna kwota'),
        accessor: `totalPrice`,
        filterable: true,
        sortable: true,
        width: '10%',
        Cell: PriceCell,
      },
      {
        columnId: 'currentStatus',
        filtersList: ['iri[newStatus]'],
        header: translate('Status'),
        accessor: `newStatus.title`,
        translateAccessor: true,
        sorterName: 'newStatus.title',
        filterable: true,
        sortable: true,
        width: '10%',
        Cell: StatusCell,
      },
      /*
      {
        columnId: 'newStatus',
        filtersList: ['iri[newStatus]'],
        header: translate('NOWY STATUS(TEST)'),
        accessor: `newStatus.title`,
        translateAccessor: true,
        sorterName: 'newStatus.title',
        filterable: true,
        sortable: true,
        width: '10%',
      },*/
      {
        columnId: 'wantFv',
        ...status('wantFv'),
        header: translate('Faktura'),
        filterable: true,
        filterName: 'wantFv',
        Filter: ChoiceFilter,
        choices: {
          true: 'Tak',
          false: 'Nie',
        },
        width: '7%',
        Cell: InvoiceCell,
      },
      {
        columnId: 'createdAt',
        header: translate('Data utworzenia'),
        accessor: `createdAt`,
        filterable: true,
        sortable: true,
        width: '10%',
        Cell: DatetimeCell,
      },
      {
        columnId: 'operations',
        ...orderOperations(`fullName`, true, true, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_SHOP_ORDER_NEW_BUTTON')}
        buttonPath={routes().new.path}
        hideButton={false}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
      >
        {translate('T_MODULE_SHOP_ORDER')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${endpoint}.get`}
        endpoint={endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ createdAt: 'desc' }}
        defaultFilters={newFilters}
        orderable={true}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        numerable={false}
        isManualOrderSorterVisible={true}
        orderAdditionalActions={[
          <CheckboxWithLabel
            key="onlySubscriptions"
            label="Pokaż tylko prenumeraty"
            onChange={handleOnlySubscriptions}
            style={{ marginLeft: '10px' }}
          />,
        ]}
        selectable={[
          elektronicznyNadawcaXls.generateXls('generateXls', 'orderUuid'),
          exportselected.go(),
        ]}
        selectableComponent={
          <MassSelectChoiceShopOrder
            selectable={[
              elektronicznyNadawcaXls.generateXls('generateXls', 'orderUuid'),
              exportselected.go(),
            ]}
          />
        }
        parameters={[
          {
            name: 'orderId',
            in: 'query',
            type: 'string',
          },
          {
            name: 'firstName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'lastName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'createdAt',
            in: 'query',
            type: 'string',
          },
          {
            name: 'email',
            in: 'query',
            type: 'string',
          },
          {
            name: 'shippingType.translations.title',
            in: 'query',
            type: 'string',
          },
          {
            name: 'iri[customer]',
            in: 'query',
            type: 'string',
            value: customerIri,
          },
          {
            name: 'iri[status]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'iri[legacyStatus]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'iri[newStatus]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'totalPrice[gte]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'totalPrice[lte]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'shippingTitle',
            in: 'query',
            type: 'string',
          },
          {
            name: 'countryTitle',
            in: 'query',
            type: 'string',
          },
          {
            name: 'wantFv',
            in: 'query',
            type: 'string',
          },
          {
            name: 'exists[subscriptions.shopOrder]',
            in: 'query',
            required: false,
            type: 'boolean',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[email]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[createdAt]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[status]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[newStatus.title]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[legacyStatus]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[totalPrice]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[orderId]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[shippingTitle]',
            in: 'query',
            type: 'string',
          },
        ]}
        customFilters={customFilters}
        reloadFilters={reloadFilters}
        setReloadFilters={setReloadFilters}
      />
    </Paper>
  )
}
