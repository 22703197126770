import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { prop } from '../../../_helpers/prop'
import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { constants } from '../../../components/table/_state'
import { notification } from '../../../_helpers/notification'
import { authHeader } from '../../../_helpers/authHeader'
import { getApiUrl } from '../../../_services/apiUrlProvider'

const useStyles = makeStyles(theme => ({
  canceledChip: {
    width: '100%',
  },
  activeContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  activeChip: {
    backgroundColor: '#a7cca7',
  },
}))

const CancelReservationButton = ({
  resource,
  accessor,
  reservationId,
  tableState,
  tableStateDispatch,
}) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'success',
  })

  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSuccess = value => {
    tableStateDispatch({
      type: constants.UPDATE_ITEM_FIELD,
      payload: {
        '@id': resource['@id'],
        accessor,
        value,
      },
    })
  }

  const handleCancelReservation = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${getApiUrl()}${resource?.['@id']}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(),
        },
        body: JSON.stringify({ canceled: true }),
      })

      if (!response.ok) {
        throw new Error('Failed to cancel reservation')
      }

      const json = await response.json()
      onSuccess(json.canceled)

      notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')
    } catch (error) {
      notification('error', 'T_FORM_ERROR', 'T_FORM_ERROR')
    } finally {
      setLoading(false)
      setOpen(false)
    }
  }

  const handleAlertClose = () => {
    setAlert({ ...alert, open: false })
  }

  return (
    <React.Fragment>
      {resource && prop(resource, accessor) && (
        <Chip size="small" label="Anulowana" className={classes.canceledChip} />
      )}
      {resource && !prop(resource, accessor) && (
        <div className={classes.activeContainer}>
          <Chip size="small" label="Aktywna" className={classes.activeChip} />
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleClickOpen}
          >
            Anuluj
          </Button>
        </div>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Anulować rezerwację?'}
        </DialogTitle>
        <DialogContent id="alert-dialog-description">
          <div>Na pewno chcesz anulować tą rezerwację?</div>
          <div>
            <b>{resource?.fullName}</b>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Zamknij
          </Button>
          <Button
            onClick={handleCancelReservation}
            color="secondary"
            autoFocus
            disabled={loading}
          >
            {loading ? 'Anulowanie w trakcie...' : 'Tal, anuluj rezerwację'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  )
}

export default CancelReservationButton
