import React, { useState } from 'react'
import { Menu, MenuItem, IconButton, Chip } from '@material-ui/core'
import { MoreVertOutlined, Print } from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/styles'
import { useSelector, useDispatch } from 'react-redux'
import { EditLink } from 'components/resource/EditLink'
import { DeleteCell } from 'components/table/cells/DeleteCell'
import { StatusCell } from 'components/table/cells/StatusCell'
import { Timestamp } from 'components/resource/Timestamp'
import { translate } from '_helpers/translate'
import { commonConstants } from '_constants'
import { LANGS_IRI } from '_lib/langs'
import clsx from 'clsx'
import { notification } from '../../../../_helpers/notification'
import { getApiUrl } from '../../../../_services/apiUrlProvider'
import schemaElektronicznyNadawca from '../../../../_schema/shopElektronicznyNadawcaAddShipment'
import schemaInpost from '../../../../_schema/shopInpostAddShipment'
import { authHeader } from '../../../../_helpers/authHeader'
import { ElektronicznyNadawcaLabelModal } from '../../Modal/ElektronicznyNadawcaLabelModal'
import { ElektronicznyNadawcaLabelRemoveModal } from '../../Modal/ElektronicznyNadawcaLabelRemoveModal'
import { InpostLabelModal } from '../../Modal/InpostLabelModal'
import {InpostLabelRemoveModal} from "../../Modal/InpostLabelRemoveModal";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    fontSize: 14,
    '& svg': {
      fontSize: 20,
    },
  },
  listItem: {
    minWidth: 32,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  menuTrigger: {
    borderRadius: 4,
    padding: 7,
  },
  menuTriggerOpen: {
    border: '1px solid #d3d4d5',
    borderBottomColor: '#fff',
    backgroundColor: '#fff',

    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
})

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',

    borderTopRightRadius: 0,
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

export const orderOperations = (
  accessor = `translations.${
    LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
  }.title`,
  editable = true,
  deletable = true,
  statable = true,
  timestampable = true,
  cloneEndpoint = null
) => ({
  header: translate('T_GENERAL_OPERATIONS'),
  accessor,
  sortable: false,
  filterable: false,
  Cell: ({
    resource,
    accessor,
    tableState,
    tableStateDispatch,
    editPath = null,
  }) => {
    const classes = useStyles()
    const [menuAnchor, setMenuAnchor] = useState(null)

    const handleMenu = e => {
      setMenuAnchor(e.currentTarget)
    }

    const handleClose = () => {
      setMenuAnchor(null)
    }

    const profile = useSelector(state => state.profile)

    const dispatch = useDispatch()

    const onSuccess = () => {
      dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })
    }

    const showMenu = editable || !!cloneEndpoint || deletable

    function downloadLabel(endpoint) {
      let xtitle = null
      fetch(`${getApiUrl()}${endpoint}`, {
        method: 'POST',
        headers: {
          ...authHeader(),
        },
        body: JSON.stringify({ order: resource['@id'] }),
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error('Error')
          }
          xtitle = response.headers.get('x-title')
          return response.blob()
        })
        .then(blob => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', xtitle ?? 'plik')

          // Append to html link element page
          document.body.appendChild(link)

          // Start download
          link.click()

          // Clean up and remove the link
          link.parentNode.removeChild(link)
        })
        .catch(e => {
          notification('error', 'Error, nie można pobrać', e)
        })
    }

    const defaultData = { order: resource['@id'] }
    const isElektronicznyNadawca = name => {
      return name === 'POCZTA_POLSKA' || name === 'POCZTA_POLSKA_PICKUP_POINT'
    }

    const isInpost = name => {
      return name === 'INPOST_PACZKOMATY' || name === 'INPOST_KURIER'
    }

    return (
      <>
        <div className={classes.root}>
          {isElektronicznyNadawca(resource?.shippingOperator?.name) &&
            !resource?.elektronicznyNadawcaLabelGuid && (
              <ElektronicznyNadawcaLabelModal
                onClick={() => {
                  downloadLabel(schemaElektronicznyNadawca.endpointPdf)
                }}
                defaultData={defaultData}
                tableState={tableState}
                tableStateDispatch={tableStateDispatch}
                resource={resource}
              ></ElektronicznyNadawcaLabelModal>
            )}
          {isElektronicznyNadawca(resource?.shippingOperator?.name) &&
            resource?.elektronicznyNadawcaLabelGuid && (
              <ElektronicznyNadawcaLabelRemoveModal
                resource={resource}
                tableState={tableState}
                tableStateDispatch={tableStateDispatch}
              ></ElektronicznyNadawcaLabelRemoveModal>
            )}
          {isElektronicznyNadawca(resource?.shippingOperator?.name) &&
            resource?.elektronicznyNadawcaLabelGuid && (
              <IconButton
                onClick={() => {
                  downloadLabel(schemaElektronicznyNadawca.endpointPdf)
                }}
              >
                <Print color={'secondary'} />
              </IconButton>
            )}
          {isInpost(resource?.shippingOperator?.name) &&
            (!resource?.inpostShipmentStatus ||
              resource?.inpostShipmentStatus === 'error') && (
              <InpostLabelModal
                onClick={() => {
                  downloadLabel(schemaInpost.endpointPdf)
                }}
                defaultData={defaultData}
                tableState={tableState}
                tableStateDispatch={tableStateDispatch}
                resource={resource}
              ></InpostLabelModal>
            )}
          {isInpost(resource?.shippingOperator?.name) &&
            resource?.inpostShipmentStatus &&
            resource?.inpostShipmentStatus !== 'processing' && (
              <>
                <InpostLabelRemoveModal
                  resource={resource}
                  tableState={tableState}
                  tableStateDispatch={tableStateDispatch}
                ></InpostLabelRemoveModal>
                <IconButton
                  onClick={() => {
                    downloadLabel(schemaInpost.endpointPdf)
                  }}
                >
                  <Print color={'secondary'} />
                </IconButton>
              </>
            )}
          {isInpost(resource?.shippingOperator?.name) &&
            resource?.inpostShipmentStatus === 'processing' && (
              <Chip label="Przetwarzanie etykiety" color="secondary" />
            )}
          {isInpost(resource?.shippingOperator?.name) &&
            resource?.inpostShipmentStatus === 'error' && (
              <Chip
                label="Przetwarzanie etykiety"
                color="secondary"
                style={{ backgroundColor: 'red' }}
              />
            )}
          {showMenu && (
            <>
              <IconButton
                aria-label="record menu"
                aria-controls="record-menu"
                aria-haspopup="true"
                onClick={handleMenu}
                className={clsx(
                  classes.menuTrigger,
                  menuAnchor && classes.menuTriggerOpen
                )}
              >
                <MoreVertOutlined color="inherit" />
              </IconButton>
              <StyledMenu
                id="record-menu"
                anchorEl={menuAnchor}
                keepMounted
                open={!!menuAnchor}
                onClose={handleClose}
              >
                {editable && (
                  <MenuItem className={classes.menuItem}>
                    <EditLink
                      resource={resource}
                      accessor={accessor}
                      disabled={!profile?.openform && !resource.isEditable}
                      editPath={editPath}
                    />
                  </MenuItem>
                )}
                {deletable && (
                  <MenuItem className={classes.menuItem}>
                    <DeleteCell
                      resource={resource}
                      accessor={accessor}
                      disabled={!profile?.openform && !resource.isDeletable}
                      onSuccess={onSuccess}
                      tableState={tableState}
                      tableStateDispatch={tableStateDispatch}
                    />
                  </MenuItem>
                )}
              </StyledMenu>
            </>
          )}
          {statable && (
            <StatusCell
              resource={resource}
              disabled={!profile?.openform && !resource.isStatable}
              tableStateDispatch={tableStateDispatch}
            />
          )}
          {timestampable && <Timestamp resource={resource} />}
        </div>
        {resource?.shippingOperator?.name === 'POCZTA_POLSKA' &&
          resource?.elektronicznyNadawcaLabelGuid && (
            <div>
              {translate('Data nadania:') +
                resource?.elektronicznyNadawcaBuforSendDate}
            </div>
          )}
      </>
    )
  },
})
