import React from 'react'
import { Form, NotFound } from 'pages'
import parentSchema from '_schema/reservationEvent'
import schema from '_schema/reservationEventStatistics'
import routes from './routes'
import queryString from 'query-string'
import { useResourceState } from '../../_helpers/useResourceState'
import { useResourceFetch } from '../../_helpers/useResourceFetch'
import { Paper } from '../../components/Paper'
import { Loader } from '../../components/Loader'

export const NewStatistics = ({ schema: definitions, ...rest }) => {
  const { pid } = queryString.parse(rest.location.search)
  const defaultData = pid ? { parent: `${parentSchema.endpoint}/${pid}` } : {}

  const [state, setState] = useResourceState()

  const { resource: parent, isFetching, fetchError } = state

  useResourceFetch(
    pid && `${parentSchema.endpoint}/${pid}`,
    parent,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    true
  )

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : pid && fetchError ? (
    <NotFound />
  ) : (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      definitions={definitions}
      customResourceSchema={schema.resource}
      method="POST"
      url={schema.endpoint}
      collectionPath={routes().statistics.path.replace(':id', pid)}
      editPath={routes().statisticsEdit.path}
      storeCollectionId={schema.endpoint}
      width={600}
      defaultData={defaultData}
    />
  )
}
