import usersShema from './accommodationUser'
import pdfSchema from './accommodationPdf'
// import { LANGS_IRI } from '_lib/langs'

const schema = {
  endpoint: '/api/accommodation_notices',
  access: 'accommodationNotice',
  resource: {
    definition: '#AccommodationNotice',
    properties: {
      mailTitle: {
        description: 'Tytuł wiadomości',
        validate: ['required'],
      },

      mailText: {
        description: 'Treść wiadomości',
        validate: ['required'],
        type: 'tinymce',
      },

      recipientsChoosen: {
        type: 'multipleResource',
        endpoint: `${usersShema.endpoint}?pagination=false`,
        titleAccessor: 'selectName',
        description: 'Odbiorcy',
        hint: 'zostaw puste jeśli chcesz wysłać do wszystkich',
      },

      pdfWeather: {
        type: 'resource',
        endpoint: `${pdfSchema.endpoint}?pagination=true&type=10&perPage=10`, //TYPE_WEATHER
        titleAccessor: `title`,
        label: 'Pogoda (pdf)',
      },
      pdfConditions: {
        type: 'resource',
        endpoint: `${pdfSchema.endpoint}?pagination=true&type=40&perPage=10`, //TYPE_CONDITIONS
        titleAccessor: `title`,
        label: 'Warunki (pdf)',
      },

      restart: {
        description: 'Restart',
        hint:
          'jesli włączysz, zresetujesz ustawienia, będziesz mógł od nowa wysłać tą wiadomość',
      },

      autosend: {
        description: 'Wyślij automatycznie',
        hint:
          'jesli włączysz, wiadomość zostanie wysłana przez system, jesli wyłączysz musisz ręcznie wysłać wiadomość',
      },
    },
  },
  resourceNewAction: {
    definition: '#AccommodationNotice',
    properties: {
      mailTitle: {
        description: 'Tytuł wiadomości',
        validate: ['required'],
      },
      mailText: {
        description: 'Treść wiadomości',
        validate: ['required'],
        type: 'tinymce',
      },
      recipientsChoosen: {
        type: 'multipleResource',
        endpoint: `${usersShema.endpoint}?pagination=false`,
        titleAccessor: 'selectName',
        description: 'Odbiorcy',
        hint: 'zostaw puste jeśli chcesz wysłać do wszystkich',
      },
      pdfWeather: {
        type: 'resource',
        endpoint: `${pdfSchema.endpoint}?pagination=true&type=10&perPage=10`, //TYPE_WEATHER
        titleAccessor: `title`,
        label: 'Pogoda (pdf)',
      },
      pdfConditions: {
        type: 'resource',
        endpoint: `${pdfSchema.endpoint}?pagination=true&type=40&perPage=10`, //TYPE_CONDITIONS
        titleAccessor: `title`,
        label: 'Warunki (pdf)',
      },

      autosend: {
        description: 'Wyślij automatycznie',
        hint:
          'jesli włączysz, wiadomość zostanie wysłana przez system, jesli wyłączysz musisz ręcznie wysłać wiadomość',
      },
    },
  },
}

export default schema
