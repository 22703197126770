import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { makeStyles } from '@material-ui/styles'
import { translate } from '_helpers/translate'
import { validate } from '../../../_helpers/validate'
import { Delete } from '@material-ui/icons'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  header: {
    color: 'rgba(0, 0, 0, .54)',
  },
  addContainer: {
    display: 'flex',
    alignItems: 'end',
  },
  addButton: {
    marginLeft: 8,
  },
  listContainer: {
    marginTop: 8,
    padding: 0,
  },
  removeIcon: {
    padding: 0,
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
}))

export const SimpleArrayType = ({
  name,
  label,
  initialValue = null,
  compareValue,
  compare,
  disabled,
  value,
  setValue,
  error,
  renderError,
  setError,
  addInputLabel = 'Wartość',
  addButtonLabel = 'Dodaj',
  orderable = true,
  removable = true,
  inputValidate = [],
  // inputType = 'text',  todo
}) => {
  const sensors = useSensors(useSensor(PointerSensor))
  const [inputValue, setInputValue] = useState('')
  const [inputError, setInputError] = useState(false)
  const [inputErrorText, setInputErrorText] = useState('')

  const classes = useStyles()

  const handleDragEnd = event => {
    const { active, over } = event
    if (active.id !== over.id) {
      const oldIndex = value.findIndex(item => item === active.id)
      const newIndex = value.findIndex(item => item === over.id)
      setValue(name, arrayMove(value, oldIndex, newIndex))
    }
  }

  const validateField = useCallback(
    value => {
      if (!inputValidate) {
        setInputError(false)
        setInputErrorText('')

        return
      }

      const valid = validate(inputValidate, value)
      setInputError(!valid.result)
      setInputErrorText(!valid.result ? valid.message : '')
      setError(name, !valid.result && valid.message)
    },
    [inputValidate, name, setError]
  )

  useEffect(() => {
    validateField(inputValue)
  }, [validateField, inputValue])

  useEffect(() => {
    if (initialValue !== null && !value) {
      setValue(name, initialValue)
    }
  }, [validateField, inputValue, initialValue, value, setValue, name])

  const addValue = newValue => {
    setInputValue('')
    setValue(name, [...(value ? value : []), newValue])
  }

  const removeItem = itemToRemove => {
    const foundItem = value?.filter(item => item !== itemToRemove)

    if (foundItem) {
      setValue(name, foundItem)
    }
  }

  const SortableItem = ({ item }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({ id: item })

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      display: 'flex',
      alignItems: 'center',
      paddingTop: 0,
      paddingBottom: 0,
    }

    return (
      <ListItem ref={setNodeRef} style={style} divider>
        <IconButton
          {...attributes}
          {...listeners}
          edge="start"
          disabled={!orderable}
        >
          <DragIndicatorIcon />
        </IconButton>
        <ListItemText primary={item} />
        {removable && (
          <IconButton
            className={classes.removeIcon}
            onClick={() => removeItem(item)}
          >
            <Delete />
          </IconButton>
        )}
      </ListItem>
    )
  }

  const renderRegularList = () => (
    <List component={Paper} className={classes.listContainer}>
      {value &&
        value?.map(item => (
          <ListItem key={item} divider>
            <ListItemText primary={item} />
            {removable && (
              <IconButton
                className={classes.removeIcon}
                onClick={() => removeItem(item)}
              >
                <Delete />
              </IconButton>
            )}
          </ListItem>
        ))}
    </List>
  )

  const renderSortableList = () => (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={value}
        strategy={verticalListSortingStrategy}
        disabled={disabled}
      >
        <List component={Paper} className={classes.listContainer}>
          {value && value?.map(item => <SortableItem key={item} item={item} />)}
        </List>
      </SortableContext>
    </DndContext>
  )

  return (
    <>
      <Typography variant="subtitle1" component="h6" className={classes.header}>
        {label}
      </Typography>
      {renderError && error && (
        <Typography color="error" variant="caption">
          {translate(error)}
        </Typography>
      )}
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {translate('T_ENTRY_CHANGED')}
        </div>
      )}

      {!value || (value.length === 0 && <div>{translate('T_NO_ENTRIES')}</div>)}
      {value && value.length > 0 && orderable
        ? renderSortableList()
        : renderRegularList()}

      <div className={classes.addContainer}>
        <TextField
          error={inputError}
          helperText={translate(inputErrorText)}
          label={translate(addInputLabel)}
          value={inputValue}
          onChange={e => {
            setInputValue(e.target.value)
            setInputError(false)
          }}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.addButton}
          disabled={inputError || !inputValue.trim()}
          onClick={() => addValue(inputValue)}
        >
          {translate(addButtonLabel)}
        </Button>
      </div>
    </>
  )
}
