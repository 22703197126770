import React, {useMemo, useState} from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from './table/columns/operations'
import { translate } from '_helpers/translate'
// { publish } from 'components/table/_mass'
import schema from '_schema/matterFormPermission'
import routes from './routes'
import { DateCell } from '../../components/table/cells/DateCell'
import { LANGS_IRI } from '../../_lib/langs'
import { MatterTypeFilter } from './components/MatterTypeFilter'
import { ControlList } from './components/ControlList'

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        columnId: 'signature',
        header: translate('Znak sprawy'),
        accessor: `signature`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'symbol',
        header: translate('Symbol'),
        accessor: `symbol`,
        filterable: true,
        sortable: true,
        width: '5%',
      },
      {
        columnId: 'numberZeros',
        header: translate('Numer'),
        accessor: `numberZeros`,
        filterable: true,
        sortable: true,
        width: '5%',
      },
      {
        columnId: 'year',
        header: translate('Year'),
        accessor: `year`,
        filterable: true,
        sortable: true,
        width: '5%',
      },
      {
        columnId: 'date',
        header: translate('Data wystawienia'),
        accessor: `date`,
        filterable: true,
        sortable: true,
        width: '10%',
        Cell: DateCell,
      },
      {
        columnId: 'fee1Item.translations.title',
        header: translate('Opłata #1'),
        accessor: `fee1Item.translations.${LANGS_IRI.PL}.title`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'fee2Item.translations.title',
        header: translate('Opłata #2'),
        accessor: `fee2Item.translations.${LANGS_IRI.PL}.title`,
        filterable: true,
        sortable: true,
        width: '10%',
      },
      {
        columnId: 'parent.contactName',
        header: translate('Dane kontaktowe'),
        accessor: `parent.contactName`,
        filterable: true,
        sortable: true,
        width: '15%',
      },
      {
        columnId: 'parent.dateFrom',
        header: translate('Od'),
        accessor: `parent.dateFrom`,
        filterable: true,
        sortable: true,
        width: '20%',
        Cell: DateCell,
      },
      {
        columnId: 'parent.dateTo',
        header: translate('Do'),
        accessor: `parent.dateTo`,
        filterable: true,
        sortable: true,
        width: '20%',
        Cell: DateCell,
      },
      {
        columnId: 'ticket',
        header: translate('Uwagi'),
        accessor: `ticket`,
        filterable: true,
        sortable: true,
        width: '20%',
      },
      {
        columnId: 'parent.number',
        header: translate('Numer rejestracyjny'),
        accessor: `parent.number`,
        filterable: true,
        sortable: true,
        width: '5%',
      },
      {
        columnId: 'operations',
        ...operations(`fullName`, true, true, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  const customFilters = [
    {
      name: 'parent.type',
      title: 'Typ sprawy',
      value: null,
      Filter: MatterTypeFilter,
    },
  ]

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      <CollectionHeader
        buttonTitle={translate('T_MODULE_MATTER_FORM_PERMISSION_NEW_BUTTON')}
        buttonPath={routes().new.path}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
        hideButton={true}
      >
        <div style={{ width: 400 }}>
          {translate('T_MODULE_MATTER_FORM_PERMISSION')}
          <ControlList />
        </div>
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        customFilters={customFilters}
        defaultSorters={{ ord: 'desc' }}
        orderable={false}
        //selectable={[publish.on(), publish.off()]}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
        numerable={false}
        parameters={[
          {
            name: 'parent.type',
            in: 'query',
            type: 'string',
          },
          {
            name: 'signature',
            in: 'query',
            type: 'string',
          },
          {
            name: 'symbol',
            in: 'query',
            type: 'string',
          },
          {
            name: 'number',
            in: 'query',
            type: 'string',
          },
          {
            name: 'year',
            in: 'query',
            type: 'string',
          },
          {
            name: 'date',
            in: 'query',
            type: 'string',
          },
          {
            name: 'parent.contactName',
            in: 'query',
            type: 'string',
          },
          {
            name: 'parent.dateFrom',
            in: 'query',
            type: 'string',
          },
          {
            name: 'parent.dateTo',
            in: 'query',
            type: 'string',
          },
          {
            name: 'parent.number',
            in: 'query',
            type: 'string',
          },
          {
            name: 'ticket',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[signature]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[symbol]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[number]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[year]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[date]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[parent.contactName]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[parent.dateFrom]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[parent.dateTo]',
            in: 'query',
            type: 'string',
          },
          {
            name: 'order[parent.number]',
            in: 'query',
            type: 'string',
          },
        ]}
      />
    </Paper>
  )
}
