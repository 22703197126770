import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import {
  DescriptionOutlined,
  MoreVertOutlined,
  History,
} from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/styles'
import { useSelector, useDispatch } from 'react-redux'
import { EditLink } from 'components/resource/EditLink'
import { DeleteCell } from 'components/table/cells/DeleteCell'
import { StatusCell } from 'components/table/cells/StatusCell'
import { CloneCell } from 'components/table/cells/CloneCell'
import { Timestamp } from 'components/resource/Timestamp'
import { translate } from '_helpers/translate'
import { commonConstants } from '_constants'
import { LANGS_IRI } from '_lib/langs'
import routes from '../../routes'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    fontSize: 14,
    '& svg': {
      fontSize: 20,
    },
  },
  listItem: {
    minWidth: 32,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  menuTrigger: {
    borderRadius: 4,
    padding: 7,
  },
  menuTriggerOpen: {
    border: '1px solid #d3d4d5',
    borderBottomColor: '#fff',
    backgroundColor: '#fff',

    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
})

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',

    borderTopRightRadius: 0,
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))

export const operations = (
  accessor = `translations.${
    LANGS_IRI[process.env.REACT_APP_LOCALE.toUpperCase()]
  }.title`,
  editable = true,
  deletable = true,
  statable = true,
  timestampable = true,
  cloneEndpoint = null
) => ({
  header: translate('T_GENERAL_OPERATIONS'),
  accessor,
  sortable: false,
  filterable: false,
  Cell: ({
    resource,
    accessor,
    tableState,
    tableStateDispatch,
    editPath = null,
  }) => {
    const history = useHistory()
    const classes = useStyles()
    const [menuAnchor, setMenuAnchor] = useState(null)

    const handleMenu = e => {
      setMenuAnchor(e.currentTarget)
    }

    const handleClose = () => {
      setMenuAnchor(null)
    }

    const profile = useSelector(state => state.profile)

    const dispatch = useDispatch()

    const onSuccess = () => {
      dispatch({ type: commonConstants.INIT_PAGE_MENU_RELOAD })
    }

    //const maxDepth = process.env.REACT_APP_BIP_PAGE_MAX_DEPTH
    const maxDepth = process.env.REACT_APP_BIP_PAGE_MAX_DEPTH
    console.log(resource.depth, maxDepth)

    const isSubPageable =
      resource.depth < maxDepth && (resource.isSubPageable || profile?.openform)

    const showMenu = editable || !!cloneEndpoint || deletable || isSubPageable

    const redirectToArticles = () => {
      history.push(routes().articles.path.replace(':id', resource['uuid']))
    }

    const redirectToVersions = () => {
      history.push(routes().versions.path.replace(':id', resource['uuid']))
    }

    return (
      <div className={classes.root}>
        <IconButton onClick={redirectToArticles}>
          <DescriptionOutlined />
        </IconButton>
        <IconButton onClick={redirectToVersions}>
          <History />
        </IconButton>
        {showMenu && (
          <>
            <IconButton
              aria-label="record menu"
              aria-controls="record-menu"
              aria-haspopup="true"
              onClick={handleMenu}
              className={clsx(
                classes.menuTrigger,
                menuAnchor && classes.menuTriggerOpen
              )}
            >
              <MoreVertOutlined color="inherit" />
            </IconButton>
            <StyledMenu
              id="record-menu"
              anchorEl={menuAnchor}
              keepMounted
              open={!!menuAnchor}
              onClose={handleClose}
            >
              {editable && (
                <MenuItem className={classes.menuItem}>
                  <EditLink
                    resource={resource}
                    accessor={accessor}
                    disabled={!profile?.openform && !resource.isEditable}
                    editPath={editPath}
                  />
                </MenuItem>
              )}
              {isSubPageable && (
                <MenuItem className={classes.menuItem}>
                  <Link
                    to={`${routes().new.path}?pid=${resource.uuid}`}
                    className={classes.link}
                  >
                    {translate('T_MODULE_PAGES_ADD_SUBPAGE')}
                  </Link>
                </MenuItem>
              )}
              {!!cloneEndpoint && (
                <MenuItem className={classes.menuItem}>
                  <CloneCell
                    resource={resource}
                    endpoint={cloneEndpoint}
                    disabled={!!resource.idName}
                    onSuccess={onSuccess}
                    tableStateDispatch={tableStateDispatch}
                  />
                </MenuItem>
              )}
              {deletable && (
                <MenuItem className={classes.menuItem}>
                  <DeleteCell
                    resource={resource}
                    accessor={accessor}
                    disabled={!profile?.openform && !resource.isDeletable}
                    onSuccess={onSuccess}
                    tableState={tableState}
                    tableStateDispatch={tableStateDispatch}
                  />
                </MenuItem>
              )}
            </StyledMenu>
          </>
        )}
        {statable && (
          <StatusCell
            resource={resource}
            disabled={!profile?.openform && !resource.isStatable}
            tableStateDispatch={tableStateDispatch}
          />
        )}
        {timestampable && <Timestamp resource={resource} />}
      </div>
    )
  },
})
