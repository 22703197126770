const schema = {
  endpoint: '/api/accommodation_pdfs',
  access: 'accommodationPdf',
  resource: {
    definition: '#AccommodationPdf',
    properties: {
      title: {
        description: 'Tytuł pliku',
        validate: ['required'],
      },
    },
  },
}

export default schema
