import React, { useMemo, useState } from 'react'
import { Paper } from 'components/Paper'
import { CollectionHeader } from 'components/CollectionHeader'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { operations } from 'components/table/_columns/operations'
import { translate } from '_helpers/translate'
import schema from '_schema/exceptionLog'
import routes from './routes'
import { useSelector } from 'react-redux'
import { Alert } from '@material-ui/lab'
import { DatetimeCell } from '../../components/table/cells/DatetimeCell'

export const Collection = () => {
  const profile = useSelector(state => state.profile)

  const columns = useMemo(
    () => [
      {
        columnId: 'createdAt',
        header: 'Data utworzenia',
        accessor: `createdAt`,
        filterable: true,
        sortable: true,
        width: '45%',
        Cell: DatetimeCell,
      },
      {
        columnId: 'hostname',
        header: 'Park',
        accessor: `hostname.trans`,
        translateAccessor: true,
        filterable: true,
        sortable: true,
        width: '45%',
      },
      {
        columnId: 'operations',
        ...operations(`createdAt`, true, true, false, true, false),
        width: '10%',
      },
    ],
    []
  )

  const [visibleColumns, setVisibleColumns] = useState(columns)

  return (
    <Paper>
      {profile?.openform && (
        <Alert variant="filled" severity="info" style={{ marginBottom: 20 }}>
          Openform widzi logi wszystkich parków
        </Alert>
      )}
      <CollectionHeader
        buttonTitle={translate('Logi błedów')}
        buttonPath={''}
        columns={columns}
        setVisibleColumns={setVisibleColumns}
        collectionId={schema.endpoint}
        hideButton={true}
      >
        {translate('Logi błędów')}
      </CollectionHeader>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        definition={schema.resource.definition}
        columns={visibleColumns}
        customResourceSchema={schema.resource}
        defaultSorters={{ ord: 'desc' }}
        parameters={[
          {
            name: 'createdAt',
            in: 'query',
            type: 'string',
          },
        ]}
        schemaParameters={[
          {
            name: 'order[createdAt]',
            in: 'query',
            type: 'string',
          },
        ]}
        orderable={false}
        numerable={false}
        isRowLinkable={true}
        editPath={routes().edit.path}
        autoWidth={false}
      />
    </Paper>
  )
}
